export const checkRegion = (region, country) => {
  if (region == "Africa") {
    return "africa";
  } else if (region == "Oceania") {
    return "Oceanica";
  } else if (region == "South America") {
    return "South America";
  } else if (region == "North America") {
    return "North America";
  } else if (region == "Europe") {
    if (
      country == "Netherlands" ||
      country == "United Kingdom" ||
      country == "France" ||
      country == "Ireland" ||
      country == "Italy" ||
      country == "Germany" ||
      country == "Portugal" ||
      country == "Spain" ||
      country == "Belgium"
    ) {
      return "West Europe";
    } else {
      return "East Europe";
    }
  } else if (region == "Asia") {
    if (
      country == "Hong Kong SAR China" ||
      country == "Taiwan" ||
      country == "China"
    ) {
      return "East Asia";
    } else {
      return "South Asia";
    }
  } else {
    return "East Europe";
  }
};
