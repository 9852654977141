var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "unVerfiedSuccess",
            tabindex: "-1",
            "aria-labelledby": "unVerfiedSuccessLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _c("div", { staticClass: "modal-body" }, [
                _vm._m(1),
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(
                    "\n             We received your request to connect with " +
                      _vm._s(_vm.unverifiedEngineer) +
                      ".\n             "
                  ),
                  _c("br"),
                  _c("b", [
                    _vm._v("Please proceed to selecting other team members"),
                  ]),
                  _c("br"),
                  _vm._v(
                    "\n             who have synced their calendar\n           "
                  ),
                ]),
              ]),
              _c("div", { staticClass: "modal-footer" }),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "unVerfied",
            tabindex: "-1",
            "aria-labelledby": "unVerfiedLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._m(3),
                  _vm._m(4),
                  _c("br"),
                  _c("br"),
                  _c("center", [
                    _vm.connectLoader
                      ? _c("img", {
                          staticClass: "button-spinner",
                          staticStyle: { "margin-left": "10px" },
                          attrs: { src: "/img/lightbox/preloader.gif" },
                        })
                      : _vm._e(),
                    !_vm.connectLoader
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            staticStyle: {
                              padding: "10px",
                              "padding-left": "15px",
                              "padding-right": "15px",
                            },
                            attrs: { type: "button" },
                            on: { click: _vm.requestEngineerConnect },
                          },
                          [
                            _vm._v(
                              "\n               Connect with " +
                                _vm._s(_vm.unverifiedEngineer) +
                                "\n             "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _c("br"),
                ],
                1
              ),
              _c("div", { staticClass: "modal-footer" }),
            ]),
          ]),
        ]
      ),
      _vm._l(_vm.bestFitTeam.matchRole, function (data, i) {
        return _c(
          "div",
          { key: i, staticClass: "dev-info_", attrs: { id: "role_data_" + i } },
          [
            _c(
              "div",
              {
                staticClass: "alert bg-white Role-Hearder",
                attrs: { role: "alert" },
              },
              [
                _c("span", { staticClass: "Role-Hearder-title" }, [
                  _vm._v(_vm._s(data + " ")),
                ]),
                _c("span", { staticClass: "badge bg-secondary" }, [
                  _vm._v(
                    "\n         " +
                      _vm._s(_vm.bestFitTeam.matchUser[i].length) +
                      "\n       "
                  ),
                ]),
                _c("span", { staticClass: "Role-Hearder-sub" }, [
                  _vm._v("\n              \n         "),
                  _c("span", { attrs: { id: "counter" + i } }, [
                    _vm._v(_vm._s(_vm.selectedEngineerCount(i))),
                  ]),
                  _vm._v("\n         selected\n       "),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "row align-items-start g-20 p-2" },
              _vm._l(_vm.bestFitTeam.matchUser[i], function (item, k) {
                return _c(
                  "div",
                  {
                    key: k,
                    class:
                      " contentBlock" +
                      k +
                      " col-md-6 col-lg-4  col-xl-4 col-sm-12  col-xs-12  mb-5  contentBlock viewUser",
                    attrs: {
                      "data-index": "" + i,
                      "data-user": "" + JSON.stringify(item),
                      "data-key": "" + k,
                      "data-name": "" + item.user.name,
                      "data-image":
                        "" +
                        (typeof item.user.profile_picture !== "object"
                          ? "https://grupa-media.s3-us-west-2.amazonaws.com/live/" +
                              item.user.profile_picture ||
                            "/img/onboarding/profile-avatar.svg"
                          : "/img/onboarding/profile-avatar.svg"),
                      "data-location":
                        "" +
                        (item.user.user_location
                          ? item.user.user_location
                          : "Unknown"),
                      "data-organisation":
                        "" +
                        (item.user.work_experience[0] !== undefined
                          ? item.user.work_experience[0].organization
                          : "None"),
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        class:
                          "p-4 card-item " +
                          _vm.selectedEngineerCard(i, item.user.id),
                      },
                      [
                        _c("div", { staticClass: "row p-2" }, [
                          _c("div", { staticClass: "col-10 item-title" }, [
                            _vm._v(
                              "\n               " +
                                _vm._s(item.user.name) +
                                "\n               "
                            ),
                            _c("div", { staticClass: "item-title-sub" }, [
                              _vm._v(
                                "\n                 " +
                                  _vm._s(item.user.work_experience_count) +
                                  " year" +
                                  _vm._s(
                                    item.user.work_experience_count > 0
                                      ? "s"
                                      : null
                                  ) +
                                  "\n                 of experience\n               "
                              ),
                            ]),
                          ]),
                          _vm._m(5, true),
                        ]),
                        _c("ul", { staticClass: "list-group no-border mt-3" }, [
                          _c(
                            "li",
                            {
                              staticClass:
                                "list-group-item no-border item-data justify-content-between align-items-center",
                            },
                            [
                              _vm._m(6, true),
                              _vm._v(
                                "\n               " +
                                  _vm._s(
                                    item.user.user_location
                                      ? item.user.user_location
                                      : "Unknown"
                                  ) +
                                  "\n             "
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "list-group-item item-data no-border justify-content-between align-items-center",
                              style:
                                "display:" +
                                _vm.hideList(
                                  item.user.work_experience[0] !== undefined
                                    ? item.user.work_experience[0].organization
                                        .length > 0
                                      ? true
                                      : false
                                    : false
                                ) +
                                ";",
                            },
                            [
                              _vm._m(7, true),
                              _vm._v(
                                "\n                \n               " +
                                  _vm._s(
                                    item.user.work_experience[0] !== undefined
                                      ? item.user.work_experience[0]
                                          .organization.length > 21
                                        ? item.user.work_experience[0].organization.slice(
                                            0,
                                            22
                                          ) + "..."
                                        : item.user.work_experience[0]
                                            .organization
                                      : "None"
                                  ) +
                                  "\n             "
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "list-group-item item-data no-border justify-content-between align-items-center",
                              style:
                                "display:" +
                                _vm.hideList(
                                  _vm.LanguageTool(item.user.language_tools) !=
                                    "Empty" &&
                                    _vm.LanguageTool(
                                      item.user.language_tools
                                    ) !== "null" &&
                                    _vm.LanguageTool(
                                      item.user.language_tools
                                    ) !== "[]" &&
                                    _vm.LanguageTool(
                                      item.user.language_tools
                                    ) !== []
                                    ? true
                                    : false
                                ),
                            },
                            [
                              _vm._m(8, true),
                              _c(
                                "span",
                                { staticClass: "list-group-item-text" },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(
                                        _vm.LanguageTool(
                                          item.user.language_tools
                                        )
                                      ) +
                                      "\n               "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass:
                                "list-group-item item-data no-border justify-content-between align-items-center",
                              style:
                                "display:" +
                                _vm.hideList(
                                  _vm.LanguageTool(item.user.team_interest) !=
                                    "Empty"
                                    ? true
                                    : false
                                ),
                            },
                            [
                              _vm._m(9, true),
                              _c(
                                "span",
                                { staticClass: "list-group-item-text" },
                                [
                                  _vm._v(
                                    " \n                 " +
                                      _vm._s(
                                        _vm.LanguageTool(
                                          item.user.team_interest
                                        )
                                      ) +
                                      "\n               "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "row p-0 mt-4",
                            staticStyle: {
                              bottom: "14px",
                              position: "absolute",
                              width: "90%",
                            },
                          },
                          [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "matchData",
                                  staticStyle: { "padding-top": "5px" },
                                },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(item.total_percentage.toFixed(2)) +
                                      "% Match\n               "
                                  ),
                                ]
                              ),
                            ]),
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-unverified",
                                  style:
                                    "float:right;display:" +
                                    (Object.keys(item.user.nylas_auth).length <=
                                    0
                                      ? "flex"
                                      : "none") +
                                    ";border:1px solid #0781f2;color:rgb(144 165 185);padding:3px;padding-left:8px;padding-right:8px;margin-top:10px;width:100px;font-size:12px;margin-left:20px",
                                  attrs: {
                                    "data-bs-toggle": "modal",
                                    "data-bs-target": "#unVerfied",
                                    "data-name": "" + item.user.name,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getEngieersName(
                                        item.user.name,
                                        item.user.id,
                                        item.user.email
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._m(10, true),
                                  _vm._v(
                                    "\n\n                   Connect\n               "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "btn btn-primary_ btnAction",
                                  style:
                                    "float: right;margin-left:0px;display: " +
                                    (_vm.selectedEngineerCard(
                                      i,
                                      item.user.id
                                    ) ||
                                    Object.keys(item.user.nylas_auth).length <=
                                      0
                                      ? "none"
                                      : "flex"),
                                  attrs: {
                                    id: "btnAction_" + k + "_" + i,
                                    "data-index": "" + i,
                                    "data-key": "" + k,
                                    "data-email": "" + item.user.email,
                                    "data-user": "" + item.user.id,
                                    "data-name": "" + item.user.name,
                                    "data-timezone":
                                      "" +
                                      (Object.keys(item.user.nylas_auth)
                                        .length > 0
                                        ? item.user.nylas_auth[0]
                                            .primary_calendar_timezone
                                        : null),
                                    "data-token":
                                      "" +
                                      (Object.keys(item.user.nylas_auth)
                                        .length > 0
                                        ? item.user.nylas_auth[0].access_token
                                        : null),
                                    "data-image":
                                      "" +
                                      (typeof item.user.profile_picture !==
                                      "object"
                                        ? "https://grupa-media.s3-us-west-2.amazonaws.com/live/" +
                                            item.user.profile_picture ||
                                          "/img/onboarding/profile-avatar.svg"
                                        : "/img/onboarding/profile-avatar.svg"),
                                    "data-location":
                                      "" +
                                      (item.user.user_location
                                        ? item.user.user_location
                                        : "Unknown"),
                                    "data-organisation":
                                      "" +
                                      (item.user.work_experience[0] !==
                                      undefined
                                        ? item.user.work_experience[0]
                                            .organization
                                        : "None"),
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge bg-primary",
                                      style:
                                        "padding-top: 0px; opacity: 0.6; font-size: 14px; ",
                                    },
                                    [_vm._v("+")]
                                  ),
                                  _vm._v(
                                    "\n                    Add    \n               "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "btn bg-light btnActionRemove",
                                  style:
                                    "float: right; display: " +
                                    (_vm.selectedEngineerCard(i, item.user.id)
                                      ? "block"
                                      : "none"),
                                  attrs: {
                                    id: "btnActionRemove_" + k + "_" + i,
                                    "data-index": "" + i,
                                    "data-key": "" + k,
                                    "data-user": "" + item.user.id,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "badge bg-danger",
                                      staticStyle: {
                                        "padding-top": "0px",
                                        opacity: "0.6",
                                        "font-size": "11px",
                                      },
                                    },
                                    [_vm._v("-")]
                                  ),
                                  _vm._v(
                                    "\n                  Remove\n               "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "unVerfiedSuccessLabel" } },
        [_vm._v("\n             CONNECTION REQUEST SENT\n           ")]
      ),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("img", {
        staticStyle: { width: "150px", "margin-top": "40px" },
        attrs: { src: "/img/success-mark.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "unVerfiedLabel" } },
        [_vm._v("REQUEST CONNECTION")]
      ),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _c("img", {
        staticStyle: { width: "150px", "margin-top": "40px" },
        attrs: { src: "/img/calendar_error.png" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticStyle: { "text-align": "center" } }, [
      _vm._v(
        "\n             This engineer's calendar has not been synchronized."
      ),
      _c("br"),
      _vm._v("\n             Let us help you get connected\n           "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-2" }, [
      _c("img", { staticClass: "marker", attrs: { src: "/img/mark.svg" } }),
      _c("br"),
      _c("br"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "badge rounded-pill" }, [
      _c("img", { attrs: { src: "/img/Map-location.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "badge rounded-pill" }, [
      _c("img", { attrs: { src: "/img/Bag.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "badge rounded-pill" }, [
      _c("img", { attrs: { src: "/img/Stack.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "badge rounded-pill" }, [
      _c("img", { attrs: { src: "/img/Shape_2.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", {}, [
      _c("img", {
        staticStyle: { width: "20px", "margin-bottom": "2px" },
        attrs: { src: "/img/calendar_error.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }