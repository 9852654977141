<template>
  <div class="row">

    <div
      class="col-md-3 sidebar"
      :style="`display:${windowWidth <= 782 ? 'none' : 'block'} `"
    >
      <img
        :src="`/img/${
          current_page == 1 ? 'sidebarIMG.svg' : 'sidebarIMG2.svg'
        }`"
        class="sidebarIMG"
      />

      <div class="sidebarText">
        <p>
          {{
            current_page == 1
              ? "Building a great team to join you on your product mission is key to success. Please confirm your selections."
              : "We connect with your calendars and that of the experts to automatically find the best times to meet for 30 mins each."
          }}
        </p>

        <p>
          {{
            current_page == 1
              ? "You can go back to modify your selections or replace any team member if you’re not convinced after the initial intro call."
              : "From research we strongly suggest meetings happen within 48 hours of matching."
          }}
        </p>
      </div>

      <div class="sidebarBackButton"  v-if="!localStorageData.isSelected">
        <span><img src="/img/Path.svg" alt="Back" srcset="" /></span>
        <span class="text">Go Back</span>
      </div>




       <div class="sidebarBackButtonHome"  @click="handleDashboard" v-if="localStorageData.isSelected">
        <span><img src="/img/Path.svg" alt="Back" srcset="" /></span>
        <span class="text">Back to Home </span>
      </div>
    </div>
    <div class="col-md-9 col-sm-12">
      <div class="tabs-container">
        <div class="tab-box" v-for="step in regStages" :key="step.stage">
          <div
            class="tab-button"
            v-on:click="setPage(step.stage)"
            :class="{
              'active-tab': current_page == step.stage,
              'completed-tab': current_page > step.stage,
            }"
          >
            {{ step.name }}
          </div>
          <div class="tab-line" v-if="step.stage < regStages.length"></div>
        </div>
      </div>

      <div class="containerPreview">
        <div class="titleHeader">
          <div class="mainHeader">
            {{
              current_page == 1
                ? "Meet your team"
                :(current_page==2?"Connect your calendars":"Confirm Meeting Team")
            }}
          </div>
          <div class="subHeader">
            {{
              current_page == 1
                ? "You have selected these Experts to join in your mission, please Review and Proceed to confirm."
                : current_page==2?"We connect to automatically find times you’re available. Your free/busy schedule is requested once and credentials are not stored on our servers.":"Comfirm meeting and accepting all selected team members and proceed to kickoff/worksheet"
            }}
          </div>
        </div>


 
        <div class="proceedToKickOff " style="margin-top:90px">
          <div class="proceedToKickOffData"
           
            v-for="(item, k) in   SelectedEngineerMeeting || []" :key="k" >

        
           
         
      <div style="margin-bottom:0px">
          <div :style="`font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 145%;
                    color: #1b1e22;
                    margin-top:${k?'40px':'0px'};
                    margin-bottom: 10px;`" >
            
             {{k}}
            </div>

             <div class="row gy-1 p-1">

           
              


                 <div  v-for="(item2, i) in   item || []" :key="i" class="selectedTeamData_ col-md-6 col-lg-6 col-xl-3 col-sm-12 col-xs-12 mb-1" style="border: 0px solid red !important;">
              <div class="selectedTeamDataMain_">
                <div class="row">
                    <div class="col-3 p-1">
                      <div class="teamImg"><img
                         :src="`${item2.eng.image}`"
                        class="img-fluid teamImgData"></div>
                    </div>
                    <div class="col-8">
                      <div class="teamData">
                     
                          <div class="teamDataTitle">{{item2.eng.name}}</div>

                           <div style="margin-top:20px;color:#aaa;font-weight: 800;margin-left: 5px;"> Meeting Detail</div>

                           <div class="ml-0">
                          <div class="teamDataList"><span class="location_">
                            <img src="/img/Map-location.svg" style="width: 17px;"></span>
                            <span class="locationData" style="margin-left: -2px;">{{getDayOfTheWeek(item2.when.start_time)}}
                           </span></div>

                            
                          <div class="teamDataList"><span class="location_"> 
                            <img
                            src="/img/clock_main.svg"
                            style="margin-top: 2px"
                          /></span><span class="locationData">{{getTimeInWord(item2.when.start_time,item2.when.end_time)}}</span></div>
                          </div>
                      </div>
                    </div>
                    
                </div>
                <div class="row p-0 m-0 mr-2" style="margin-top: -10px !important;">
                    <div class="col-6-md p-0 m-0"></div>
                    <div class="col-6-md p-0 m-0 text-right"><code style="font-size: 9px;">{{item2.eng.timezone}}</code></div>
                </div>
              </div>
            </div> 

           
            </div>
            
          

          

            </div>
        



          </div>


          <div style="display:flex;padding:20px;margin-top:60px">

            <!-- @click="goToMiddle()" -->
            <!--  data-bs-target="#comingSoon" -->
            <button
             data-bs-toggle="modal"
                            data-bs-target="#requestChange"
                   
                      
                      type="button"
                      class="btn btn-light p-3 mr-4 shadow-sm "
                     
                    >
                     Request Change
                    </button>




                    <div
                  v-if="kickoffLoader"
                  style="
                    left: 70%;
                    right: 0;
                    margin-top: 2%;
                    text-align: center;
                  "
                  id="kickoffLoader"
                >
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  {{   kickoffLoaderMsg}}
                </div>

                 <span v-if="kickoffLoaderError!='' && kickoffLoader===false">
              <code style="font-weight:800"> {{kickoffLoaderError}}</code>
             
            </span>

            <!-- 

               data-bs-toggle="modal"
                            data-bs-target="#comingSoon"
             -->

                    <button
                    v-if="kickoffLoader==false"
                      style="margin-left:20px;padding-left:20px;padding-right:20px"
                      @click="goToKickOfWorkSpace()"
                      
                      type="button"
                      class="btn btn-primary p-3"
                     
                    >
                     Proceed with your team
                    </button>

          </div>


          
          
        </div>

        <div class="calenderMain">
          <div class="calenderConnect">
            <div class="row">
              <div class="col-8 emailText" style="">
                <span class="calederIcon"
                  ><img src="/img/google calendar.svg"
                /></span>
                {{ obscureEmail(currentUser.email) }}
              </div>
              <div class="col-4 disconectText">
                {{ devNylasRecord.user === null ? "Connect" : "Disconnect" }}
              </div>
            </div>

            <div
              class="horizonatlLine"
              style="margin-left: -20px; margin-top: 30px; margin-bottom: 10px"
            />

            <div class="row">
              <div class="col-5 clickMore">Click to connect more</div>
              <div class="col-7">
                <span class="IconFooter"
                  ><img src="/img/calendar-logo.svg"
                /></span>

                <span class="IconFooter googleClick"
                  ><img src="/img/google calendar.svg"
                /></span>

                <span class="IconFooter"><img src="/img/iCalendar.svg" /></span>

                <span class="IconFooter"
                  ><img src="/img/calendly_brand.svg"
                /></span>
              </div>
            </div>
          </div>

          <div class="reviewTitle">Review and Confirm Schedule</div>
          <div class="reviewTitleSub">
            We have selected the best shared available times between you and
            each expert over the next 48hours.
          </div>

          <div class="scheduleEng">
            <div class="scheduleTime">Best Available times</div>

           

            <ul
              class="nav nav-tabs nav-fill mb-0"
              id="pills-tab"
              role="tablist"
            >
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Meet each Engineers
                </button>
              </li>
              <li
                class="nav-item disabled"
                role="presentation"
                aria-disabled="true"
              >
                <a
                  class="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Conference meeting
                </a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div
                  v-if="isLoadingSingleCalender || isReloadingContent"
                  style="
                    left: 50%;
                    right: 0;
                    margin-top: 20%;
                    text-align: center;
                  "
                  id="loaderCal"
                >
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  {{ isLoadingSingleCalenderMsg }}
                </div>
               

                <div v-if="!isLoadingSingleCalender">
                  <div
                    class="scheduleEngCard"
                    v-for="(item, k) in NylasSingleAvailabilityRecord"
                    :key="k"
                  >
                    <!-- {{k}} -->
                    <div
                      class="row"
                      style="
                        border-bottom: 1px solid rgb(228 229 231 / 59%);
                        padding-bottom: 30px;
                        padding-top: 10px;
                      "
                    >
                      <div class="col-6 text-left">
                        <div class="Eng">
                          <img
                            :src="`${item.eng.image}`"
                            class="img-fluid"
                            style="
                              width: 50px;
                              height: 50px;
                              background-blend-mode: normal;
                              border-radius: 2px;
                              margin-right: 1px;
                            "
                          />

                          <div>
                            <div
                              class="teamDataTitle"
                              style="padding-left: 10px"
                            >
                              {{ item.eng.name }}
                            </div>
                            <div class="locationData">
                              {{ item.eng.role }}
                            </div>

                            <div class="locationData" style="margin-top: -18px">
                              <code style="font-size: 9px">{{
                                item.eng.timezone
                              }}</code>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-6 text-left">
                        <div
                          :class="`eng_selected_${k}`"
                          data-start="0"
                          data-end="0"
                          data-id="0"
                          :data-index="k"
                          :id="`eng_day_${item.eng.id}`"
                          style="
                            font-weight: 700;
                            /*  color: #1b1e22; */
                            font-size: 14px;

                            color: #a8a2a2ee;
                          "
                        >
                          <!--   Not Set -->
                          {{
                            Object.keys(
                              item.data.hasOwnProperty("time_slots")
                                ? item.data.time_slots
                                : []
                            ).length > 0
                              ? getSingleRamdomDay(item.data)
                              : "None"
                          }}
                        </div>
                        <div style="display: flex; margin-top: 10px">
                          <img
                            src="/img/clock_main.svg"
                            style="margin-top: 2px"
                          />
                          <div
                            style="
                              font-weight: 700;
                              font-size: 14px;
                              margin-left: 5px;
                            "
                            :id="`eng_time_${item.eng.id}`"
                          >
                            {{
                              Object.keys(
                                item.data.hasOwnProperty("time_slots")
                                  ? item.data.time_slots
                                  : []
                              ).length > 0
                                ? getSingleRamdomeDateTimeRange(
                                    item.data,
                                    item.eng.id
                                  )
                                : "No Available &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                            }}
                            <!--  <span style="color: #a8a2a2ee">select time slot</span> -->
                          </div>
                          <div
                            @click="
                              ChangeEngineerTime(
                                item.eng.id,
                                item.eng.name,
                                item.eng.email,
                                $event
                              )
                            "
                            :title="`Choose meeting time for ${item.name}`"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                            :data-id="`${item.id}`"
                            :data-name="`${item.name}`"
                            :data-email="`${item.email}`"
                            :data-availability="`${JSON.stringify(item.data)}`"
                            class="change-slot"
                            style=""
                          >
                            Change
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--  -->

                 

                 <div
                    class="scheduleEngCard"
                    v-if="NylasSingleFreeBusyError != ''"
                    style="text-align: center; margin-top: 40px;display: flex;"
                  >
                    <img src="/img/calendar_error.png" style="width: 60px; height:60px" />

                    <div class="reviewTitle" style="margin-top: 1px;margin-left:20px; text-align: left; font-size: 13px; font-weight: 200">
                      Error Syncing with   <b>{{NylasSingleFreeBusyErrorUser}} </b>

                      <div class="reviewTitleSub" style="margin-top:10px">
                      <code>{{ NylasSingleFreeBusyError }}</code>
                    </div>

                    <div class="locationData" style="margin-top:-5px;font-size:10px">
                     
                      {{NylasSingleFreeBusyErrorEmail}}
                     
                    </div>
                    </div>
                    
                  </div>
                 
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabindex="0"
              >
                <div
                  v-if="isLoadingCalender"
                  style="
                    left: 50%;
                    right: 0;
                    margin-top: 20%;
                    text-align: center;
                  "
                  id="loaderCal"
                >
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  {{ isLoadingCalenderMsg }}
                </div>

                <div
                  class="scheduleEngCard"
                  v-if="NylasFreeBusyError != ''"
                  style="text-align: center; margin-top: 72px"
                >
                  <img src="/img/calendar_error.png" style="width: 140px" />

                  <div class="reviewTitle" style="margin-top: 40px">
                    ERROR DETECTED
                  </div>
                  <div class="reviewTitleSub">
                    <code>{{ NylasFreeBusyError }}</code>
                  </div>
                  <br /><br />
                </div>

                <div
                  style="text-align: center; margin-top: 50px"
                  v-if="!isLoadingCalender && NylasFreeBusyError == ''"
                >
                  <div class="alert alert-warning" role="alert">
                    Feature is still under construction
                  </div>
                  <div style="margin-top: 40px; margin-bottom: 30px">
                    <img src="/img/schedule.png" style="width: 100px" />
                  </div>

                  <div
                    class="reviewTitleSub"
                    style="
                      width: 100%;
                      font-size: 11px;
                      text-transform: uppercase;
                    "
                  >
                    You would be meeting with
                  </div>

                  <div
                    style="max-height: 18px; margin-top: 5px"
                    v-for="(data, i) in (selectedTeamData.hasOwnProperty('role')
                      ? selectedTeamData.role
                      : []) || []"
                    :key="i"
                  >
                    <code
                      style="white-space: normal; display: inline"
                      v-if="
                        Object(selectedTeamData.engineersData[i]).length > 0
                      "
                    >
                      <!--   <span
                        style="font-size:9px"
                        v-if="
                          Object(selectedTeamData.engineersData[i]).length > 0
                        "
                      >
                        {{ selectedTeamData.role[i] }}
                      </span> <br /> -->

                      <span
                        style="font-weight: 300;padding-right:0px"
                        :data-index="`${i}`"
                        v-for="(item, k) in selectedTeamData.engineersData[i]"
                        :key="k"
                      >
                        {{ item.name }} &nbsp;&nbsp;&nbsp;<i style="color:grey;font-size:8px">|</i>&nbsp;&nbsp;&nbsp;
                      </span>
                    </code>
                  </div>

                  <input type="hidden" id="eng_id_data_c" />
                  <input type="hidden" id="cal_day_c" />
                  <input type="hidden" id="cal_start_time_c" />
                  <input type="hidden" id="cal_end_time_c" />
                  <input type="hidden" id="cal_time_range_c" />
                  <div
                    class="btn-group"
                    style="margin-top: 40px; margin-bottom: 30px"
                  >
                    <button
                      class="btn btn-light btn-lg dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="true"
                      aria-expanded="false"
                      style="padding-bottom: 20px; border: 1px solid #ccc"
                    >
                      <div style="margin-top: 5px; font-size: 13px" v-if=" Object.keys(
                            NylasAvailabilityRecord.hasOwnProperty('time_slots')
                              ? NylasAvailabilityRecord.time_slots
                              : []
                          ).length > 0">
                        Click to change time
                      </div>

                      <div style="margin-top: 5px; font-size: 13px" v-if=" Object.keys(
                            NylasAvailabilityRecord.hasOwnProperty('time_slots')
                              ? NylasAvailabilityRecord.time_slots
                              : []
                          ).length <= 0">
                       No available time slot to meet
                      </div>

                      <code
                        class="selected_conference_time btn btn-dark"
                        style="margin-top: 5px"
                        >{{
                          Object.keys(
                            NylasAvailabilityRecord.hasOwnProperty("time_slots")
                              ? NylasAvailabilityRecord.time_slots
                              : []
                          ).length > 0
                            ? getRamdomDay()
                            : 'NO'
                        }}
                        {{
                          Object.keys(
                            NylasAvailabilityRecord.hasOwnProperty("time_slots")
                              ? NylasAvailabilityRecord.time_slots
                              : []
                          ).length > 0
                            ? getRamdomeDateTimeRange()
                            : 'TIME SLOT'
                        }}
                      </code>
                    </button>
                    <ul
                      class="dropdown-menu"
                      v-if="
                        Object.keys(
                          NylasAvailabilityRecord.hasOwnProperty('time_slots')
                            ? NylasAvailabilityRecord.time_slots
                            : []
                        ).length > 0
                      "
                    >
                      <li
                        v-for="(item, k) in NylasAvailabilityRecord.time_slots"
                        :key="k"
                      >
                        <a
                          class="dropdown-item timeSlotData_c"
                          :data-start-time="item.start_time"
                          :data-end-time="item.end_time"
                          href="#"
                        >
                          {{ getDateData(item.start_time) }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="confirbBtn_" style="margin-top: 50px">
            <div
              class="modal"
              id="proceedModal"
              tabindex="-1"
              aria-labelledby="proceedModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Meet your team</h5>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="modal-body">
                    <p class="mt-2">
                      This schedule will be added to your calendar and sent to
                      the experts and other stakeholders.
                    </p>
                    <p class="mt-4">
                      You’ll be notified by email once an expert responds to
                      your invitation. Typical response time is ~6hours.
                    </p>

                    <div style="color: rgb(151, 157, 165); margin-top: 50px">
                      Mails would be sent to &nbsp;&nbsp; <br /><span
                        v-html="sendMailsto()"
                      ></span>
                    </div>

                    <p style="color: rgb(34 108 208); margin-top: 60px">
                      Do you wish to proceed?
                    </p>
                  </div>

                  <div class="modal-footer">
                    <div
                      v-if="isSendingInvitation"
                      style="
                        right: 0%;
                        right: 0;
                        margin-top: 0%;
                        text-align: center;
                      "
                      id="loaderCal"
                    >
                      <v-progress-circular
                        :size="30"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                      {{ "sending invitation(s)..." }}
                    </div>

                       <!-- <button
                   
                      @click="proceedToFinal()"
                      v-if="!isSendingInvitation"
                      type="button"
                      class="btn btn-secondary"
                     
                    >
                      Step 3
                    </button>  -->

                    <button
                      v-if="!isSendingInvitation"
                      type="button"
                      class="btn btn-secondary p-3"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button
                      v-if="!isSendingInvitation"
                      @click="confirmTeamProceed()"
                      type="button"
                      class="btn btn-primary confirmTeamProceed p-3"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn btn-light p-3 mr-4 shadow-sm backbBtn">
              <span
                >&nbsp;
                <img style="width: 16px" src="/img/Path.svg" />
              </span>
              <span>&nbsp;&nbsp;</span>
            </div>



            
            

            <!-- isLoadingSingleCalender == false && isLoadingCalender == false &&  NylasSingleFreeBusyError == '' -->
           
            <span v-if="isReloadingContentError!=''">
              <code style="font-weight:800"> {{isReloadingContentError}}</code>
              <span style="padding-left:30px;color:#aaa;cursor:pointer;color:dodgerblue" onClick="javascript:document.location.reload()">Click to try again</span>
            </span>
            <div
              v-if="
              
                isLoadingSingleCalender == false && isLoadingCalender == false &&  NylasSingleAvailabilityRecordError == '' && isReloadingContent===false && Object.keys(this.NylasSingleAvailabilityRecord).length >0
              "
              class="btn btn-primary p-3"
              data-bs-toggle="modal"
              data-bs-target="#proceedModal"
            >
         
              <span>Proceed</span>  
              <span
                >&nbsp;
                <img style="width: 16px" src="/img/arrow-right.svg" />
              </span>
            </div>


             <div onClick="javascript:document.location.reload()"
              v-if="isLoadingSingleCalender == false && isLoadingCalender == false && ( NylasSingleAvailabilityRecordError != ''  || Object.keys(this.NylasSingleAvailabilityRecord).length <=0)  " 
             class="btn proceedBtn p-3" >
              <span>Reload</span>
              <span
                >&nbsp;
                <img style="width: 16px" src="/img/refresh-2-256.png" />
              </span>
            </div>
          </div>
          </div>


          <!-- 
 v-if="
                isLoadingSingleCalender == false && isLoadingCalender == false &&  NylasFreeBusyError != ''
              "

           -->

         


           <div
            class="modal"
            id="errorModal"
            tabindex="-1"
            aria-labelledby="errorModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">ERROR DETECTED</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body" style="text-align: center">
                  <br />
                  <img
                    src="/img/calendar_error.png"
                    style="margin-top: 30px; width: 150px"
                  /><br /><br />
                  <p style="margin-top: 30px">
                   Error detected while sending meeting invitation
                  </p>
                  <code>{{this.meetingError}}</code>
                  <br /><br />
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  
                </div>
              </div>
            </div>
            
          </div>


           <div
            class="modal"
            id="successChangeRequestModal"
            tabindex="-1"
            aria-labelledby="successChangeRequestModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Change Request Sent</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body" style="text-align: center">
                  <br />
                  <img
                    src="/img/marked.svg"
                    style="margin-top: 30px; width: 150px"
                  /><br /><br />
                  <p style="margin-top: 30px">
                    Engineer Change Request was successfully sent
                  </p>
                  <br /><br />
                </div>

                <div class="modal-footer">
              <button
               
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-secondary"
              >
                Close
              </button>
            </div>
              </div>
            </div>
            
          </div>

          <div
            class="modal"
            id="successModal"
            tabindex="-1"
            aria-labelledby="successModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Meeting Scheduled</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body" style="text-align: center">
                  <br />
                  <img
                    src="/img/marked.svg"
                    style="margin-top: 30px; width: 150px"
                  /><br /><br />
                  <p style="margin-top: 30px">
                    Meeting schedules have been successfully sent
                  </p>
                  <br /><br />
                </div>

                <div class="modal-footer">
              <button
               
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-secondary"
              >
                Close
              </button>
            </div>
              </div>
            </div>
            
          </div>


           <div
              class="modal fade"
              id="comingSoon"
              tabindex="-1"
              role="dialog"
              aria-labelledby="comingSoonTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="comingSoonTitle">
                      COMING SOON
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick="javascript:$('#comingSoon').modal('hide')"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" style="text-align: center">
                    <img
                      src="/img/coming-soon.png"
                      style="width: 100px; margin-top: 50px"
                    />
                    <p style="margin-top: 50px; margin-bottom: 30px">
                      This feature is still under construction
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      onClick="javascript:$('#comingSoon').modal('hide')"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>


            <div
              class="modal fade"
              id="requestChange"
              tabindex="-1"
              role="dialog"
              aria-labelledby="requestChangeLabel"
              aria-hidden="false"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="requestChangeTitle">
                      REQUEST ENGINEER CHANGE
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick="javascript:$('#requestChange').modal('hide')"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body" style="text-align: center;padding-top:40px">
                   

                    <center>
                    <textarea
                    id="reasonData" name="reasonData"  v-on:keyup="TextChangeReadon"
                    class="form-control" style="width:95%;height:150px"></textarea>
                    <p>Have a reservation you want to share, kindly let us know ?</p>
                    </center>
                    
                   </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary  p-3"
                     
                      onClick="javascript:$('#requestChange').modal('hide')"
                    >
                      Close
                    </button>

                    <div
                  v-if="ChangeReadonLoader"
                  style="
                    left: 70%;
                    right: 0;
                    margin-top: 2%;
                    text-align: center;
                  "
                  id="ChangeReadonLoader"
                >
                  <v-progress-circular
                    :size="30"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  {{   'submiting...'}}
                </div>


                    <button
                    v-if="ChangeReadonLoader==false"
                      type="button"
                      class="btn btn-primary p-3"
                     
                     
                      @click="requestEngineerChange()"
                    >
                      Request Change
                    </button>
                  </div>
                </div>
              </div>
            </div>

          <div
            class="modal"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Select a time</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div
                    style="margin-top: 40px"
                    v-if="
                      Object.keys(
                        SelectedAvailabilityRecord.hasOwnProperty('time_slots')
                          ? SelectedAvailabilityRecord.time_slots
                          : []
                      ).length <= 0
                    "
                  >
                    <p style="padding-left: 28px; text-align: center">
                      <span style="font-size: 17px; font-weight: 600"
                        >NO TIME AVAILABLE</span
                      ><br />
                      <img
                        src="/img/calendar_blocked.png"
                        style="margin-top: 30px; width: 100px"
                      /><br /><br />
                      There are no available time slots for the selected
                      engineers
                    </p>
                  </div>
                  <div
                    v-if="
                      Object.keys(
                        SelectedAvailabilityRecord.hasOwnProperty('time_slots')
                          ? SelectedAvailabilityRecord.time_slots
                          : []
                      ).length > 0
                    "
                  >
                    <p style="padding-left: 20px">
                      Choose the best available times to meet with
                      <code>{{ selectedEngineer }}</code
                      ><br />
                      Please select one to proceed;
                    </p>
                  </div>

                  <br />
                  <input type="hidden" id="eng_id_data" />
                  <input type="hidden" id="cal_day" />
                  <input type="hidden" id="cal_start_time" />
                  <input type="hidden" id="cal_end_time" />
                  <input type="hidden" id="cal_time_range" />

                  <div
                    class="row"
                    style="padding-left: 20px"
                    v-if="
                      Object.keys(
                        SelectedAvailabilityRecord.hasOwnProperty('time_slots')
                          ? SelectedAvailabilityRecord.time_slots
                          : []
                      ).length > 0
                    "
                  >
                    <div
                      class="col-md-6 timeSlotData"
                      v-for="(item, k) in SelectedAvailabilityRecord.time_slots"
                      :key="k"
                    >
                      <span class="cal_start_time" style="display: none">{{
                        item.start_time
                      }}</span>
                      <span class="cal_end_time" style="display: none">{{
                        item.end_time
                      }}</span>

                      {{ getDateData(item.start_time) }}
                    </div>
                  </div>
                  <!--   <div class="timeSlotData">{{ $moment(item.start_time).format("z")}}</div> -->
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    v-if="
                      Object.keys(
                        SelectedAvailabilityRecord.hasOwnProperty('time_slots')
                          ? SelectedAvailabilityRecord.time_slots
                          : []
                      ).length > 0
                    "
                    type="button"
                    class="btn btn-primary confirmTime"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="selectedTeamContainer">
          <div
            class="selectedTeam"
            v-for="(data, i) in (selectedTeamData.hasOwnProperty('role')
              ? selectedTeamData.role
              : []) || []"
            :key="i"
          >
            <div
              class="selectedRole"
              v-if="Object(selectedTeamData.engineersData[i]).length > 0"
            >
              {{ selectedTeamData.role[i] }}
            </div>

            <div class="row gy-1 p-1" style="border: 0px solid red !important">
              <div
                :data-index="`${i}`"
                class="selectedTeamData_ col-md-6 col-lg-6 col-xl-3 col-sm-12 col-xs-12 mb-1"
                v-for="(item, k) in selectedTeamData.engineersData[i]"
                :key="k"
              >
                <div class="selectedTeamDataMain_">
                  <div class="row">
                    <div class="col-3 p-1">
                      <div class="teamImg">
                        <img
                          :src="`${item.image}`"
                          class="img-fluid teamImgData"
                        />
                      </div>
                    </div>
                    <div :class="`${windowWidth <= 700 ? 'col-8' : 'col-7'}`">
                      <div class="teamData">
                        <div class="teamDataTitle">{{ item.name }}</div>
                        <div class="teamDataList">
                          <span class="location_"
                            ><img src="/img/Map-location.svg"
                          /></span>
                          <span class="locationData">{{
                            item.location.length > 16
                              ? item.location.slice(0, 14) + "..."
                              : item.location
                          }}</span>
                        </div>

                        <div class="teamDataList">
                          <span class="location_"
                            ><img src="/img/Bag.svg"
                          /></span>
                          <span class="locationData" v-if="item.hasOwnProperty('')">{{
                            item.organisation.length > 17
                              ? item.organisation.slice(0, 14) + "..."
                              : item.organisation
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <div
                        class="teamRemove"
                        :data-index="`${i}`"
                        :data-key="`${k}`"
                        :data-user="`${item.id}`"
                      >
                        <img src="/img/minus-cirlce.svg" class="" />
                      </div>
                    </div>
                  </div>
                  <div
                    class="row p-0 m-0 mr-2"
                    style="margin-top: -10px !important"
                  >
                    <div class="col-6-md p-0 m-0"></div>
                    <div class="col-6-md p-0 m-0 text-right">
                      <code style="font-size: 9px">{{ item.timezone }}</code>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          

          <div class="confirbBtn">
            <div class="btn btn-primary p-3">
              <span>Set-up the Initial conversation</span>
              <span
                >&nbsp;
                <img style="width: 16px" src="/img/arrow-right.svg" />
              </span>
            </div>
          </div>


         <!--  <div  @click="goToKickOfWorkSpace()">
            <div class="btn btn-secondary p-3">
              <span>Create your team</span>
              <span
                >&nbsp;
                <img style="width: 16px" src="/img/arrow-right.svg" />
              </span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment";

const { base64encode, base64decode } = require("nodejs-base64");
import {
  getNylasFreeBusy,
  getGrupaNylasRecord,
  getNylasAvailability,
  sendCalnderInvitation,
} from "@/api/nylasApi";

import { clientCreateEngineerTeam, clientSaveTeam,RequestEngineerChange } from "@/api/client";
import { workWithTeam } from "@/api";


import { async } from 'validate.js';

export default {
  name: "TeamPreview", // this is what the Warning is talking about.
  props: ["windowWidth", "yourselectedTeamData"],
  data() {
    return {
      isLoadingCalender: false,
      isLoadingSingleCalender: false,
      isSendingInvitation: false,

      isLoadingCalenderMsg: "",
      isLoadingSIngleCalenderMsg: "",

      isGetNylasData: false,
      primaryCalendar: "",
      devNylasRecord: [],
      NylasFreeBusyRecord: [],
      NylasFreeBusyError: "",
      NylasAvailabilityRecord: [],

      NylasSingleFreeBusyRecord: [],
      NylasSingleFreeBusyError: "",
      NylasSingleFreeBusyErrorUser: "",
      NylasSingleFreeBusyErrorEmail: "",
      NylasSingleAvailabilityRecord: [],
      NylasSingleAvailabilityRecordError: "",
      SelectedAvailabilityRecord: [],
      SelectedEngineerMeeting: [],
      SelectedEngineerMeetingRole: [],
      SelectedTimetoMeet:[],
      selectedEngineer: "",
      selectedEngineerID: "",
      selectedEngineerEmail: "",
      isReloadingContent: false,
      isReloadingContentError: "",
      isLoadingSingleCalenderMsg:"Loading profile...",
      token: "",
      meetingError:"",
      emailSet: [],
      kickoffLoader: false,
      kickoffLoaderMsg: 'Creating team...',
      kickoffLoaderError: '',
      selectedTeam: "",
      ChangeReadon: "",
      ChangeReadonLoader: false,
      localStorageData:[],
      
       

      /*  start_time: parseInt(
        (
          new Date(
            new Date().toISOString().split("T")[0] + " 5:00:00"
          ).getTime() / 1000
        ).toFixed(0)
      ), */

      start_time_raw: new Date(
        new Date().toISOString().split("T")[0] + " 5:00:00"
      ),
      start_time: Date.now() / 1000,

      start_time_raw_now: new Date(Date.now() + 4 * (60 * 60 * 1000)),
      //start_time_raw_now: new Date(Date.now()),
      // new Date(Date.now()).getTime() + 60 * 60 * 24 * 1000

      regStages: [
        {
          stage: 1,
          name: "1. Confirm your team",
        },
        {
          stage: 2,
          name: "2. Meet your team",
        },

        {
          stage: 3,
          name: "3. Proceed to Kickoff",
        },
      ],
      current_page: 1,
      selectedTeamData:
        localStorage.getItem("engineerRole") !== null
          ? JSON.parse(localStorage.getItem("engineerRole"))
          : [],
    };
  },
  components: {},
  methods: {
    ...mapActions("selectTeamForProject", [
      "updatechosenName",
      "updateSelectedPlan",
    ]),
    unserialize(data) {
      var that = this,
        utf8Overhead = function (chr) {
          // http://phpjs.org/functions/unserialize:571#comment_95906
          var code = chr.charCodeAt(0);
          if (code < 0x0080) {
            return 0;
          }
          if (code < 0x0800) {
            return 1;
          }
          return 2;
        },
        error = function (type, msg, filename, line) {
          throw new that.window[type](msg, filename, line);
        },
        read_until = function (data, offset, stopchr) {
          var i = 2,
            buf = [],
            chr = data.slice(offset, offset + 1);

          while (chr != stopchr) {
            if (i + offset > data.length) {
              error("Error", "Invalid");
            }
            buf.push(chr);
            chr = data.slice(offset + (i - 1), offset + i);
            i += 1;
          }
          return [buf.length, buf.join("")];
        },
        read_chrs = function (data, offset, length) {
          var i, chr, buf;

          buf = [];
          for (i = 0; i < length; i++) {
            chr = data.slice(offset + (i - 1), offset + i);
            buf.push(chr);
            length -= utf8Overhead(chr);
          }
          return [buf.length, buf.join("")];
        },
        _unserialize = function (data, offset) {
          var dtype,
            dataoffset,
            keyandchrs,
            keys,
            contig,
            length,
            array,
            readdata,
            readData,
            ccount,
            stringlength,
            i,
            key,
            kprops,
            kchrs,
            vprops,
            vchrs,
            value,
            chrs = 0,
            typeconvert = function (x) {
              return x;
            };

          if (!offset) {
            offset = 0;
          }
          dtype = data.slice(offset, offset + 1).toLowerCase();

          dataoffset = offset + 2;

          switch (dtype) {
            case "i":
              typeconvert = function (x) {
                return parseInt(x, 10);
              };
              readData = read_until(data, dataoffset, ";");
              chrs = readData[0];
              readdata = readData[1];
              dataoffset += chrs + 1;
              break;
            case "b":
              typeconvert = function (x) {
                return parseInt(x, 10) !== 0;
              };
              readData = read_until(data, dataoffset, ";");
              chrs = readData[0];
              readdata = readData[1];
              dataoffset += chrs + 1;
              break;
            case "d":
              typeconvert = function (x) {
                return parseFloat(x);
              };
              readData = read_until(data, dataoffset, ";");
              chrs = readData[0];
              readdata = readData[1];
              dataoffset += chrs + 1;
              break;
            case "n":
              readdata = null;
              break;
            case "s":
              ccount = read_until(data, dataoffset, ":");
              chrs = ccount[0];
              stringlength = ccount[1];
              dataoffset += chrs + 2;

              readData = read_chrs(
                data,
                dataoffset + 1,
                parseInt(stringlength, 10)
              );
              chrs = readData[0];
              readdata = readData[1];
              dataoffset += chrs + 2;
              if (
                chrs != parseInt(stringlength, 10) &&
                chrs != readdata.length
              ) {
                error("SyntaxError", "String length mismatch");
              }
              break;
            case "a":
              readdata = {};

              keyandchrs = read_until(data, dataoffset, ":");
              chrs = keyandchrs[0];
              keys = keyandchrs[1];
              dataoffset += chrs + 2;

              length = parseInt(keys, 10);
              contig = true;

              for (i = 0; i < length; i++) {
                kprops = _unserialize(data, dataoffset);
                kchrs = kprops[1];
                key = kprops[2];
                dataoffset += kchrs;

                vprops = _unserialize(data, dataoffset);
                vchrs = vprops[1];
                value = vprops[2];
                dataoffset += vchrs;

                if (key !== i) contig = false;

                readdata[key] = value;
              }

              if (contig) {
                array = new Array(length);
                for (i = 0; i < length; i++) array[i] = readdata[i];
                readdata = array;
              }

              dataoffset += 1;
              break;
            default:
              error(
                "SyntaxError",
                "Unknown / Unhandled data type(s): " + dtype
              );
              break;
          }
          return [dtype, dataoffset - offset, typeconvert(readdata)];
        };
      return _unserialize(data + "", 0)[2];
    },

    sendMailsto() {
      let d = "";
      this.NylasSingleAvailabilityRecord.forEach((element) => {
        if (element.data.hasOwnProperty("time_slots")) {
          if (element.data.time_slots.length > 0)
            d += `<code> ${element.eng.name} </code>&nbsp;&nbsp;`;
        }
      });

      return d;
    },

    getDayOfTheWeek(time) {

       const milliseconds =
          parseInt(time) * 1000;

        const dateObject = new Date(milliseconds);

        let day_of_the_week = dateObject.toLocaleString("en-US", {
          weekday: "long",
        }); // Monday

      return day_of_the_week;

    },

    getTimeInWord(startTime,endTime)
    {
      
        const milliseconds1 =
          parseInt(startTime) * 1000;
      const dateObject1 = new Date(milliseconds1);
        
        let hour1 = dateObject1.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute1 = dateObject1.toLocaleString("en-US", {
          minute: "numeric",
        }); 

        const milliseconds2 =
          parseInt(endTime) * 1000;
        const dateObject2 = new Date(milliseconds2);

        let hour2 = dateObject2.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute2 = dateObject2.toLocaleString("en-US", {
          minute: "numeric",
        }); 

       let word =  `${hour1.split(" ")[0]}:${
            minute1.length == 1 ? minute1 + "0" : minute1
          } ${hour1.split(" ")[1]} - ${hour2.split(" ")[0]}:${
            minute2.length == 1 ? minute2 + "0" : minute2
         } ${hour2.split(" ")[1]}`

      return word;
          
         
        
    },

    async requestEngineerChange()
    {

      let payload = {
        client_id: this.currentUser.id,
        client_email: this.currentUser.email,
        client_name:this.currentUser.name,
        project_id: this.clientProject.id,
        project_name: this.clientProject.project_name,
        data: this.ChangeReadon,
        subject:'Engineer Change Request'

      }


      this.ChangeReadonLoader=true
      await RequestEngineerChange(payload)
        .then((data) => {

          console.log(data)
          this.ChangeReadonLoader = false

          
          $("#requestChange").modal("hide");
          $("#successChangeRequestModal").modal("show");


        
      }).catch((error) => {

         this.ChangeReadonLoader=false
        console.log(error)
        
      })


    },

    TextChangeReadon: function (evt) {
      this.$emit("change", evt);
      //console.log(evt);
     // console.log(evt.target.value)
      this.ChangeReadon=evt.target.value

    },
    


    proceedToFinal()
    {
      

      
  


      let i = 0;

      this.SelectedTimetoMeet = [];
      
      this.NylasSingleAvailabilityRecord.forEach((element) => {

        this.SelectedTimetoMeet.push({
          eng: element.eng,
          role: element.eng.role,
          when: {
                  start_time: $(`.eng_selected_${i}`).attr("data-start"),
                  end_time: $(`.eng_selected_${i}`).attr("data-end"),
          }
        }
        )
       


        i++;
      });

      //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      this.current_page = 3;
      
        $(".calenderMain, .selectedTeamContainer").fadeOut(() => {
          $(".proceedToKickOff").fadeIn();
        });
      $("#proceedModal").modal("hide");

       var uniqueNamesOld = [];

        let grouper =this.SelectedTimetoMeet.reduce((r, a) => {
                     // console.log("a", a);
                      //console.log('r', r);
                      uniqueNamesOld.push(a.role)
                      r[a.role] = [...r[a.role] || [], a];
                     return r;
        }, {}); 



         var uniqueNames = [];
       
        $.each(uniqueNamesOld, function(i, el){
                 if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
        });

        this.SelectedEngineerMeetingRole = uniqueNames

      //console.log(grouper)
      //console.log(this.SelectedEngineerMeetingRole)
    
      


      this.SelectedEngineerMeeting = grouper;

      //console.log(this.SelectedEngineerMeetingRole)
      // console.log(this.SelectedTimetoMeet)
         // console.log(this.SelectedEngineerMeeting)
      
        $(window).scrollTop(0);

    },

    getSelectedTeam(userName) {
      workWithTeam(userName)
        .then((response) => {
          this.selectedTeam = response.data.data;
          this.selectedTeam.members = JSON.parse(
            JSON.stringify(response.data.data.members)
          );
          this.$developConsole(response.data, "Work With Team");
        })
        .catch((err) => {
          this.$developConsole("Error Team", err.response);
        });
    },

    switchTeam(teamDetails) {

        //console.log("teamDetails :: ", teamDetails);
      // Only client can perform this action

      //if (this.user.user_type == 2) {
        //const { team } = teamDetails;
      //const { user_name } = team;

      let team = teamDetails;
      let user_name = teamDetails.user_name;
        
        team.price ? this.updateSelectedPlan(team.price) : null;
        this.updatechosenName(user_name);
        this.selectedTeam = "";
        this.getSelectedTeam(user_name);
     // }
    },

    async goToKickOfWorkSpace ()
    {

      //console.log(this.NylasSingleAvailabilityRecord)

      let data = []
      let teamLeadID =''


     // this.localStorageData = data
      if (this.localStorageData.hasOwnProperty('isSelected')) {

        this.localStorageData.timeSlot.forEach((element) => {


          if (teamLeadID == '') {

            teamLeadID= element.eng.id


          }

          data.push({
                  user_id: element.eng.id,
                  user_email: element.eng.email,
                  user_name: element.eng.name,
                  user_timezone: element.eng.timezone,
                project_id: this.clientProject.id,
                    project_name: this.clientProject.project_name,
           })
        


        })


      }
      else
      {

         this.NylasSingleAvailabilityRecord.forEach((element) => {

        //console.log(element.eng.id)

        data.push({
          user_id: element.eng.id,
          user_email: element.eng.email,
          user_name: element.eng.name,
           user_timezone: element.eng.timezone,
         project_id: this.clientProject.id,
        project_name: this.clientProject.project_name,
          
        })


         })

        teamLeadID= this.NylasSingleAvailabilityRecord[0].eng.id

      }

     

      let teamLead = {
        user_id: teamLeadID,
         project_name: this.clientProject.project_name, 
      }


      let param = {
        teamLead: teamLead,
        team: data

      };

      //console.log(param)


      this.kickoffLoader=true

       await clientCreateEngineerTeam((param)).then((data)=>
      {
        this.kickoffLoader=false

        if(data.data.hasOwnProperty('team'))
        {

           const teamId = data.data.team.id;
          const project_id = this.clientProject.id;
          const team_name = data.data.team.team_name;

           // console.log(data.data.team)
          //console.log(data.data.team.team_name)
          //console.log(data.data.team.id) 

               // console.log("clientCreateEngineerTeam :: ",data.data)

          this.kickoffLoader = true

          this.kickoffLoaderMsg = 'Building your workspace...'

           let payload = {
          project_id: project_id,
          team_id: teamId,
        };


            clientSaveTeam(payload)
              .then((response) => {

                //console.log("clientSaveTeam :: ",response.data)

             this.kickoffLoader = false
            
            if (response.data.status == 1) {


              this.switchTeam(data.data.team)
              this.$Mixpanel.track("MeetThisTeam", {
                distinct_id: this.currentUser.email,
                team_name: team_name,
              });

              this.$router.push({
                name: "project_workspace",
                params: { id: project_id },
              });
            }
          })
              .catch((error) => {
                console.log("clientSaveTeam error :: ",error)
                this.kickoffLoader = false
              this.kickoffLoaderError = error+' : 1'
            this.$developConsole(error, "An error ......");
          }); 
        }
        else {
          this.kickoffLoaderError = 'Unexpected error. Kindly confirm your network'
          
        }
       

      }).catch((error) => {

        console.log("clientCreateEngineerTeam :: ",error)

         this.kickoffLoader = false
        this.kickoffLoaderError = error+' : 2'
        
      }) 

       //$("#comingSoon").modal("show");


      





    },


     goToMiddle()
    {

       this.current_page = 2;

      
        $(".proceedToKickOff").fadeOut(() => {
          $(".calenderMain").fadeIn();
            $(".selectedTeamContainer").fadeOut();
        });
     
     
      
        $(window).scrollTop(0);

    },

    confirmTeamProceed() {
      let calendar = JSON.parse(
        this.unserialize(this.devNylasRecord.user.calendar)
      );

      calendar.forEach((element) => {
        if (element.is_primary) {
          this.primaryCalendar = element.id;
        }
      });

      let i = 0;

      let MailData = {
        data: [],
        token: this.token,
        project_id: this.clientProject.id,
        project_name: this.clientProject.project_name,
       // team_lead_id: this.NylasSingleAvailabilityRecord[0].eng.id,
        //team_lead_email:this.NylasSingleAvailabilityRecord[0].eng.email,
        client_id: this.currentUser.id,
        client_email: this.currentUser.email,
        client_name:this.currentUser.name,
        timezone: this.NylasSingleAvailabilityRecord[0].eng.timezone,
        location:this.NylasSingleAvailabilityRecord[0].eng.location
      };
    // console.log("MailData :: ",MailData);


      this.SelectedTimetoMeet = [];
     
      this.NylasSingleAvailabilityRecord.forEach((element) => {

        this.SelectedTimetoMeet.push({
          eng: element.eng,
          role: element.eng.role,
          when: {
                  start_time: $(`.eng_selected_${i}`).attr("data-start"),
                  end_time: $(`.eng_selected_${i}`).attr("data-end"),
                }})
        // console.log(this.currentUser);
        // console.log(this.clientProject);

        // console.log(element.data.time_slots.length);

        if (this.devNylasRecord.user.provider === "gmail") {
          if (element.data.time_slots.length > 0) {
            MailData.data.push([
              {
                title:
                 "Grupa | "+ this.clientProject.project_name.toUpperCase() +
                  " Project Meeting : " +
                  element.eng.role,
                when: {
                  start_time: $(`.eng_selected_${i}`).attr("data-start"),
                  end_time: $(`.eng_selected_${i}`).attr("data-end"),
                },
                participants: [
                  { email: element.eng.email, name: element.eng.name },
                   { email: 'matchops@grupa.io', name: 'Grupa Match Team' },
                  {
                    email:
                      this.currentUser.email.split("@")[0] +
                      "+any@" +
                      this.currentUser.email.split("@")[1],
                    name: this.currentUser.name,
                  },
                ],
                conferencing: {
                  provider: "Google Meet",
                  autocreate: new Object(),
                },
                calendar_id: this.primaryCalendar,
                customer_event_id:
                  " Sending email from " +
                  this.currentUser.email +
                  " to " +
                  element.eng.email,
                description:
                  "Grupa | Meeting schedule for " +
                  this.clientProject.project_name +
                  " Project.<b/>Sent to you by " +
                  this.currentUser.name,
                location: "This is a Google-Meet web meeting",
              },
            ]);
          }
        }

        i++;
      });

      //console.log(MailData);

      //return false;

     

      this.isSendingInvitation = true;
      sendCalnderInvitation(MailData)
        .then((res) => {
         // console.log(res.data);
          // this.NylasAvailabilityRecord = res.data.data.response;
          this.isSendingInvitation = false;

          if (res.data.status === 1) {
            //$("#exampleModal").modal("hide");


           
           // console.log(res.data.data.response)
           // console.log(res.data.data.response.data)
             //console.log(res.data.data.response.data.message)
            if (res.data.data.response.data.hasOwnProperty('message'))
            {

              this.meetingError = res.data.data.response.data.message
               $("#proceedModal").modal("hide");
            $("#errorModal").modal("show");

            }
            else
            {
               $("#proceedModal").modal("hide");
              $("#successModal").modal("show");
            this.proceedToFinal()

            }
          } else {
            alert("Unable to send Invitation to enngineers");
          }
        })
        .catch((err) => {
          this.isSendingInvitation = false;
          console.log(err);
           this.meetingError = err
           $("#proceedModal").modal("hide");
            $("#errorModal").modal("show");
        });

      //console.log(this.primaryCalendar);
    },
    ChangeEngineerTime(id, name, email, e) {
      this.selectedEngineer = name;
      this.selectedEngineerID = id;
      this.selectedEngineerEmail = email;
      this.SelectedAvailabilityRecord = JSON.parse(
        e.target.getAttribute("data-availability")
      );
      // console.log(id);
      // e.target.getAttribute("data-availability");
      $("#eng_id_data").val(id);

      if ($(".timeSlotData").hasClass("activeSlot")) {
        $(".timeSlotData").removeClass("activeSlot");
      }
    },
    //
    getSingleRamdomeDateTimeRange(data, id) {
      let length = Object.keys(
        data.hasOwnProperty("time_slots") ? data.time_slots : []
      ).length;

      let rand = Math.random();

      if (
        data.time_slots[Math.floor(rand * length)].hasOwnProperty("start_time")
      ) {
        let start_time = data.time_slots[Math.floor(rand * length)].start_time;

        let end_time = data.time_slots[Math.floor(rand * length)].end_time;

        //return this.getDateDay(start_time);

        let t = setTimeout(() => {
          //console.log(id);
          //console.log($("#eng_day_" + id).html());
          $("#eng_day_" + id).attr("data-start", start_time);
          $("#eng_day_" + id).attr("data-end", end_time);

          $("#eng_day_" + id).attr("data-id", id);
          clearTimeout(t);
        }, 2500);

        return this.getDateTimeRange(start_time, end_time);
      }
    },
    getRamdomeDateTimeRange() {
      let length = Object.keys(
        this.NylasAvailabilityRecord.hasOwnProperty("time_slots")
          ? this.NylasAvailabilityRecord.time_slots
          : []
      ).length;

      let rand = Math.random();

      if (
        this.NylasAvailabilityRecord.time_slots[
          Math.floor(rand * length)
        ].hasOwnProperty("start_time")
      ) {
        let start_time =
          this.NylasAvailabilityRecord.time_slots[Math.floor(rand * length)]
            .start_time;

        let end_time =
          this.NylasAvailabilityRecord.time_slots[Math.floor(rand * length)]
            .end_time;

        //return this.getDateDay(start_time);
        return this.getDateTimeRange(start_time, end_time);
      }
    },
    getRamdomDay() {
      let length = Object.keys(
        this.NylasAvailabilityRecord.hasOwnProperty("time_slots")
          ? this.NylasAvailabilityRecord.time_slots
          : []
      ).length;

      let rand = Math.random();

      if (
        this.NylasAvailabilityRecord.time_slots[
          Math.floor(rand * length)
        ].hasOwnProperty("start_time")
      ) {
        let start_time =
          this.NylasAvailabilityRecord.time_slots[Math.floor(rand * length)]
            .start_time;

        return this.getDateDayShort(start_time);
      }
    },

    getSingleRamdomDay(data) {
      let length = Object.keys(
        data.hasOwnProperty("time_slots") ? data.time_slots : []
      ).length;

      let rand = Math.random();

      if (
        data.time_slots[Math.floor(rand * length)].hasOwnProperty("start_time")
      ) {
        let start_time = data.time_slots[Math.floor(rand * length)].start_time;

        return this.getDateDayShort(start_time);
      }
    },

    getDateDay(timestamp) {
      const milliseconds = timestamp * 1000;
      const dateObject = new Date(milliseconds);
      let day_of_the_week = dateObject.toLocaleString("en-US", {
        weekday: "long",
      }); // Monday

      return day_of_the_week;
    },

    getDateDayShort(timestamp) {
      const milliseconds = timestamp * 1000;
      const dateObject = new Date(milliseconds);
      let day_of_the_week = dateObject.toLocaleString("en-US", {
        weekday: "short",
      }); // Monday

      return day_of_the_week;
    },

    getDateTimeRange(timestampStart, timestampEnd) {
      const milliseconds1 = timestampStart * 1000;
      const dateObject1 = new Date(milliseconds1);

      let hour1 = dateObject1.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
      let minute1 = dateObject1.toLocaleString("en-US", { minute: "numeric" }); // 30

      const milliseconds2 = timestampEnd * 1000;
      const dateObject2 = new Date(milliseconds2);

      let hour2 = dateObject2.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
      let minute2 = dateObject2.toLocaleString("en-US", { minute: "numeric" }); // 30

      return ` ${hour1.split(" ")[0]}:${
        minute1.length == 1 ? minute1 + "0" : minute1
      } ${hour1.split(" ")[1]} - ${hour2.split(" ")[0]}:${
        minute2.length == 1 ? minute2 + "0" : minute2
      } ${hour2.split(" ")[1]}`;
    },

    getDateData(timestamp) {
      const milliseconds = timestamp * 1000;
      const dateObject = new Date(milliseconds);

      let day_of_the_week = dateObject.toLocaleString("en-US", {
        weekday: "short",
      }); // Monday
      let month = dateObject.toLocaleString("en-US", { month: "short" });
      let day = dateObject.toLocaleString("en-US", { day: "numeric" }); // 9
      let hour = dateObject.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
      let minute = dateObject.toLocaleString("en-US", { minute: "numeric" }); // 30

      return `${day_of_the_week} ${month} ${day} @ ${hour.split(" ")[0]}:${
        minute.length == 1 ? minute + "0" : minute
      } ${hour.split(" ")[1]}`;
    },
    obscureEmail(email) {
      const [name, domain] = email.split("@");
      return `${name[0] + name[1]}${new Array(name.length).join(
        "*"
      )}${name.substring(name.length - 2, name.length)}@${domain}`;
    },

    async getGrupaNylasRecordFunction() {
      if (!this.isGetNylasData) {
        let payload = {
          email: this.currentUser.email, //"okechukwu0127@gmail.com", //
        };
         this.isReloadingContentError=""
        this.isReloadingContent = true;
        this.isLoadingCalenderMsg = "Initializing...";
        await getGrupaNylasRecord(payload)
          .then((res) => {
            this.devNylasRecord = res.data.data;
            //console.log(JSON.stringify(res.data.data));
            this.isGetNylasData = true;
            this.isReloadingContent = false;
          })
          .catch((error) => {
            this.isReloadingContent = true;
            this.isReloadingContentError=error
            console.log("ERROR getGrupaNylasRecord: ", error);
          });
      }
    },

    async getNylasSingleFreeBusyFunction(data) {
      //  var end_date = new Date().toISOString().split("T")[0] + " 22:00:00";

      var end_date_raw = new Date(Date.now() + 24 * (60 * 60 * 1000)); // new Date(Date.now()).getTime() + 60 * 60 * 24 * 1000;
      //new Date().toISOString().split("T")[0] + " 22:00:00"

      var start_time_raw = this.start_time_raw;

      let st_time = this.changeTimeZoneTimestamp(
        this.start_time_raw_now,
        data.data.timezone
      ).toFixed(0);

      let ed_time = this.changeTimeZoneTimestamp(
        end_date_raw,
        data.data.timezone
      ).toFixed(0);

      /* console.log(
        st_time +
          " - " +
          ed_time +
          " = " +
          data.data.timezone +
          " - " +
          data.email +
          "-" +
          end_date_raw
      );

      console.log("==== " + this.start_time_raw_now); */

      //console.log(data)

      let payload = {
        emailSet: `[${JSON.stringify(data.email)}]`,
        // start_time: this.start_time,
        start_time: st_time,
        // end_time: parseInt((new Date(end_date).getTime() / 1000).toFixed(0)),
        end_time: ed_time,
       // token: this.token,
        token: data.data.token,
      };
      //console.log(payload);

      this.isLoadingSingleCalender = true;
      this.isLoadingSingleCalenderMsg = "Syncing Engineers Calendars...";

      await getNylasFreeBusy(payload)
        .then((res) => {
          if (res.data.data.response.hasOwnProperty("message")) {
            // console.log(res.data.data.response.message);
            this.NylasSingleFreeBusyError = res.data.data.response.message;
           // console.log(data)
            this.NylasSingleFreeBusyErrorEmail= this.NylasSingleFreeBusyErrorEmail+' - '+data.email

             this.NylasSingleFreeBusyErrorUser =  this.NylasSingleFreeBusyErrorUser + ' - '+data.data.name;
          } else {
            // this.isLoadingSingleCalender = false;
            this.isLoadingSingleCalenderMsg =
              "Loading Engineers Availability...";

            let response = {
              email: data.email,
              data: res.data.data.response,
              eng: data.data,
            };
            this.NylasSingleFreeBusyRecord.push(response);
          }
        })
        .catch((err) => {
          this.isLoadingSingleCalender = false;
          this.NylasSingleFreeBusyError = err
           this.NylasSingleFreeBusyErrorUser = data.data.name;
          this.NylasSingleFreeBusyErrorEmail=data.email
          console.log("SingleFreeBusy ERROR :: ", err);
        });
    },
    changeTimeZoneTimestamp(date, timeZone) {
      try {
        // console.log("date :--: ", date);

        // return date.getTime()

        return (
          new Date(
            date.toLocaleString("en-US", {
              // timeZone,
            })
          ).getTime() / 1000
        );
      } catch (e) {
        console.log(e);
        return new Date(date.toLocaleString("en-US")).getTime() / 1000;
      }
    },

    async getNylasFreeBusyFunction() {
      var end_date = new Date().toISOString().split("T")[0] + " 22:00:00";

      var end_date_raw = new Date(Date.now() + 24 * (60 * 60 * 1000)); // new Date(Date.now()).getTime() + 60 * 60 * 24 * 1000;
      //new Date().toISOString().split("T")[0] + " 22:00:00"

      var start_time_raw = this.start_time_raw;

      let st_time = this.changeTimeZoneTimestamp(
        this.start_time_raw_now
        //data.data.timezone
      ).toFixed(0);

      let ed_time = this.changeTimeZoneTimestamp(
        end_date_raw
        //data.data.timezone
      ).toFixed(0);

      let payload = {
        emailSet: JSON.stringify(this.emailSet),
        start_time: this.start_time,
        start_time: parseInt(st_time),

        // start_time_row:this.changeTimeZoneTimestamp(this.start_time_raw,),
        // // end_time: this.start_time + 30 * 60 * 60 * 0.5,
        //end_time: parseInt((new Date(end_date).getTime() / 1000).toFixed(0)),
        end_time: parseInt(ed_time),

        token: this.token,
      };

      //
      //console.log(payload);

      this.isLoadingCalender = true;
      this.isLoadingCalenderMsg = "Syncing Calendars...";

      getNylasFreeBusy(payload)
        .then((res) => {
          if (res.data.data.response.hasOwnProperty("message")) {
            // console.log(res.data.data.response.message);
            this.NylasFreeBusyError = res.data.data.response.message;
            this.isLoadingCalender = false;
          } else {
            this.NylasFreeBusyRecord = res.data.data.response;
            this.isLoadingCalender = false;

            //console.log(res.data.data.response);
          }
        })
        .catch((err) => {
          this.isLoadingCalender = false;
          console.log(err);
          this.NylasFreeBusyError = err;
        });
    },

    getNylasAvailabilityFunction() {
      // var end_date = new Date().toISOString().split("T")[0] + " 22:00:00";

      var end_date_raw = new Date(Date.now() + 24 * (60 * 60 * 1000)); // new Date(Date.now()).getTime() + 60 * 60 * 24 * 1000;

      let st_time = this.changeTimeZoneTimestamp(
        this.start_time_raw_now
        //data2.data.timezone
      ).toFixed(0);

      let ed_time = this.changeTimeZoneTimestamp(
        end_date_raw
        //data2.data.timezone
      ).toFixed(0);

      let payload = {
        emails: this.currentUser.email,
        // start_time: this.start_time,
        start_time: parseInt(st_time),

        // end_time: this.start_time + 30 * 60 * 60 * 0.5,
        // end_time: parseInt((new Date(end_date).getTime() / 1000).toFixed(0)),
        end_time: parseInt(ed_time),

        token: this.token,
        duration_minutes: 30,
        interval_minutes: 30,
        free_busy: this.NylasFreeBusyRecord,
      };

      //console.log(payload);

      this.isLoadingCalender = true;
      this.isLoadingCalenderMsg = "finalizing...";

      getNylasAvailability(payload)
        .then((res) => {
          this.NylasAvailabilityRecord = res.data.data.response;
          this.isLoadingCalender = false;
        })
        .catch((err) => {
          this.isLoadingCalender = false;
          console.log(err);
        });
    },

    async getNylasSingleAvailabilityFunction(data) {
      // var end_date = new Date().toISOString().split("T")[0] + " 22:00:00"; //new Date(myStringDate).getTime() + 60 * 60 * 24 * 1000

      var end_date_raw = new Date(Date.now() + 24 * (60 * 60 * 1000)); // new Date(Date.now()).getTime() + 60 * 60 * 24 * 1000;

      let st_time = this.changeTimeZoneTimestamp(
        this.start_time_raw_now
        //data2.data.timezone
      ).toFixed(0);

      let ed_time = this.changeTimeZoneTimestamp(
        end_date_raw
        //data2.data.timezone
      ).toFixed(0);

      let payload = {
        emails: this.currentUser.email,
        start_time: parseInt(st_time),
        // end_time: this.start_time + 30 * 60 * 60 * 0.5,
        // end_time: parseInt((new Date(end_date).getTime() / 1000).toFixed(0)),
        end_time: parseInt(ed_time),

        token: this.token,
        duration_minutes: 30,
        interval_minutes: 30,
        free_busy: this.NylasSingleFreeBusyRecord[data.index].data,
      };

      //console.log(payload);

      this.isLoadingSingleCalender = true;
      this.isLoadingSingleCalenderMsg = "Finalizing Calendar Sync...";

      await getNylasAvailability(payload)
        .then((res) => {
          // this.NylasAvailabilityRecord = res.data.data.response;
          // this.isLoadingSingleCalender = false;
          let rec = {
            data: res.data.data.response,
            eng: this.NylasSingleFreeBusyRecord[data.index].eng,
          };
          this.NylasSingleAvailabilityRecord.push(rec);
         // alert('NylasSingleAvailabilityRecord')
          /* console.log(
          this.NylasSingleAvailabilityRecord
          ) */
        })
        .catch((err) => {
          this.NylasSingleAvailabilityRecordError =="Error"
          this.isLoadingSingleCalender = false;
          console.log(err);
          console.log("SingleAvailability ERROR :: ", err);
        });
    },

    handleDashboard() {
      
      let projectId = this.$route.params.id;
      let BaseUrl = Vue.prototype.$Website;

      
      
      if (projectId) {
         document.location.href=`${BaseUrl}/dashboard/project/${projectId}/workspace`
       
      }
    },
  },
  watch: {
    token: async function (newItem, oldItem) {
      let DispatchStep1 = [];

      let data = await JSON.parse(localStorage.getItem("engineerRole"));

      if (data.preview === true) {
        if (this.selectedTeamData.hasOwnProperty("role")) {
          for (let i = 0; i < this.selectedTeamData.role.length; i++) {
            let engineer = this.selectedTeamData.engineersData[i];

            if (Object(engineer).length > 0) {
              for (let k = 0; k < engineer.length; k++) {
                let data = {
                  email: engineer[k].email,
                  data: { ...engineer[k], role: this.selectedTeamData.role[i] },
                };
                DispatchStep1.push(this.getNylasSingleFreeBusyFunction(data));
              }

              // console.log(DispatchStep1);
              //console.log("Free :: ", this.NylasSingleFreeBusyRecord);
            }
          }
          this.isLoadingSingleCalender = true;

          Promise.all([...DispatchStep1])
            .then((resp) => {
              let DispatchStep2 = [];
              for (let i = 0; i < this.NylasSingleFreeBusyRecord.length; i++) {
                let data = {
                  index: i,
                  eng: this.NylasSingleFreeBusyRecord[i].eng,
                };

                DispatchStep2.push(
                  this.getNylasSingleAvailabilityFunction(data)
                );
              }

              /*  console.log(
                "====================================================="
              ); */
              //console.log(DispatchStep2);
              Promise.all([...DispatchStep2])
                .then((resp) => {
                  this.isLoadingSingleCalender = false;
                  /* console.log(
                    "Available : ",
                    this.NylasSingleAvailabilityRecord
                  ); */
                })
                .catch((error) => {
                  console.log("ERROR DispatchStep2:: ", error);
                });
            })
            .catch((error) => {
              console.log("ERROR DispatchStep1 :: ", error);
            });
        }
      }
    },
    devNylasRecord: function (newItem, oldItem) {
      if (newItem.hasOwnProperty("user"))
        if (newItem.user != null) {
          this.token = newItem.user.access_token;

          if (this.isLoadingCalender == false) {
            this.getNylasFreeBusyFunction();
          }
        } else {
         // $('#unVerfiedFouder').show();
          /* alert(
            "You have not Synced your calendar with us\nKindly Share your calendar profile with us to conntinue"
          ); */
        }
    },
    NylasFreeBusyRecord: function (newItem, oldItem) {
      if (typeof newItem === "object") {
        if (Object.keys(newItem).length > 0) {
          if (this.isLoadingCalender == false) {
            this.getNylasAvailabilityFunction();
          }
        } else {
          alert("Engineers have no free time within the next 24 hours");
        }
      }
    },
  },

  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),

     ...mapState("selectTeamForProject", ["selectedPlan", "availableTeams"]),
    
  },

  mounted() {
    this.$nextTick(async () => {
      $(".nav-tabs li.disabled").on("click", function (e) {
        //e.stopImmediatePropagation();
        // alert('ddd')
        $("#comingSoon").modal("show");
      });

      $("body").on("click", ".confirmTime", function () {
        if ($("#cal_day").val() == "") {
          alert("Kindly select a time slot to continue");
        } else {
          $("#eng_day_" + parseInt($("#eng_id_data").val()))
            .html($("#cal_day").val())
            .css("color", "#1b1e22");

          $("#eng_time_" + parseInt($("#eng_id_data").val())).html(
            $("#cal_time_range").val()
          );

          $("#eng_day_" + parseInt($("#eng_id_data").val())).attr(
            "data-start",
            $("#cal_start_time").val()
          );
          $("#eng_day_" + parseInt($("#eng_id_data").val())).attr(
            "data-end",
            $("#cal_end_time").val()
          );

          $("#eng_day_" + parseInt($("#eng_id_data").val())).attr(
            "data-id",
            $("#eng_id_data").val()
          );

          $("#exampleModal").modal("hide");
        }
      });

      $("body").on("click", ".timeSlotData_c", function () {
        const milliseconds = parseInt($(this).attr("data-start-time")) * 1000;

        const dateObject = new Date(milliseconds);

        let day_of_the_week = dateObject.toLocaleString("en-US", {
          weekday: "short",
        }); // Monday

        $("#cal_day_c").val(day_of_the_week);
        $("#cal_start_time_c").val(parseInt($(this).attr("data-start-time")));
        $("#cal_end_time_c").val(parseInt($(this).attr("data-end-time")));

        const milliseconds1 = parseInt($(this).attr("data-start-time")) * 1000;
        const dateObject1 = new Date(milliseconds1);

        let hour1 = dateObject1.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute1 = dateObject1.toLocaleString("en-US", {
          minute: "numeric",
        }); // 30

        const milliseconds2 = parseInt($(this).attr("data-end-time")) * 1000;
        const dateObject2 = new Date(milliseconds2);

        let hour2 = dateObject2.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute2 = dateObject2.toLocaleString("en-US", {
          minute: "numeric",
        }); // 30

        $("#cal_time_range_c").val(
          `${hour1.split(" ")[0]}:${
            minute1.length == 1 ? minute1 + "0" : minute1
          } ${hour1.split(" ")[1]} - ${hour2.split(" ")[0]}:${
            minute2.length == 1 ? minute2 + "0" : minute2
          } ${hour2.split(" ")[1]}`
        );

        $(".selected_conference_time").html(
          $("#cal_day_c").val() + " " + $("#cal_time_range_c").val()
        );
      });

      $("body").on("click", ".timeSlotData", function () {
        if ($(".timeSlotData").hasClass("activeSlot")) {
          $(".timeSlotData").removeClass("activeSlot");
        }

        $(this).addClass("activeSlot");

        //console.log($(this).find(".cal_start_time").html());

        const milliseconds =
          parseInt($(this).find(".cal_start_time").html()) * 1000;

        const dateObject = new Date(milliseconds);

        let day_of_the_week = dateObject.toLocaleString("en-US", {
          weekday: "long",
        }); // Monday

        $("#cal_day").val(day_of_the_week);
        $("#cal_start_time").val(
          parseInt($(this).find(".cal_start_time").html())
        );
        $("#cal_end_time").val(parseInt($(this).find(".cal_end_time").html()));

        const milliseconds1 =
          parseInt($(this).find(".cal_start_time").html()) * 1000;
        const dateObject1 = new Date(milliseconds1);

        let hour1 = dateObject1.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute1 = dateObject1.toLocaleString("en-US", {
          minute: "numeric",
        }); // 30

        const milliseconds2 =
          parseInt($(this).find(".cal_end_time").html()) * 1000;
        const dateObject2 = new Date(milliseconds2);

        let hour2 = dateObject2.toLocaleString("en-US", { hour: "numeric" }); // 10 AM
        let minute2 = dateObject2.toLocaleString("en-US", {
          minute: "numeric",
        }); // 30

        $("#cal_time_range").val(
          `${hour1.split(" ")[0]}:${
            minute1.length == 1 ? minute1 + "0" : minute1
          } ${hour1.split(" ")[1]} - ${hour2.split(" ")[0]}:${
            minute2.length == 1 ? minute2 + "0" : minute2
          } ${hour2.split(" ")[1]}`
        );

        //console.log("toggleClass");
      });


      let data = await JSON.parse(localStorage.getItem("engineerRole"));
      this.localStorageData = data
      if (data.isSelected) {

                this.proceedPreview = true;
               
               // data.preview = true;
       // data.isSelected = false;
                
        this.SelectedTimetoMeet = data.timeSlot;
        localStorage.setItem("engineerRole", JSON.stringify(data));

         this.current_page = 3;
      
        $(".calenderMain, .selectedTeamContainer").fadeOut(() => {
          $(".proceedToKickOff").fadeIn();
        });
      //$("#proceedModal").modal("hide");

       var uniqueNamesOld = [];

        let grouper =this.SelectedTimetoMeet.reduce((r, a) => {
                     // console.log("a", a);
                      //console.log('r', r);
                      uniqueNamesOld.push(a.role)
                      r[a.role] = [...r[a.role] || [], a];
                     return r;
        }, {}); 



                var uniqueNames = [];
                


                  $.each(uniqueNamesOld, function(i, el){
                        if($.inArray(el, uniqueNames) === -1) uniqueNames.push(el);
                });

                this.SelectedEngineerMeetingRole = uniqueNames

              //console.log(grouper)
              //console.log(this.SelectedEngineerMeetingRole)
            
              


              this.SelectedEngineerMeeting = grouper;

             //console.log(this.SelectedEngineerMeetingRole)
              // console.log(this.SelectedTimetoMeet)
                // console.log(this.SelectedEngineerMeeting)
              
                $(window).scrollTop(0);

         // document.location.reload();
      }
        
      if (this.selectedTeamData.hasOwnProperty('engineersData'))
      {

         for (let i = 0; i < (this.selectedTeamData.engineersData.length ); i++) {
        if (Object.keys(this.selectedTeamData.engineersData[i]).length > 0) {
          for (
            let k = 0;
            k < this.selectedTeamData.engineersData[i].length;
            k++
          ) {
            this.emailSet.push(this.selectedTeamData.engineersData[i][k].email);
          }
        }
      }

      }
     

      if (!this.isGetNylasData) {
        this.getGrupaNylasRecordFunction();
      }

      $("body>.work_viewLess").hide();
      $("body").on("click", ".work_viewMore", async function () {
     // $(".work_viewMore").on("click", function () {
       // alert('kk')
        $(".work_experienceData").css({ height: "auto" });
        $(this).hide();
        $(".work_viewLess").show();
      });

      $("body").on("click", ".work_viewLess", async function () {
      //$(".work_viewLess").on("click", function () {
       // alert(parseInt($(".work_experienceData").css('height')))
      //  if (parseInt($(".work_experienceData").css('height')) >= 270) {
          $(".work_experienceData").css({ height: "270px" });
        // }      
        
        $(this).hide();
        $(".work_viewMore").show();
      });

      function removeFromArray(arr, val) {
        for (var i = 0; i < arr.length; i++)
          if (arr[i] === val) arr.splice(i, 1);
        return arr;
      }

      function removeIndexFromArray(arr, key) {
        let removed = arr.splice(key, 1);

        return arr;
      }
      // this.testAlert('welcome')
      $("body").on("click", ".teamRemove", async function () {
        let index = $(this).attr("data-index");
        let user = $(this).attr("data-user");
        let key = $(this).attr("data-key");

        let data = await JSON.parse(localStorage.getItem("engineerRole"));
        removeFromArray(data.engineers[index], user);
        removeIndexFromArray(data.engineersData[index], key);

        localStorage.setItem("engineerRole", JSON.stringify(data));

        //selectedTeamDataMain_
        $(this).parents(".selectedTeamData_").fadeOut();
      });

      $(".confirbBtn").on("click", () => {
        this.current_page = 2;
        $(".selectedTeamContainer").fadeOut(() => {
          $(".calenderMain").fadeIn();
        });
        $(window).scrollTop(0);
      });

      $(".backbBtn").on("click", () => {
        this.current_page = 1;
        $(".calenderMain").fadeOut(() => {
          $(".selectedTeamContainer").fadeIn();
        });
        $(window).scrollTop(0);
      });

      //


       $(".sidebarBackButtonHome").on("click", async () => {
             
       });
      

      $(".sidebarBackButton").on("click", async () => {
        let data = await JSON.parse(localStorage.getItem("engineerRole"));

        data.preview = false;
        localStorage.setItem("engineerRole", JSON.stringify(data));

        document.location.reload();
        $(".teams-preview").fadeOut(() => {
          $(".teams-container").fadeIn();
        });
      });
    });
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}


.proceedBtn {
 
 
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  
  cursor: pointer;

  /* identical to box height, or 14px */

  align-items: center;
  text-align: center;

  color: #459bec;

  width: 104.1px;
  height: 49px;
  padding-top: 0px;

  background: rgba(164, 208, 249, 0.3);
  border-radius: 3px;
}

.nav-tabs li.disabled a {
  /*  pointer-events: none; */
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: silver;
  opacity: 0.65;
}

.change-slot {
  font-weight: 700;
  margin-left: 30px;
  font-size: 12px;
  cursor: pointer;
  color: #0b62b1;
}

.change-slot:hover {
  font-weight: 900;
  margin-left: 30px;
  font-size: 13px;
  cursor: pointer;
  color: #0b62b1;
}

.timeSlotData {
  padding: 8px 16px;
  gap: 4px;
  max-width: 200px;
  text-align: center;
  min-width: 113px;
  height: 39px;
  cursor: pointer;
  margin-left: 10px;
  /* accent/500 */

  background: #ffffff;
  /* primary/200 */

  border: 1px dashed #c8cbd0;
  border-radius: 50px;
  margin-top: 15px;
}

.activeSlot {
  background: #0781f2;
  /* accent/500 */

  color: #ffffff;
  border: 1px dashed #0781f2;
  border-radius: 50px;
}

.timeSlot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 131px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.scheduleEngCard {
  margin-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.Eng {
  height: 40px;
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  padding: 0px;
  gap: 16p;
}

.scheduleTime {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  text-transform: uppercase;
  margin-bottom: 30px;

  /* primary/300 */

  color: #979da5;
  text-align: right;
}

.scheduleEng {
  margin-top: 16px;
  min-width: 479px;
  max-width: 540px;

  height: auto;
  left: 0px;
  padding: 20px;

  /* primary/25 */

  background: #fdfdfd;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;
}

.reviewTitleSub {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  width: 478px;
  /* or 20px */

  /* primary/300 */

  color: #979da5;
}

.reviewTitle {
  margin-top: 70px;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/700 (text) */

  color: #1b1e22;
}

.IconFooter {
  padding-left: 1px;
  padding-right: 15px;
  cursor: pointer;
}

.emailText {
  color: #1b1e22;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.calederIcon {
  padding-right: 10px;
}

.horizonatlLine {
  width: 478px;
  height: 0px;
  left: 0px;
  top: 155px;

  opacity: 0.5;
  /* primary/100 */

  border: 1px solid #e4e5e7;
}

.clickMore {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  text-align: right;
  /* or 20px */

  /* primary/300 */

  color: #979da5;
}

.disconectText {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  /* identical to box height, or 17px */

  /* primary/500 */

  color: #53585f;
  text-align: center;
}







.calenderConnect  {
  background: #fdfdfd;
  width: 479px;
  height: 124px;
  left: 0px;
  top: 83px;
  border: 1px solid #e4e5e7;
  border-radius: 8px;

  margin-top: 20px;
  padding: 20px;
  padding-right: 0px;
}

.calenderMain {
  display: none;
}

.proceedToKickOff
{
   display: none;

}

.selectedTeamContainer, .proceedToKickOffData {

  margin-left:20px
  
}

.teamData {
  padding-top: 10px;
  margin-left: 5px;
  /* width: 60%;
  border: 0px solid red;
  padding: 10px; */
}

.teamDataList {
  margin-top: 3px;
}

.confirbBtn {
  margin-top: 50px;
}

.locationData {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 125%;
  /* identical to box height, or 15px */

  /* primary/400 */

  color: #6f7680;
  padding: 10px;
}

.teamData .teamDataTitle,
.teamDataTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  /* primary/700 (text) */

  color: #1b1e22;
}

.teamImg {
  border: 0px solid red;
  height: 100%;
  align-self: center;
  margin-top: 20%;
}
.teamRemove {
  /*  width: 3%;
  margin-right: 14px; */
  margin-top: 1px;
  cursor: pointer;
}

.selectedTeam {
  margin-top: 40px;
}

.selectedTeam .selectedRole {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/700 (text) */

  color: #1b1e22;
  margin-bottom: 10px;
}

.selectedTeamDataMain_ {
  background: #fdfdfd;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;
  padding-bottom: 9px;
}
.selectedTeam .selectedTeamData_ {
  /*
   min-height: 92px;
    flex-direction: row;
   align-items: flex-start;
  padding: 0px;
  gap: 10px;
  display: flex;
  width: 340px;

  
  
  margin-top: 20px;
  margin-left: 40px;


  flex: none;
  order: 1;
  flex-grow: 0;
  z-index: 1;  */
}

img.teamImgData {
  /*  min-width: 70px; */
  width: 70px;

  margin-left: 10px;
  vertical-align: middle;

  border-radius: 5px;
}

.titleHeader {
  padding: 0px;
}

.titleHeader .mainHeader {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
  /* identical to box height */

  /* primary/700 (text) */

  color: #1b1e22;
}

.titleHeader .subHeader {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  width: 478px;
  margin-top: 10px;
  /* or 23px */

  /* primary/300 */

  color: #979da5;
}

.containerPreview {
  padding: 30px;
}
.sidebar {
  left: 0px;
  top: 0px;
  height: 1230px;
  text-align: center;

  /* primary/50 */

  background: #f7f7f8;
}

.sidebar .sidebarIMG {
  margin-top: 30%;
  width: 70%;
}

.sidebar .sidebarText {
  margin-top: 0px;
  padding: 50px;
  text-align: left;
}



.sidebar .sidebarBackButton, .sidebar .sidebarBackButtonHome {
  margin-top: -20px;
  text-align: left;
  padding-left: 50px;
  cursor: pointer;
}

.sidebar .sidebarBackButton .text, .sidebar .sidebarBackButtonHome .text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 6px;
  /* identical to box height */

  /* accent/500 */

  color: #0781f2;
}

.tabs-container {
  display: flex;
  align-items: center;
  margin: 30px;
  margin-top: 7%;
}
.tab-box {
  display: flex;
  align-items: center;
}
.tab-button {
  padding: 8px 12px;
  color: #979da5;
  border: 1px solid #979da5;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  cursor: pointer;
}
.tab-line {
  border-top: 1px solid #e4e5e7;
  width: 80px;
  height: 1px;
}
.active-tab {
  border: 1px solid #1fa564;
  color: #1fa564;
}
.completed-tab {
  background: #53585f;
  color: #ffffff;
}
.right-side {
  width: calc(100% - 382px);
  margin-left: 382px;
}
@media (max-width: 1280px) {
  .right-side {
    width: 75%;
    margin-left: 25%;
  }
}
@media (max-width: 768px) {
  .right-side {
    width: 100%;
    margin-left: 0%;
  }
}

</style>
