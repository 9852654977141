<template>
  <div>
    <!-- Another Model -->

    <div
      class="modal fade"
      id="unVerfiedSuccess"
      tabindex="-1"
      aria-labelledby="unVerfiedSuccessLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="unVerfiedSuccessLabel">
              CONNECTION REQUEST SENT
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p style="text-align: center">
              <img
                src="/img/success-mark.svg"
                style="width: 150px; margin-top: 40px"
              />
            </p>
            <p style="text-align: center">
              We received your request to connect with {{ unverifiedEngineer }}.
              <br />
              <b>Please proceed to selecting other team members</b><br />
              who have synced their calendar
            </p>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="unVerfied"
      tabindex="-1"
      aria-labelledby="unVerfiedLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="unVerfiedLabel">REQUEST CONNECTION</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p style="text-align: center">
              <img
                src="/img/calendar_error.png"
                style="width: 150px; margin-top: 40px"
              />
            </p>
            <p style="text-align: center">
              This engineer's calendar has not been synchronized.<br />
              Let us help you get connected
            </p>
            <br /><br />

            <center>
              <img
                v-if="connectLoader"
                src="/img/lightbox/preloader.gif"
                style="margin-left: 10px"
                class="button-spinner"
              />
              <button
                v-if="!connectLoader"
                style="padding: 10px; padding-left: 15px; padding-right: 15px"
                type="button"
                @click="requestEngineerConnect"
                class="btn btn-primary"
              >
                Connect with {{ unverifiedEngineer }}
              </button>
            </center>
            <!-- 

               data-bs-dismiss="modal"
                 data-bs-target="#unVerfiedSuccess"
               data-bs-target="#unVerfiedSuccess"
              We received your request to connect, please proceed to selecting other team members who have synced their calendar
             -->

            <!-- <p>
              <div class="alert alert-warning" role="alert" >
                Please reach out to <a href="mailto:shany@grupa.io">shany@grupa.io</a> to schedule a conversation
              </div>
            </p> -->
            <br />
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>

    <!--  From A: {{ store.count }} -->
    <div
      class="dev-info_"
      v-for="(data, i) in bestFitTeam.matchRole"
      :key="i"
      :id="`role_data_${i}`"
    >
      <div class="alert bg-white Role-Hearder" role="alert">
        <span class="Role-Hearder-title">{{ data + " " }}</span>

        <span class="badge bg-secondary">
          {{ bestFitTeam.matchUser[i].length }}
        </span>

        <span class="Role-Hearder-sub">
          &nbsp;&nbsp; &nbsp;&nbsp;
          <span :id="`counter${i}`">{{ selectedEngineerCount(i) }}</span>
          selected
        </span>
      </div>

      <div class="row align-items-start g-20 p-2">
        <!-- <div style="font-size:4px">{{bestFitTeam.matchUser[i]}}</div> -->

        <div
          :data-index="`${i}`"
          :class="` contentBlock${k} col-md-6 col-lg-4  col-xl-4 col-sm-12  col-xs-12  mb-5  contentBlock viewUser`"
          v-for="(item, k) in bestFitTeam.matchUser[i]"
          :data-user="`${JSON.stringify(item)}`"
          :key="k"
          :data-key="`${k}`"
          :data-name="`${item.user.name}`"
          :data-image="`${
            typeof item.user.profile_picture !== 'object'
              ? 'https://grupa-media.s3-us-west-2.amazonaws.com/live/' +
                  item.user.profile_picture ||
                '/img/onboarding/profile-avatar.svg'
              : '/img/onboarding/profile-avatar.svg'
          }`"
          :data-location="`${
            item.user.user_location ? item.user.user_location : 'Unknown'
          }`"
          :data-organisation="`${
            item.user.work_experience[0] !== undefined
              ? item.user.work_experience[0].organization
              : 'None'
          }`"
        >
          <div
            :class="`p-4 card-item ${selectedEngineerCard(i, item.user.id)}`"
          >
            <!--  <div style="font-size:4px">{{item}}</div>
 -->

            <div class="row p-2">
              <div class="col-10 item-title">
                {{ item.user.name }}
                <div class="item-title-sub">
                  {{ item.user.work_experience_count }} year{{
                    item.user.work_experience_count > 0 ? "s" : null
                  }}
                  of experience
                </div>
              </div>

              <div class="col-2">
                <img class="marker" src="/img/mark.svg" />

                <!-- <img src="/img/mark_check.svg" /> -->

                <br />

                <br />
              </div>
            </div>

            <ul class="list-group no-border mt-3">
              <li
                class="list-group-item no-border item-data justify-content-between align-items-center"
              >
                <span class="badge rounded-pill">
                  <img src="/img/Map-location.svg" />
                </span>
                {{
                  item.user.user_location ? item.user.user_location : "Unknown"
                }}
              </li>

              <li
                :style="`display:${hideList(
                  item.user.work_experience[0] !== undefined
                    ? item.user.work_experience[0].organization.length > 0
                      ? true
                      : false
                    : false
                )};`"
                class="list-group-item item-data no-border justify-content-between align-items-center"
              >
                <span class="badge rounded-pill">
                  <img src="/img/Bag.svg" />
                </span>
                &nbsp;
                {{
                  item.user.work_experience[0] !== undefined
                    ? item.user.work_experience[0].organization.length > 21
                      ? item.user.work_experience[0].organization.slice(0, 22) +
                        "..."
                      : item.user.work_experience[0].organization
                    : "None"
                }}
              </li>

              <li
                :style="`display:${hideList(
                  LanguageTool(item.user.language_tools) != 'Empty' &&
                    LanguageTool(item.user.language_tools) !== 'null' &&
                    LanguageTool(item.user.language_tools) !== '[]' &&
                    LanguageTool(item.user.language_tools) !== []
                    ? true
                    : false
                )}`"
                class="list-group-item item-data no-border justify-content-between align-items-center"
              >
                <span class="badge rounded-pill">
                  <img src="/img/Stack.svg" />
                </span>

                <span class="list-group-item-text">
                  {{ LanguageTool(item.user.language_tools) }}
                </span>
              </li>

              <li
                :style="`display:${hideList(
                  LanguageTool(item.user.team_interest) != 'Empty'
                    ? true
                    : false
                )}`"
                class="list-group-item item-data no-border justify-content-between align-items-center"
              >
                <span class="badge rounded-pill">
                  <img src="/img/Shape_2.svg" />
                </span>

                <span class="list-group-item-text"
                  >&nbsp;
                  {{ LanguageTool(item.user.team_interest) }}
                </span>
              </li>
            </ul>

            <div
              class="row p-0 mt-4"
              style="bottom: 14px; position: absolute; width: 90%"
            >
              <div class="col">
                <div class="matchData" style="padding-top: 5px">
                  {{ item.total_percentage.toFixed(2) }}% Match
                </div>
              </div>
              <div class="col">
                <!-- 
                   onclick="javascript:alert('Engineers calender have not been synchronized\nThus, you can not schedule a meeting with him/her')"
                 -->
                <div
                  @click="
                    getEngieersName(
                      item.user.name,
                      item.user.id,
                      item.user.email
                    )
                  "
                  data-bs-toggle="modal"
                  data-bs-target="#unVerfied"
                  class="btn btn-unverified"
                  :data-name="`${item.user.name}`"
                  :style="`float:right;display:${
                    Object.keys(item.user.nylas_auth).length <= 0
                      ? 'flex'
                      : 'none'
                  };border:1px solid #0781f2;color:rgb(144 165 185);padding:3px;padding-left:8px;padding-right:8px;margin-top:10px;width:100px;font-size:12px;margin-left:20px`"
                >
                  <span class=""
                    ><img
                      src="/img/calendar_error.png"
                      style="width: 20px; margin-bottom: 2px"
                  /></span>

                  &nbsp;&nbsp;Connect
                </div>
                <div
                  :style="`float: right;margin-left:0px;display: ${
                    selectedEngineerCard(i, item.user.id) ||
                    Object.keys(item.user.nylas_auth).length <= 0
                      ? 'none'
                      : 'flex'
                  }`"
                  class="btn btn-primary_ btnAction"
                  :id="`btnAction_${k}_${i}`"
                  :data-index="`${i}`"
                  :data-key="`${k}`"
                  :data-email="`${item.user.email}`"
                  :data-user="`${item.user.id}`"
                  :data-name="`${item.user.name}`"
                  :data-timezone="`${
                    Object.keys(item.user.nylas_auth).length > 0
                      ? item.user.nylas_auth[0].primary_calendar_timezone
                      : null
                  }`"
                  :data-token="`${
                    Object.keys(item.user.nylas_auth).length > 0
                      ? item.user.nylas_auth[0].access_token
                      : null
                  }`"
                  :data-image="`${
                    typeof item.user.profile_picture !== 'object'
                      ? 'https://grupa-media.s3-us-west-2.amazonaws.com/live/' +
                          item.user.profile_picture ||
                        '/img/onboarding/profile-avatar.svg'
                      : '/img/onboarding/profile-avatar.svg'
                  }`"
                  :data-location="`${
                    item.user.user_location
                      ? item.user.user_location
                      : 'Unknown'
                  }`"
                  :data-organisation="`${
                    item.user.work_experience[0] !== undefined
                      ? item.user.work_experience[0].organization
                      : 'None'
                  }`"
                >
                  <span
                    class="badge bg-primary"
                    :style="`padding-top: 0px; opacity: 0.6; font-size: 14px; `"
                    >+</span
                  >
                  &nbsp; &nbsp;Add &nbsp; &nbsp;
                </div>

                <div
                  :style="`float: right; display: ${
                    selectedEngineerCard(i, item.user.id) ? 'block' : 'none'
                  }`"
                  :id="`btnActionRemove_${k}_${i}`"
                  class="btn bg-light btnActionRemove"
                  :data-index="`${i}`"
                  :data-key="`${k}`"
                  :data-user="`${item.user.id}`"
                >
                  <span
                    class="badge bg-danger"
                    style="padding-top: 0px; opacity: 0.6; font-size: 11px"
                    >-</span
                  >
                  &nbsp;Remove
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div>
          <div :class="`loadMore  displayNone ${'hideMore' + i}`">
            Load
            <b style="font-weight: 800">less</b>

            <img width="15" src="/img/up-arrow.png" />
          </div>

          <div :class="`loadMore  ${'loadMore' + i}`">
            Load
            <b style="font-weight: 800">more</b>

            <img width="15" src="/img/down-arrow.png" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import moment from "moment";
import { mapState, mapActions } from "vuex";

//import { store } from './../../../../store/reactive/store'
import { RequestEngineerConnect } from "@/api/client";

export default {
  props: [
    "bestFitTeam",
    "checkUnderscore",
    "selected_region",
    "teamStack",
    "abbreviateFullName",
    "industries",
    "imgPath",
    "viewTeam",
    "getTimeZone",
    "teamFrameWork",
    "imageExists",
    "compareTeamClientTimeZone",
    "topTeam",
    "Carousel",
  ],
  components: {
    // PortfolioCard,
    //Carousel,
  },

  data: () => ({
    frame: 3,
    count: 0,
    test: "/img/profile.svg",
    unverifiedEngineer: "",
    unverifiedEngineerId: "",
    unverifiedEngineerEmail: "",
    connectLoader: false,

    // store,
  }),

  created() {},
  methods: {
    getEngieersName(name, id, email) {
      this.unverifiedEngineer = name;
      this.unverifiedEngineerId = id;
      this.unverifiedEngineerEmail = email;
      // console.log(this.unverifiedEngineer)
      // console.log(this.unverifiedEngineerId)
    },
    async requestEngineerConnect() {
      let payload = {
        client_id: this.currentUser.id,
        client_email: this.currentUser.email,
        client_name: this.currentUser.name,
        project_id: this.clientProject.id,
        project_name: this.clientProject.project_name,

        engineer_name: this.unverifiedEngineer,
        engineer_email: this.unverifiedEngineerEmail,
        engineer_id: this.unverifiedEngineerId,

        subject: "Engineer Connection Request",
      };

      this.connectLoader = true;
      await RequestEngineerConnect(payload)
        .then((data) => {
          console.log(data);
          this.connectLoader = false;

          window.$("#unVerfied").modal("hide");
          window.$("#unVerfiedSuccess").modal("show");
        })
        .catch((error) => {
          this.connectLoader = false;
          console.log(error);
          alert("An unexpected error occured");
        });
    },

    hideList(data) {
      return data === true ? "block" : "none";
    },
    selectedEngineerCount(index) {
      if (localStorage.getItem("engineerRole") !== null) {
        try {
          let ct = JSON.parse(localStorage.getItem("engineerRole")).engineers[
            index
          ].length;
          return ct;
        } catch (e) {
          return 0;
        }
      } else {
        return 0;
      }
    },

    selectedEngineerCard(index, user) {
      if (localStorage.getItem("engineerRole") !== null) {
        if (
          JSON.parse(localStorage.getItem("engineerRole")).engineers[
            index
          ].includes(user.toString())
        ) {
          return "selectedEngineer";
        }
      }
    },

    LanguageTool(text) {
      if (text) {
        let data = text
          .replaceAll('["', "")
          .replaceAll('","', ", ")
          .replaceAll('"]', "")
          .slice(0, 22);

        if (data.length > 21) {
          return data + "...";
        } else {
          return data + "";
        }
      } else {
        return "Empty";
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),
  },
  //watch() {},
  mounted() {
    this.$nextTick(async () => {
      function removeFromArray(arr, val) {
        for (var i = 0; i < arr.length; i++)
          if (arr[i] === val) arr.splice(i, 1);
        return arr;
      }

      function removeIndexFromArray(arr, key) {
        let removed = arr.splice(key, 1);
        //console.log(removed)
        //console.log(arr)

        //console.log(key)
        return arr;
      }

      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }

      //

      $("body").on("click", ".btnActionRemove", async function () {
        let index = $(this).attr("data-index");
        let user = $(this).attr("data-user");
        let key = $(this).attr("data-key");

        let data = await JSON.parse(localStorage.getItem("engineerRole"));
        removeFromArray(data.engineers[index], user);
        removeIndexFromArray(data.engineersData[index], key);

        // data.engineersData[index].push(newArray);
        //console.log(data)
        localStorage.setItem("engineerRole", JSON.stringify(data));

        $(this).parents("div").prev(".row");
        $(this).parents(".card-item").removeClass("selectedEngineer");

        $(this).parents(".col").find(".btnAction").show();

        $("#counter" + index).html(parseInt($("#counter" + index).html()) - 1);

        let oldValue = parseInt($(".totalSelected").html())
          ? parseInt($(".totalSelected").html())
          : 0;

        $(".totalSelected").html(oldValue - 1);

        $(this).hide();
        if (parseInt($(".totalSelected").html()) > 0) {
          $("#pButton").removeClass("proceedBtn").addClass("proceedBtnActive");
        } else {
          $("#pButton").removeClass("proceedBtnActive").addClass("proceedBtn");
        }
      });

      $("body").on("click", ".btnAction, .addToTeam", async function () {
        let index = $(this).attr("data-index");
        let user = $(this).attr("data-user");
        let key = $(this).attr("data-key");

        let location = $(this).attr("data-location");
        let name = $(this).attr("data-name");
        let organisation = $(this).attr("data-organisation");
        let image = $(this).attr("data-image");
        let email = $(this).attr("data-email");
        let timezone = $(this).attr("data-timezone");
        let token = $(this).attr("data-token");

        // $(".addToTeam").attr("data-index", index);
        //$(".addToTeam").attr("data-user", user);
        //$(".addToTeam").attr("data-location", location);
        //$(".addToTeam").attr("data-organisation", organisation);
        //$(".addToTeam").attr("data-key", key);
        //$(".addToTeam").attr("data-image", image);

        let newArray = {
          id: user,
          location: location,
          name: name,
          image: image,
          organisation: organisation,
          email: email,
          timezone: timezone,
          token: token,
        };

        // console.log(newArray)
        // alert(index)

        let data = await JSON.parse(localStorage.getItem("engineerRole"));

        data.engineers[index].push(user);
        data.engineersData[index].push(newArray);
        //console.log(data);
        //data.engineersData[index].push
        localStorage.setItem("engineerRole", JSON.stringify(data));

        //console.log(localStorage.getItem("engineerRole"));

        // this.selectedEngineerCard(index, user);
        /*  this.$store.commit("selectTeamForProject/pushEngineer", {
            index: index,
            data: user
          }); */
        /* console.log(
          this.$store.state.selectTeamForProject.engineerRole.engineers
        ); */

        $(this).parents("div").prev(".row");
        $(this).parents(".card-item").addClass("selectedEngineer");

        $("#counter" + index).html(parseInt($("#counter" + index).html()) + 1);

        let oldValue = parseInt($(".totalSelected").html())
          ? parseInt($(".totalSelected").html())
          : 0;

        $(".totalSelected").html(oldValue + 1);

        $(this).hide();
        $(this).parents(".col").find(".btnActionRemove").show();

        //console.log(`#btnActionRemove_${key}_${index}`);
        //console.log($(`#btnActionRemove_${key}_${index}`).html());

        $(`#btnAction_${key}_${index}`).hide();
        $(`#btnActionRemove_${key}_${index}`).show();

        if (parseInt($(".totalSelected").html()) > 0) {
          $("#pButton").removeClass("proceedBtn").addClass("proceedBtnActive");
        } else {
          $("#pButton").removeClass("proceedBtnActive").addClass("proceedBtn");
        }
      });

      function returnDate(objDate) {
        var month = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        var objDate = new Date(objDate);
        // console.log(objDate.getMonth())
        //console.log(objDate.getFullYear())
        return month[objDate.getMonth()] + " " + objDate.getFullYear();
      }

      function capitalizeData(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

      function returnDateYear(objDate) {
        var objDate = new Date(objDate);
        // console.log(objDate.getMonth())
        //console.log(objDate.getFullYear())
        return objDate.getFullYear();
      }

      function compensationMethod(b) {
        let a = parseInt(b);
        if (a == null) {
          return "";
        }
        switch (a) {
          case 1:
            return "Cash Only";
          case 2:
            return "A mix of cash & equity";
          case 3:
            return "Equity only";
          case 4:
            return "I’m not sure yet";

          default:
            return "None";
        }
      }

      $(".addToTeam").fadeOut(0);

      $(".viewUser").on("click", function (e) {
        //alert('oo')
        e.preventDefault();

        var item = $(this).data("user");

        let index = $(this).attr("data-index");
        let user = $(this).attr("data-user");

        //console.log(index)
        //console.log(user)

        let key = $(this).attr("data-key");

        let location = $(this).attr("data-location");
        let name = $(this).attr("data-name");
        let organisation = $(this).attr("data-organisation");
        let image = $(this).attr("data-image");

        $(".addToTeam").attr("data-index", index);
        $(".addToTeam").attr("data-user", item.user.id);
        $(".addToTeam").attr("data-location", location);
        $(".addToTeam").attr("data-organisation", organisation);
        $(".addToTeam").attr("data-key", key);
        $(".addToTeam").attr("data-image", image);
        $(".addToTeam").attr("data-email", item.user.email);

        // let percentage = $(this).attr("data-percentage");
        //console.log(typeof item.user.profile_picture )

        let imageUrl = "https://grupa-media.s3-us-west-2.amazonaws.com/live/";
        let img =
          typeof item.user.profile_picture !== "object"
            ? imageUrl + item.user.profile_picture ||
              "/img/onboarding/profile-avatar.svg"
            : "/img/onboarding/profile-avatar.svg";

        // console.log(img);
        $(".profileImage").attr("src", img);

        $(".profileLoader").fadeOut(() => {
          $(".profileData").fadeIn();
        });

        //$(this).find('selectedEngineer')

        if (!$(".card-item", this).hasClass("selectedEngineer")) {
          // $(".addToTeam").fadeIn();

          if ($(this).find(".btn-unverified").css("display") == "none") {
            $(".addToTeam").fadeIn();
          } else {
            $(".addToTeam").fadeOut();
          }
        } else {
          $(".addToTeam").fadeOut();
        }

        $(".user-name").html(item.user.name);
        $(".experience").html(
          `${item.user.work_experience_count} year${
            item.user.work_experience_count > 0 ? "s" : ""
          } of experience`
        );
        $(".location").html(
          `${item.user.user_location ? item.user.user_location : "Unknown"}`
        );

        $(".organization_").html(
          `${
            item.user.work_experience[0] !== undefined
              ? item.user.work_experience[0].organization
              : "None"
          }`
        );

        //compensation_
        //

        //.forEach( (x, i) => )

        let userCompensation = "";

        //alert(JSON.parse(item.user.compensation_plans || '[]'))

        item.user.compensation_plans !== null ||
          item.user.compensation_plans != null ||
          item.user.compensation_plans !== undefined;
        item.user.compensation_plans != undefined;
        item.user.compensation_plans !== "null" ||
        JSON.parse(item.user.compensation_plans || "[]").length < 1
          ? JSON.parse(item.user.compensation_plans || "[]").forEach((x, i) => {
              // console.log("compensation_" + x);
              userCompensation +=
                x == 4
                  ? ""
                  : `
                  <div class="investor_data">
                  <img src="/img/cash1.svg" />
                  <span class="mx-1 " >${compensationMethod(x)}</span>
                </div>`;
            })
          : null;

        if (JSON.parse(item.user.compensation_plans || "[]").length > 0) {
          $(".compensation_").html(`<div class="text-muted" style="font-size:11px; margin-bottom: -5px;">Compensation choice</div>${userCompensation}`);
          $(".compensation_hr, .compensation_").fadeIn();
        } else {
          $(".compensation_hr, .compensation_").fadeOut();
        }

        // console.log(item.user.compensation_plans);
        //console.log();

        /*
        ${
            item.user.work_experience[0] !== undefined
              ? item.user.work_experience[0].organization
              : 'None'
          }
        */
        $(".matcher").html(`${item.total_percentage.toFixed(2)}% Match`);
        $(".ticker").html(
          `${item.user.name.split(" ")[0]} ticks the following boxes for you;`
        );
        $(".roleMatch").html(item.user.team_role);

        if (item.skill_match.length > 0) {
          $(".skillMatch").show();
          $(".skillMatch").html(
            `Proficient in ${item.skill_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")}`
          );
        } else {
          $(".skillMatch").hide();
        }

        //console.log(item.skill_match.toString().replace(/,(?=[^,]*$)/, ' & '))
        //replace(/,(?=[^,]*$)/, ' and')

        if (item.platform_match.length > 0) {
          $(".platformMatch").show();
          $(".platformMatch").html(
            `Has experience in ${item.platform_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")}`
          );
        } else {
          $(".platformMatch").hide();
        }

        //console.log(item.industry_match)
        if (item.industry_match.length > 0) {
          $(".industryMatchMain").show();
          $(".industryMatch").html(
            `Is interested in ${item.industry_match
              .toString()
              .replaceAll(",", ", ")
              .replace(/,(?=[^,]*$)/, " & ")} industry`
          );
        } else {
          $(".industryMatchMain").hide();
        }

        // $(".aboutUser").html(`${item.user.bio}`);

        $(".aboutUser").html(
          `${
            item.user.bio !== undefined && item.user.bio !== null
              ? item.user.bio.length > 211
                ? item.user.bio.slice(0, 212) + "..."
                : item.user.bio
              : "None"
          }`
        );

        /*

         item.user.work_experience[0] !== undefined
                    ? item.user.work_experience[0].organization.length > 21
                      ? item.user.work_experience[0].organization.slice(0, 22) +
                        "..."
                      : item.user.work_experience[0].organization
                    : "None"
        */

        let userSkill = "";
        // console.log(item.user.language_tools)
        item.user.language_tools !== "null"
          ? JSON.parse(item.user.language_tools).forEach(
              (x, i) =>
                (userSkill += `<li data-v-e45522b2 class="flex-item skillData">${capitalizeFirstLetter(
                  x
                )}</li>`)
            )
          : (userSkill = `<li data-v-e45522b2 class="flex-item skillData">None</li>`);

        let work_experience = "";

        item.user.work_experience.forEach(
          (x, i) =>
            (work_experience += `
                <div data-v-e45522b2 class="my-3 experienceData ${
                  i % 2 ? "" : "exOdd"
                }" style="text-align: left; ">

                  <div data-v-e45522b2 class="exImage">
                     <img data-v-e45522b2
                    src="${
                      x.company_logo !== "" &&
                      x.company_logo !== null &&
                      typeof x.company_logo !== "object"
                        ? x.company_logo
                        : "/img/icon.svg"
                    }"
                    class="rounded-circle img-fluid exImage"
                    style=""
                  />

                  </div>
                  <div class="exJob" data-v-e45522b2>
                    <div class="exJobTop" data-v-e45522b2>
                      <div class="exJobTopTitle" data-v-e45522b2>${
                        x.organization
                      }</div>

                    <div class="exJobTopTitleSub" data-v-e45522b2>${
                      x.role
                    }</div>
                    </div>


                  <div class="exJobBottom" data-v-e45522b2>

                    <span class="exJobBottomData" data-v-e45522b2>
                     ${returnDate(x.start_date)} -  ${
              x.current_job == 1 ? "Present" : returnDate(x.end_date)
            } <span style='font-size:19px'>&nbsp;•&nbsp;</span> ${
              x.current_job == 1
                ? moment(x.start_date).fromNow().replace(" ago", "")
                : moment(x.start_date)
            }
                    </span>
                  </div>
                  </div>

                </div>`)
        );

        let users_education = "";
        let users_education_count = 0;

        item.user.users_education.forEach((x, i) => {
          users_education += `
            <div data-v-e45522b2 class="educationData">
                   <div  data-v-e45522b2class="eduImage"><img data-v-e45522b2  src="/img/education.svg" class="eduImage-img"/></div>
                   <div data-v-e45522b2 class="eduData">
                     <div data-v-e45522b2 class="eduDataTop">
                       <div data-v-e45522b2 class="eduDataTopTitle">${capitalizeData(
                         x.institution
                       )}</div>
                       <div data-v-e45522b2 class="eduDataTopTitleSub">${
                         x.degree_title
                       }</div>
                     </div>
                     <div data-v-e45522b2 class="eduDataBottom">${returnDateYear(
                       x.start_date
                     )} - ${returnDateYear(x.end_date)}</div>
                   </div>
                 </div>


            `;
          users_education_count += i;
        });

        //work_experience

        $(".flex-container").html(userSkill);

        $(".work_experienceData").html(work_experience);

        $(".educationDataMain").html(users_education);

        $(".work_viewMore, .work_viewLess").hide();
        /*  if (users_education_count >= 3) {
          $(".work_viewLess").click();
        } else if (users_education_count > 0) {
          $(".work_viewMore").click();
        } else {
          $(".work_viewMore, .work_viewLess").hide();
        } */
        // alert(users_education_count)
      });

      $(".addToTeam").on("click", function () {
        $(".card-item.active").addClass("selectedEngineer");
      });
      $("body").on("click", ".contentBlock", function () {
        // $(".contentBlock").on("click", function () {
        $(".viewUser").find("img.marker", this).attr("src", "/img/mark.svg");
        $(this).find("img.marker", this).attr("src", "/img/mark_check.svg");

        $(".card-item").removeClass("active");

        $(".card-item", this).addClass("active");
      });

      let sum = 0;
      for (let i = 0; i < this.bestFitTeam.matchUser.length; i++) {
        let timer = setTimeout(function () {
          sum += parseInt($("#counter" + i).html());

          //console.log("setTimeout", sum);
          $(".totalSelected").html(sum);

          if (parseInt(sum) > 0) {
            $("#pButton")
              .removeClass("proceedBtn")
              .addClass("proceedBtnActive");
          } else {
            $("#pButton")
              .removeClass("proceedBtnActive")
              .addClass("proceedBtn");
          }

          clearTimeout(timer);
        }, 600);

        //
        $(".contentBlock" + i)
          .slice(0, 3)
          .show();

        $(".loadMore" + i).on("click", function (e) {
          e.preventDefault();
          $(".contentBlock" + i + ":hidden")
            .slice(0, 3)
            .slideDown();

          if ($(".contentBlock" + i + ":visible").length > 3) {
            $(".hideMore" + i).show();
          }

          if ($(".contentBlock" + i + ":hidden").length == 0) {
            $(".loadMore" + i)
              .text("End of record")
              .addClass("noContent");
          }
        });

        $(".hideMore" + i).on("click", function (e) {
          //alert('ff')
          e.preventDefault();

          if ($(".contentBlock" + i + ":visible").length > 3) {
            $(".contentBlock" + i + ":visible")
              .slice(0, 3)
              .slideUp();
          }
          // alert($(".contentBlock" + i + ":visible").length)

          if ($(".contentBlock" + i + ":visible").length <= 6) {
            $(".hideMore" + i).hide();
          }

          if ($(".contentBlock" + i + ":visible").length == 3) {
            $(".hideMore" + i).hide();
          }
        });
      }
    });
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.viewUser .active {
  border: 2px solid #0781f2;
}

.viewUser .btnAction {
  color: #0781f2;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #0781f2;
}

.viewUser .btnAction:hover {
  border: 2px solid #0781f2;
}

.act {
  color: #fff !important;
  font-weight: 500;
}

.selectedEngineer {
  background: #383e47 !important;
}

.selectedEngineer .list-group-item {
  background: transparent;
}

.selectedEngineer .item-title {
  color: #fff;
  font-weight: 700;
}

.selectedEngineer .matchData {
  color: #c8cbd0;
}

.matchData {
  font-weight: 800;
  font-size: 14px;
  line-height: 145%;

  color: #979da5;
}

.displayNone {
  display: none;
}
.noContent {
  color: #000 !important;
  background-color: transparent !important;
  pointer-events: none;
}

.btnActionRemove {
  display: none;
}

.btnActionRemove,
.btnAction {
  font-size: 11px;
}

.loadMore {
  /*  width: 120px; */

  text-align: center;

  padding: 10px;
  border-radius: 10px;

  float: right;
  margin-right: 20px;

  background-color: #e4e5e7;
  transition: 0.3s;
  margin-bottom: 50px;
  color: #000;
  border: 1px solid #c9cbcf;
  cursor: pointer;
  font-size: 12px;
}

.loadMore:hover {
  background-color: #c9cbcf;
  border: 1px solid #e4e5e7;
  color: rgb(49, 48, 48);
}
.contentBlock {
  /* display: none; */
}
.no-border {
  border: 0px solid #fff;
}
.Role-Hearder {
  background-color: #f7f9fd;
  border-color: #edeff2;
  font-weight: 700;

  font-size: 16px;
  line-height: 145%;

  color: #1b1e22;
}

.Role-Hearder-title {
  font-weight: 700;

  font-size: 16px;
  line-height: 145%;

  color: #1b1e22;
}

.bg-white {
  background-color: #fff;
}

.item-data {
  font-weight: 500;
  font-size: 14px;
  line-height: 145%;
  padding: 6px !important;
  /* or 20px */

  /* primary/400 */

  color: #6f7680;
}

.item-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/700 (text) */

  color: #1b1e22;
}

.item-title-sub {
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 18px */

  /* primary/400 */

  color: #6f7680;
}

.card-item {
  border-radius: 10px;
  background: #fdfdfd;
  cursor: pointer;
  /* primary/100 */

  border: 1px solid #e4e5e7;
  border-radius: 8px;
  min-height: 310px;
}

.Role-Hearder-sub {
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/300 */

  color: #979da5;
  float: right;
}
.div-wrapper {
  /* width: 1053px; */
  margin-bottom: 48px;
}
.section-title {
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 38px;
  color: #979da5;
  text-align: left;
  background-color: #f7f7f8;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #e4e5e7;
  padding: 20px;
}
.section-title span {
  font-weight: 300;
  font-size: 14px;
  line-height: 38px;
  color: #1fa564;
}
</style>
