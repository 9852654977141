var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      {
        staticClass: "col-md-3 sidebar",
        style: "display:" + (_vm.windowWidth <= 782 ? "none" : "block") + " ",
      },
      [
        _c("img", {
          staticClass: "sidebarIMG",
          attrs: {
            src:
              "/img/" +
              (_vm.current_page == 1 ? "sidebarIMG.svg" : "sidebarIMG2.svg"),
          },
        }),
        _c("div", { staticClass: "sidebarText" }, [
          _c("p", [
            _vm._v(
              "\n         " +
                _vm._s(
                  _vm.current_page == 1
                    ? "Building a great team to join you on your product mission is key to success. Please confirm your selections."
                    : "We connect with your calendars and that of the experts to automatically find the best times to meet for 30 mins each."
                ) +
                "\n       "
            ),
          ]),
          _c("p", [
            _vm._v(
              "\n         " +
                _vm._s(
                  _vm.current_page == 1
                    ? "You can go back to modify your selections or replace any team member if you’re not convinced after the initial intro call."
                    : "From research we strongly suggest meetings happen within 48 hours of matching."
                ) +
                "\n       "
            ),
          ]),
        ]),
        !_vm.localStorageData.isSelected
          ? _c("div", { staticClass: "sidebarBackButton" }, [
              _vm._m(0),
              _c("span", { staticClass: "text" }, [_vm._v("Go Back")]),
            ])
          : _vm._e(),
        _vm.localStorageData.isSelected
          ? _c(
              "div",
              {
                staticClass: "sidebarBackButtonHome",
                on: { click: _vm.handleDashboard },
              },
              [
                _vm._m(1),
                _c("span", { staticClass: "text" }, [_vm._v("Back to Home ")]),
              ]
            )
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "col-md-9 col-sm-12" }, [
      _c(
        "div",
        { staticClass: "tabs-container" },
        _vm._l(_vm.regStages, function (step) {
          return _c("div", { key: step.stage, staticClass: "tab-box" }, [
            _c(
              "div",
              {
                staticClass: "tab-button",
                class: {
                  "active-tab": _vm.current_page == step.stage,
                  "completed-tab": _vm.current_page > step.stage,
                },
                on: {
                  click: function ($event) {
                    return _vm.setPage(step.stage)
                  },
                },
              },
              [_vm._v("\n           " + _vm._s(step.name) + "\n         ")]
            ),
            step.stage < _vm.regStages.length
              ? _c("div", { staticClass: "tab-line" })
              : _vm._e(),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "containerPreview" }, [
        _c("div", { staticClass: "titleHeader" }, [
          _c("div", { staticClass: "mainHeader" }, [
            _vm._v(
              "\n           " +
                _vm._s(
                  _vm.current_page == 1
                    ? "Meet your team"
                    : _vm.current_page == 2
                    ? "Connect your calendars"
                    : "Confirm Meeting Team"
                ) +
                "\n         "
            ),
          ]),
          _c("div", { staticClass: "subHeader" }, [
            _vm._v(
              "\n           " +
                _vm._s(
                  _vm.current_page == 1
                    ? "You have selected these Experts to join in your mission, please Review and Proceed to confirm."
                    : _vm.current_page == 2
                    ? "We connect to automatically find times you’re available. Your free/busy schedule is requested once and credentials are not stored on our servers."
                    : "Comfirm meeting and accepting all selected team members and proceed to kickoff/worksheet"
                ) +
                "\n         "
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "proceedToKickOff ",
            staticStyle: { "margin-top": "90px" },
          },
          [
            _vm._l(_vm.SelectedEngineerMeeting || [], function (item, k) {
              return _c(
                "div",
                { key: k, staticClass: "proceedToKickOffData" },
                [
                  _c("div", { staticStyle: { "margin-bottom": "0px" } }, [
                    _c(
                      "div",
                      {
                        style:
                          "font-style: normal;\n                   font-weight: 700;\n                   font-size: 16px;\n                   line-height: 145%;\n                   color: #1b1e22;\n                   margin-top:" +
                          (k ? "40px" : "0px") +
                          ";\n                   margin-bottom: 10px;",
                      },
                      [
                        _vm._v(
                          "\n           \n            " +
                            _vm._s(k) +
                            "\n           "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row gy-1 p-1" },
                      _vm._l(item || [], function (item2, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass:
                              "selectedTeamData_ col-md-6 col-lg-6 col-xl-3 col-sm-12 col-xs-12 mb-1",
                            staticStyle: { border: "0px solid red !important" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "selectedTeamDataMain_" },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-3 p-1" }, [
                                    _c("div", { staticClass: "teamImg" }, [
                                      _c("img", {
                                        staticClass: "img-fluid teamImgData",
                                        attrs: { src: "" + item2.eng.image },
                                      }),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c("div", { staticClass: "teamData" }, [
                                      _c(
                                        "div",
                                        { staticClass: "teamDataTitle" },
                                        [_vm._v(_vm._s(item2.eng.name))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "margin-top": "20px",
                                            color: "#aaa",
                                            "font-weight": "800",
                                            "margin-left": "5px",
                                          },
                                        },
                                        [_vm._v(" Meeting Detail")]
                                      ),
                                      _c("div", { staticClass: "ml-0" }, [
                                        _c(
                                          "div",
                                          { staticClass: "teamDataList" },
                                          [
                                            _vm._m(2, true),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "locationData",
                                                staticStyle: {
                                                  "margin-left": "-2px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getDayOfTheWeek(
                                                      item2.when.start_time
                                                    )
                                                  ) +
                                                    "\n                          "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "teamDataList" },
                                          [
                                            _vm._m(3, true),
                                            _c(
                                              "span",
                                              { staticClass: "locationData" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getTimeInWord(
                                                      item2.when.start_time,
                                                      item2.when.end_time
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "row p-0 m-0 mr-2",
                                    staticStyle: {
                                      "margin-top": "-10px !important",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "col-6-md p-0 m-0",
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-6-md p-0 m-0 text-right",
                                      },
                                      [
                                        _c(
                                          "code",
                                          {
                                            staticStyle: { "font-size": "9px" },
                                          },
                                          [_vm._v(_vm._s(item2.eng.timezone))]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              )
            }),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  padding: "20px",
                  "margin-top": "60px",
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-light p-3 mr-4 shadow-sm ",
                    attrs: {
                      "data-bs-toggle": "modal",
                      "data-bs-target": "#requestChange",
                      type: "button",
                    },
                  },
                  [
                    _vm._v(
                      "\n                    Request Change\n                   "
                    ),
                  ]
                ),
                _vm.kickoffLoader
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          left: "70%",
                          right: "0",
                          "margin-top": "2%",
                          "text-align": "center",
                        },
                        attrs: { id: "kickoffLoader" },
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: {
                            size: 30,
                            color: "primary",
                            indeterminate: "",
                          },
                        }),
                        _vm._v(
                          "\n                 " +
                            _vm._s(_vm.kickoffLoaderMsg) +
                            "\n               "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.kickoffLoaderError != "" && _vm.kickoffLoader === false
                  ? _c("span", [
                      _c("code", { staticStyle: { "font-weight": "800" } }, [
                        _vm._v(" " + _vm._s(_vm.kickoffLoaderError)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.kickoffLoader == false
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary p-3",
                        staticStyle: {
                          "margin-left": "20px",
                          "padding-left": "20px",
                          "padding-right": "20px",
                        },
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.goToKickOfWorkSpace()
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    Proceed with your team\n                   "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ],
          2
        ),
        _c("div", { staticClass: "calenderMain" }, [
          _c("div", { staticClass: "calenderConnect" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8 emailText" }, [
                _vm._m(4),
                _vm._v(
                  "\n               " +
                    _vm._s(_vm.obscureEmail(_vm.currentUser.email)) +
                    "\n             "
                ),
              ]),
              _c("div", { staticClass: "col-4 disconectText" }, [
                _vm._v(
                  "\n               " +
                    _vm._s(
                      _vm.devNylasRecord.user === null
                        ? "Connect"
                        : "Disconnect"
                    ) +
                    "\n             "
                ),
              ]),
            ]),
            _c("div", {
              staticClass: "horizonatlLine",
              staticStyle: {
                "margin-left": "-20px",
                "margin-top": "30px",
                "margin-bottom": "10px",
              },
            }),
            _vm._m(5),
          ]),
          _c("div", { staticClass: "reviewTitle" }, [
            _vm._v("Review and Confirm Schedule"),
          ]),
          _c("div", { staticClass: "reviewTitleSub" }, [
            _vm._v(
              "\n           We have selected the best shared available times between you and\n           each expert over the next 48hours.\n         "
            ),
          ]),
          _c("div", { staticClass: "scheduleEng" }, [
            _c("div", { staticClass: "scheduleTime" }, [
              _vm._v("Best Available times"),
            ]),
            _vm._m(6),
            _c(
              "div",
              { staticClass: "tab-content", attrs: { id: "pills-tabContent" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade show active",
                    attrs: {
                      id: "pills-home",
                      role: "tabpanel",
                      "aria-labelledby": "pills-home-tab",
                      tabindex: "0",
                    },
                  },
                  [
                    _vm.isLoadingSingleCalender || _vm.isReloadingContent
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              left: "50%",
                              right: "0",
                              "margin-top": "20%",
                              "text-align": "center",
                            },
                            attrs: { id: "loaderCal" },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 30,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                            _vm._v(
                              "\n                 " +
                                _vm._s(_vm.isLoadingSingleCalenderMsg) +
                                "\n               "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.isLoadingSingleCalender
                      ? _c(
                          "div",
                          [
                            _vm._l(
                              _vm.NylasSingleAvailabilityRecord,
                              function (item, k) {
                                return _c(
                                  "div",
                                  { key: k, staticClass: "scheduleEngCard" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        staticStyle: {
                                          "border-bottom":
                                            "1px solid rgb(228 229 231 / 59%)",
                                          "padding-bottom": "30px",
                                          "padding-top": "10px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-6 text-left" },
                                          [
                                            _c("div", { staticClass: "Eng" }, [
                                              _c("img", {
                                                staticClass: "img-fluid",
                                                staticStyle: {
                                                  width: "50px",
                                                  height: "50px",
                                                  "background-blend-mode":
                                                    "normal",
                                                  "border-radius": "2px",
                                                  "margin-right": "1px",
                                                },
                                                attrs: {
                                                  src: "" + item.eng.image,
                                                },
                                              }),
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "teamDataTitle",
                                                    staticStyle: {
                                                      "padding-left": "10px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                             " +
                                                        _vm._s(item.eng.name) +
                                                        "\n                           "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "locationData",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                             " +
                                                        _vm._s(item.eng.role) +
                                                        "\n                           "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "locationData",
                                                    staticStyle: {
                                                      "margin-top": "-18px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "code",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "9px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            item.eng.timezone
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 text-left" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                class: "eng_selected_" + k,
                                                staticStyle: {
                                                  "font-weight": "700",
                                                  "/*  color": "#1b1e22",
                                                  "*/\n                           font-size":
                                                    "14px",
                                                  color: "#a8a2a2ee",
                                                },
                                                attrs: {
                                                  "data-start": "0",
                                                  "data-end": "0",
                                                  "data-id": "0",
                                                  "data-index": k,
                                                  id: "eng_day_" + item.eng.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                         " +
                                                    _vm._s(
                                                      Object.keys(
                                                        item.data.hasOwnProperty(
                                                          "time_slots"
                                                        )
                                                          ? item.data.time_slots
                                                          : []
                                                      ).length > 0
                                                        ? _vm.getSingleRamdomDay(
                                                            item.data
                                                          )
                                                        : "None"
                                                    ) +
                                                    "\n                       "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "flex",
                                                  "margin-top": "10px",
                                                },
                                              },
                                              [
                                                _c("img", {
                                                  staticStyle: {
                                                    "margin-top": "2px",
                                                  },
                                                  attrs: {
                                                    src: "/img/clock_main.svg",
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "font-weight": "700",
                                                      "font-size": "14px",
                                                      "margin-left": "5px",
                                                    },
                                                    attrs: {
                                                      id:
                                                        "eng_time_" +
                                                        item.eng.id,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                           " +
                                                        _vm._s(
                                                          Object.keys(
                                                            item.data.hasOwnProperty(
                                                              "time_slots"
                                                            )
                                                              ? item.data
                                                                  .time_slots
                                                              : []
                                                          ).length > 0
                                                            ? _vm.getSingleRamdomeDateTimeRange(
                                                                item.data,
                                                                item.eng.id
                                                              )
                                                            : "No Available           "
                                                        ) +
                                                        "\n                           "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "change-slot",
                                                    attrs: {
                                                      title:
                                                        "Choose meeting time for " +
                                                        item.name,
                                                      "data-bs-toggle": "modal",
                                                      "data-bs-target":
                                                        "#exampleModal",
                                                      "data-id": "" + item.id,
                                                      "data-name":
                                                        "" + item.name,
                                                      "data-email":
                                                        "" + item.email,
                                                      "data-availability":
                                                        "" +
                                                        JSON.stringify(
                                                          item.data
                                                        ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.ChangeEngineerTime(
                                                          item.eng.id,
                                                          item.eng.name,
                                                          item.eng.email,
                                                          $event
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                           Change\n                         "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            _vm.NylasSingleFreeBusyError != ""
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "scheduleEngCard",
                                    staticStyle: {
                                      "text-align": "center",
                                      "margin-top": "40px",
                                      display: "flex",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: {
                                        width: "60px",
                                        height: "60px",
                                      },
                                      attrs: { src: "/img/calendar_error.png" },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "reviewTitle",
                                        staticStyle: {
                                          "margin-top": "1px",
                                          "margin-left": "20px",
                                          "text-align": "left",
                                          "font-size": "13px",
                                          "font-weight": "200",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                     Error Syncing with   "
                                        ),
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.NylasSingleFreeBusyErrorUser
                                            ) + " "
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "reviewTitleSub",
                                            staticStyle: {
                                              "margin-top": "10px",
                                            },
                                          },
                                          [
                                            _c("code", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.NylasSingleFreeBusyError
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "locationData",
                                            staticStyle: {
                                              "margin-top": "-5px",
                                              "font-size": "10px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                    \n                     " +
                                                _vm._s(
                                                  _vm.NylasSingleFreeBusyErrorEmail
                                                ) +
                                                "\n                    \n                   "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "tab-pane fade",
                    attrs: {
                      id: "pills-profile",
                      role: "tabpanel",
                      "aria-labelledby": "pills-profile-tab",
                      tabindex: "0",
                    },
                  },
                  [
                    _vm.isLoadingCalender
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              left: "50%",
                              right: "0",
                              "margin-top": "20%",
                              "text-align": "center",
                            },
                            attrs: { id: "loaderCal" },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 30,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                            _vm._v(
                              "\n                 " +
                                _vm._s(_vm.isLoadingCalenderMsg) +
                                "\n               "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.NylasFreeBusyError != ""
                      ? _c(
                          "div",
                          {
                            staticClass: "scheduleEngCard",
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "72px",
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "140px" },
                              attrs: { src: "/img/calendar_error.png" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "reviewTitle",
                                staticStyle: { "margin-top": "40px" },
                              },
                              [
                                _vm._v(
                                  "\n                   ERROR DETECTED\n                 "
                                ),
                              ]
                            ),
                            _c("div", { staticClass: "reviewTitleSub" }, [
                              _c("code", [
                                _vm._v(_vm._s(_vm.NylasFreeBusyError)),
                              ]),
                            ]),
                            _c("br"),
                            _c("br"),
                          ]
                        )
                      : _vm._e(),
                    !_vm.isLoadingCalender && _vm.NylasFreeBusyError == ""
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "center",
                              "margin-top": "50px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "alert alert-warning",
                                attrs: { role: "alert" },
                              },
                              [
                                _vm._v(
                                  "\n                   Feature is still under construction\n                 "
                                ),
                              ]
                            ),
                            _vm._m(7),
                            _c(
                              "div",
                              {
                                staticClass: "reviewTitleSub",
                                staticStyle: {
                                  width: "100%",
                                  "font-size": "11px",
                                  "text-transform": "uppercase",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                   You would be meeting with\n                 "
                                ),
                              ]
                            ),
                            _vm._l(
                              (_vm.selectedTeamData.hasOwnProperty("role")
                                ? _vm.selectedTeamData.role
                                : []) || [],
                              function (data, i) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticStyle: {
                                      "max-height": "18px",
                                      "margin-top": "5px",
                                    },
                                  },
                                  [
                                    Object(
                                      _vm.selectedTeamData.engineersData[i]
                                    ).length > 0
                                      ? _c(
                                          "code",
                                          {
                                            staticStyle: {
                                              "white-space": "normal",
                                              display: "inline",
                                            },
                                          },
                                          _vm._l(
                                            _vm.selectedTeamData.engineersData[
                                              i
                                            ],
                                            function (item, k) {
                                              return _c(
                                                "span",
                                                {
                                                  key: k,
                                                  staticStyle: {
                                                    "font-weight": "300",
                                                    "padding-right": "0px",
                                                  },
                                                  attrs: {
                                                    "data-index": "" + i,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                       " +
                                                      _vm._s(item.name) +
                                                      "    "
                                                  ),
                                                  _c(
                                                    "i",
                                                    {
                                                      staticStyle: {
                                                        color: "grey",
                                                        "font-size": "8px",
                                                      },
                                                    },
                                                    [_vm._v("|")]
                                                  ),
                                                  _vm._v(
                                                    "   \n                     "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _c("input", {
                              attrs: { type: "hidden", id: "eng_id_data_c" },
                            }),
                            _c("input", {
                              attrs: { type: "hidden", id: "cal_day_c" },
                            }),
                            _c("input", {
                              attrs: { type: "hidden", id: "cal_start_time_c" },
                            }),
                            _c("input", {
                              attrs: { type: "hidden", id: "cal_end_time_c" },
                            }),
                            _c("input", {
                              attrs: { type: "hidden", id: "cal_time_range_c" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "btn-group",
                                staticStyle: {
                                  "margin-top": "40px",
                                  "margin-bottom": "30px",
                                },
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-light btn-lg dropdown-toggle",
                                    staticStyle: {
                                      "padding-bottom": "20px",
                                      border: "1px solid #ccc",
                                    },
                                    attrs: {
                                      type: "button",
                                      "data-bs-toggle": "dropdown",
                                      "data-bs-auto-close": "true",
                                      "aria-expanded": "false",
                                    },
                                  },
                                  [
                                    Object.keys(
                                      _vm.NylasAvailabilityRecord.hasOwnProperty(
                                        "time_slots"
                                      )
                                        ? _vm.NylasAvailabilityRecord.time_slots
                                        : []
                                    ).length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "5px",
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                       Click to change time\n                     "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    Object.keys(
                                      _vm.NylasAvailabilityRecord.hasOwnProperty(
                                        "time_slots"
                                      )
                                        ? _vm.NylasAvailabilityRecord.time_slots
                                        : []
                                    ).length <= 0
                                      ? _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-top": "5px",
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                      No available time slot to meet\n                     "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "code",
                                      {
                                        staticClass:
                                          "selected_conference_time btn btn-dark",
                                        staticStyle: { "margin-top": "5px" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            Object.keys(
                                              _vm.NylasAvailabilityRecord.hasOwnProperty(
                                                "time_slots"
                                              )
                                                ? _vm.NylasAvailabilityRecord
                                                    .time_slots
                                                : []
                                            ).length > 0
                                              ? _vm.getRamdomDay()
                                              : "NO"
                                          ) +
                                            "\n                       " +
                                            _vm._s(
                                              Object.keys(
                                                _vm.NylasAvailabilityRecord.hasOwnProperty(
                                                  "time_slots"
                                                )
                                                  ? _vm.NylasAvailabilityRecord
                                                      .time_slots
                                                  : []
                                              ).length > 0
                                                ? _vm.getRamdomeDateTimeRange()
                                                : "TIME SLOT"
                                            ) +
                                            "\n                     "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                Object.keys(
                                  _vm.NylasAvailabilityRecord.hasOwnProperty(
                                    "time_slots"
                                  )
                                    ? _vm.NylasAvailabilityRecord.time_slots
                                    : []
                                ).length > 0
                                  ? _c(
                                      "ul",
                                      { staticClass: "dropdown-menu" },
                                      _vm._l(
                                        _vm.NylasAvailabilityRecord.time_slots,
                                        function (item, k) {
                                          return _c("li", { key: k }, [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "dropdown-item timeSlotData_c",
                                                attrs: {
                                                  "data-start-time":
                                                    item.start_time,
                                                  "data-end-time":
                                                    item.end_time,
                                                  href: "#",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                         " +
                                                    _vm._s(
                                                      _vm.getDateData(
                                                        item.start_time
                                                      )
                                                    )
                                                ),
                                              ]
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "confirbBtn_",
              staticStyle: { "margin-top": "50px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal",
                  attrs: {
                    id: "proceedModal",
                    tabindex: "-1",
                    "aria-labelledby": "proceedModalLabel",
                    "aria-hidden": "true",
                  },
                },
                [
                  _c("div", { staticClass: "modal-dialog" }, [
                    _c("div", { staticClass: "modal-content" }, [
                      _vm._m(8),
                      _c("div", { staticClass: "modal-body" }, [
                        _c("p", { staticClass: "mt-2" }, [
                          _vm._v(
                            "\n                     This schedule will be added to your calendar and sent to\n                     the experts and other stakeholders.\n                   "
                          ),
                        ]),
                        _c("p", { staticClass: "mt-4" }, [
                          _vm._v(
                            "\n                     You’ll be notified by email once an expert responds to\n                     your invitation. Typical response time is ~6hours.\n                   "
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              color: "rgb(151, 157, 165)",
                              "margin-top": "50px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                     Mails would be sent to    "
                            ),
                            _c("br"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.sendMailsto()),
                              },
                            }),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticStyle: {
                              color: "rgb(34 108 208)",
                              "margin-top": "60px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                     Do you wish to proceed?\n                   "
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "modal-footer" }, [
                        _vm.isSendingInvitation
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  right: "0",
                                  "margin-top": "0%",
                                  "text-align": "center",
                                },
                                attrs: { id: "loaderCal" },
                              },
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    size: 30,
                                    color: "primary",
                                    indeterminate: "",
                                  },
                                }),
                                _vm._v(
                                  "\n                     " +
                                    _vm._s("sending invitation(s)...") +
                                    "\n                   "
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isSendingInvitation
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary p-3",
                                attrs: {
                                  type: "button",
                                  "data-bs-dismiss": "modal",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                     Cancel\n                   "
                                ),
                              ]
                            )
                          : _vm._e(),
                        !_vm.isSendingInvitation
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-primary confirmTeamProceed p-3",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmTeamProceed()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                     Confirm\n                   "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._m(9),
              _vm.isReloadingContentError != ""
                ? _c("span", [
                    _c("code", { staticStyle: { "font-weight": "800" } }, [
                      _vm._v(" " + _vm._s(_vm.isReloadingContentError)),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "padding-left": "30px",
                          color: "dodgerblue",
                          cursor: "pointer",
                        },
                        attrs: {
                          onClick: "javascript:document.location.reload()",
                        },
                      },
                      [_vm._v("Click to try again")]
                    ),
                  ])
                : _vm._e(),
              _vm.isLoadingSingleCalender == false &&
              _vm.isLoadingCalender == false &&
              _vm.NylasSingleAvailabilityRecordError == "" &&
              _vm.isReloadingContent === false &&
              Object.keys(this.NylasSingleAvailabilityRecord).length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "btn btn-primary p-3",
                      attrs: {
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#proceedModal",
                      },
                    },
                    [_c("span", [_vm._v("Proceed")]), _vm._m(10)]
                  )
                : _vm._e(),
              _vm.isLoadingSingleCalender == false &&
              _vm.isLoadingCalender == false &&
              (_vm.NylasSingleAvailabilityRecordError != "" ||
                Object.keys(this.NylasSingleAvailabilityRecord).length <= 0)
                ? _c(
                    "div",
                    {
                      staticClass: "btn proceedBtn p-3",
                      attrs: {
                        onClick: "javascript:document.location.reload()",
                      },
                    },
                    [_c("span", [_vm._v("Reload")]), _vm._m(11)]
                  )
                : _vm._e(),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "modal",
            attrs: {
              id: "errorModal",
              tabindex: "-1",
              "aria-labelledby": "errorModalLabel",
              "aria-hidden": "true",
            },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(12),
                _c(
                  "div",
                  {
                    staticClass: "modal-body",
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c("br"),
                    _c("img", {
                      staticStyle: { "margin-top": "30px", width: "150px" },
                      attrs: { src: "/img/calendar_error.png" },
                    }),
                    _c("br"),
                    _c("br"),
                    _c("p", { staticStyle: { "margin-top": "30px" } }, [
                      _vm._v(
                        "\n                  Error detected while sending meeting invitation\n                 "
                      ),
                    ]),
                    _c("code", [_vm._v(_vm._s(this.meetingError))]),
                    _c("br"),
                    _c("br"),
                  ]
                ),
                _vm._m(13),
              ]),
            ]),
          ]
        ),
        _vm._m(14),
        _vm._m(15),
        _vm._m(16),
        _c(
          "div",
          {
            staticClass: "modal fade",
            attrs: {
              id: "requestChange",
              tabindex: "-1",
              role: "dialog",
              "aria-labelledby": "requestChangeLabel",
              "aria-hidden": "false",
            },
          },
          [
            _c(
              "div",
              { staticClass: "modal-dialog", attrs: { role: "document" } },
              [
                _c("div", { staticClass: "modal-content" }, [
                  _vm._m(17),
                  _c(
                    "div",
                    {
                      staticClass: "modal-body",
                      staticStyle: {
                        "text-align": "center",
                        "padding-top": "40px",
                      },
                    },
                    [
                      _c("center", [
                        _c("textarea", {
                          staticClass: "form-control",
                          staticStyle: { width: "95%", height: "150px" },
                          attrs: { id: "reasonData", name: "reasonData" },
                          on: { keyup: _vm.TextChangeReadon },
                        }),
                        _c("p", [
                          _vm._v(
                            "Have a reservation you want to share, kindly let us know ?"
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary  p-3",
                        attrs: {
                          type: "button",
                          onClick:
                            "javascript:$('#requestChange').modal('hide')",
                        },
                      },
                      [
                        _vm._v(
                          "\n                     Close\n                   "
                        ),
                      ]
                    ),
                    _vm.ChangeReadonLoader
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              left: "70%",
                              right: "0",
                              "margin-top": "2%",
                              "text-align": "center",
                            },
                            attrs: { id: "ChangeReadonLoader" },
                          },
                          [
                            _c("v-progress-circular", {
                              attrs: {
                                size: 30,
                                color: "primary",
                                indeterminate: "",
                              },
                            }),
                            _vm._v(
                              "\n                 " +
                                _vm._s("submiting...") +
                                "\n               "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.ChangeReadonLoader == false
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary p-3",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.requestEngineerChange()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                     Request Change\n                   "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "modal",
            attrs: {
              id: "exampleModal",
              tabindex: "-1",
              "aria-labelledby": "exampleModalLabel",
              "aria-hidden": "true",
            },
          },
          [
            _c("div", { staticClass: "modal-dialog" }, [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(18),
                _c("div", { staticClass: "modal-body" }, [
                  Object.keys(
                    _vm.SelectedAvailabilityRecord.hasOwnProperty("time_slots")
                      ? _vm.SelectedAvailabilityRecord.time_slots
                      : []
                  ).length <= 0
                    ? _c("div", { staticStyle: { "margin-top": "40px" } }, [
                        _vm._m(19),
                      ])
                    : _vm._e(),
                  Object.keys(
                    _vm.SelectedAvailabilityRecord.hasOwnProperty("time_slots")
                      ? _vm.SelectedAvailabilityRecord.time_slots
                      : []
                  ).length > 0
                    ? _c("div", [
                        _c("p", { staticStyle: { "padding-left": "20px" } }, [
                          _vm._v(
                            "\n                     Choose the best available times to meet with\n                     "
                          ),
                          _c("code", [_vm._v(_vm._s(_vm.selectedEngineer))]),
                          _c("br"),
                          _vm._v(
                            "\n                     Please select one to proceed;\n                   "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _c("br"),
                  _c("input", { attrs: { type: "hidden", id: "eng_id_data" } }),
                  _c("input", { attrs: { type: "hidden", id: "cal_day" } }),
                  _c("input", {
                    attrs: { type: "hidden", id: "cal_start_time" },
                  }),
                  _c("input", {
                    attrs: { type: "hidden", id: "cal_end_time" },
                  }),
                  _c("input", {
                    attrs: { type: "hidden", id: "cal_time_range" },
                  }),
                  Object.keys(
                    _vm.SelectedAvailabilityRecord.hasOwnProperty("time_slots")
                      ? _vm.SelectedAvailabilityRecord.time_slots
                      : []
                  ).length > 0
                    ? _c(
                        "div",
                        {
                          staticClass: "row",
                          staticStyle: { "padding-left": "20px" },
                        },
                        _vm._l(
                          _vm.SelectedAvailabilityRecord.time_slots,
                          function (item, k) {
                            return _c(
                              "div",
                              { key: k, staticClass: "col-md-6 timeSlotData" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "cal_start_time",
                                    staticStyle: { display: "none" },
                                  },
                                  [_vm._v(_vm._s(item.start_time))]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "cal_end_time",
                                    staticStyle: { display: "none" },
                                  },
                                  [_vm._v(_vm._s(item.end_time))]
                                ),
                                _vm._v(
                                  "\n\n                     " +
                                    _vm._s(_vm.getDateData(item.start_time)) +
                                    "\n                   "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      )
                    : _vm._e(),
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-bs-dismiss": "modal" },
                    },
                    [_vm._v("\n                   Close\n                 ")]
                  ),
                  Object.keys(
                    _vm.SelectedAvailabilityRecord.hasOwnProperty("time_slots")
                      ? _vm.SelectedAvailabilityRecord.time_slots
                      : []
                  ).length > 0
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-primary confirmTime",
                          attrs: { type: "button" },
                        },
                        [
                          _vm._v(
                            "\n                   Confirm\n                 "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "selectedTeamContainer" },
        [
          _vm._l(
            (_vm.selectedTeamData.hasOwnProperty("role")
              ? _vm.selectedTeamData.role
              : []) || [],
            function (data, i) {
              return _c("div", { key: i, staticClass: "selectedTeam" }, [
                Object(_vm.selectedTeamData.engineersData[i]).length > 0
                  ? _c("div", { staticClass: "selectedRole" }, [
                      _vm._v(
                        "\n             " +
                          _vm._s(_vm.selectedTeamData.role[i]) +
                          "\n           "
                      ),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "row gy-1 p-1",
                    staticStyle: { border: "0px solid red !important" },
                  },
                  _vm._l(
                    _vm.selectedTeamData.engineersData[i],
                    function (item, k) {
                      return _c(
                        "div",
                        {
                          key: k,
                          staticClass:
                            "selectedTeamData_ col-md-6 col-lg-6 col-xl-3 col-sm-12 col-xs-12 mb-1",
                          attrs: { "data-index": "" + i },
                        },
                        [
                          _c("div", { staticClass: "selectedTeamDataMain_" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-3 p-1" }, [
                                _c("div", { staticClass: "teamImg" }, [
                                  _c("img", {
                                    staticClass: "img-fluid teamImgData",
                                    attrs: { src: "" + item.image },
                                  }),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  class:
                                    "" +
                                    (_vm.windowWidth <= 700
                                      ? "col-8"
                                      : "col-7"),
                                },
                                [
                                  _c("div", { staticClass: "teamData" }, [
                                    _c(
                                      "div",
                                      { staticClass: "teamDataTitle" },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c("div", { staticClass: "teamDataList" }, [
                                      _vm._m(20, true),
                                      _c(
                                        "span",
                                        { staticClass: "locationData" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item.location.length > 16
                                                ? item.location.slice(0, 14) +
                                                    "..."
                                                : item.location
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "teamDataList" }, [
                                      _vm._m(21, true),
                                      item.hasOwnProperty("")
                                        ? _c(
                                            "span",
                                            { staticClass: "locationData" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.organisation.length > 17
                                                    ? item.organisation.slice(
                                                        0,
                                                        14
                                                      ) + "..."
                                                    : item.organisation
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c("div", { staticClass: "col-1" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "teamRemove",
                                    attrs: {
                                      "data-index": "" + i,
                                      "data-key": "" + k,
                                      "data-user": "" + item.id,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: "/img/minus-cirlce.svg" },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "row p-0 m-0 mr-2",
                                staticStyle: {
                                  "margin-top": "-10px !important",
                                },
                              },
                              [
                                _c("div", { staticClass: "col-6-md p-0 m-0" }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-6-md p-0 m-0 text-right",
                                  },
                                  [
                                    _c(
                                      "code",
                                      { staticStyle: { "font-size": "9px" } },
                                      [_vm._v(_vm._s(item.timezone))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ])
            }
          ),
          _vm._m(22),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/img/Path.svg", alt: "Back", srcset: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("img", { attrs: { src: "/img/Path.svg", alt: "Back", srcset: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "location_" }, [
      _c("img", {
        staticStyle: { width: "17px" },
        attrs: { src: "/img/Map-location.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "location_" }, [
      _c("img", {
        staticStyle: { "margin-top": "2px" },
        attrs: { src: "/img/clock_main.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "calederIcon" }, [
      _c("img", { attrs: { src: "/img/google calendar.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-5 clickMore" }, [
        _vm._v("Click to connect more"),
      ]),
      _c("div", { staticClass: "col-7" }, [
        _c("span", { staticClass: "IconFooter" }, [
          _c("img", { attrs: { src: "/img/calendar-logo.svg" } }),
        ]),
        _c("span", { staticClass: "IconFooter googleClick" }, [
          _c("img", { attrs: { src: "/img/google calendar.svg" } }),
        ]),
        _c("span", { staticClass: "IconFooter" }, [
          _c("img", { attrs: { src: "/img/iCalendar.svg" } }),
        ]),
        _c("span", { staticClass: "IconFooter" }, [
          _c("img", { attrs: { src: "/img/calendly_brand.svg" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass: "nav nav-tabs nav-fill mb-0",
        attrs: { id: "pills-tab", role: "tablist" },
      },
      [
        _c("li", { staticClass: "nav-item", attrs: { role: "presentation" } }, [
          _c(
            "button",
            {
              staticClass: "nav-link active",
              attrs: {
                id: "pills-home-tab",
                "data-bs-toggle": "pill",
                "data-bs-target": "#pills-home",
                type: "button",
                role: "tab",
                "aria-controls": "pills-home",
                "aria-selected": "true",
              },
            },
            [_vm._v("\n                 Meet each Engineers\n               ")]
          ),
        ]),
        _c(
          "li",
          {
            staticClass: "nav-item disabled",
            attrs: { role: "presentation", "aria-disabled": "true" },
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: {
                  id: "pills-profile-tab",
                  "data-bs-toggle": "pill",
                  "data-bs-target": "#pills-profile",
                  type: "button",
                  role: "tab",
                  "aria-controls": "pills-profile",
                  "aria-selected": "false",
                },
              },
              [_vm._v("\n                 Conference meeting\n               ")]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticStyle: { "margin-top": "40px", "margin-bottom": "30px" } },
      [
        _c("img", {
          staticStyle: { width: "100px" },
          attrs: { src: "/img/schedule.png" },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Meet your team")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "btn btn-light p-3 mr-4 shadow-sm backbBtn" },
      [
        _c("span", [
          _vm._v(" \n               "),
          _c("img", {
            staticStyle: { width: "16px" },
            attrs: { src: "/img/Path.svg" },
          }),
        ]),
        _c("span", [_vm._v("  ")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" \n               "),
      _c("img", {
        staticStyle: { width: "16px" },
        attrs: { src: "/img/arrow-right.svg" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" \n               "),
      _c("img", {
        staticStyle: { width: "16px" },
        attrs: { src: "/img/refresh-2-256.png" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("ERROR DETECTED")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-footer" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          attrs: { type: "button", "data-bs-dismiss": "modal" },
        },
        [_vm._v("\n                   Close\n                 ")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successChangeRequestModal",
          tabindex: "-1",
          "aria-labelledby": "successChangeRequestModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Change Request Sent"),
              ]),
              _c("button", {
                staticClass: "btn-close",
                attrs: {
                  type: "button",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("br"),
                _c("img", {
                  staticStyle: { "margin-top": "30px", width: "150px" },
                  attrs: { src: "/img/marked.svg" },
                }),
                _c("br"),
                _c("br"),
                _c("p", { staticStyle: { "margin-top": "30px" } }, [
                  _vm._v(
                    "\n                   Engineer Change Request was successfully sent\n                 "
                  ),
                ]),
                _c("br"),
                _c("br"),
              ]
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-bs-dismiss": "modal" },
                },
                [_vm._v("\n               Close\n             ")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          id: "successModal",
          tabindex: "-1",
          "aria-labelledby": "successModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v("Meeting Scheduled"),
              ]),
              _c("button", {
                staticClass: "btn-close",
                attrs: {
                  type: "button",
                  "data-bs-dismiss": "modal",
                  "aria-label": "Close",
                },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { "text-align": "center" },
              },
              [
                _c("br"),
                _c("img", {
                  staticStyle: { "margin-top": "30px", width: "150px" },
                  attrs: { src: "/img/marked.svg" },
                }),
                _c("br"),
                _c("br"),
                _c("p", { staticStyle: { "margin-top": "30px" } }, [
                  _vm._v(
                    "\n                   Meeting schedules have been successfully sent\n                 "
                  ),
                ]),
                _c("br"),
                _c("br"),
              ]
            ),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-bs-dismiss": "modal" },
                },
                [_vm._v("\n               Close\n             ")]
              ),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "comingSoon",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "comingSoonTitle",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "comingSoonTitle" },
                  },
                  [
                    _vm._v(
                      "\n                     COMING SOON\n                   "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                      onClick: "javascript:$('#comingSoon').modal('hide')",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _c("img", {
                    staticStyle: { width: "100px", "margin-top": "50px" },
                    attrs: { src: "/img/coming-soon.png" },
                  }),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "margin-top": "50px",
                        "margin-bottom": "30px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                     This feature is still under construction\n                   "
                      ),
                    ]
                  ),
                ]
              ),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      onClick: "javascript:$('#comingSoon').modal('hide')",
                    },
                  },
                  [_vm._v("\n                     Close\n                   ")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "requestChangeTitle" } },
        [
          _vm._v(
            "\n                     REQUEST ENGINEER CHANGE\n                   "
          ),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
            onClick: "javascript:$('#requestChange').modal('hide')",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Select a time")]),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      { staticStyle: { "padding-left": "28px", "text-align": "center" } },
      [
        _c(
          "span",
          { staticStyle: { "font-size": "17px", "font-weight": "600" } },
          [_vm._v("NO TIME AVAILABLE")]
        ),
        _c("br"),
        _c("img", {
          staticStyle: { "margin-top": "30px", width: "100px" },
          attrs: { src: "/img/calendar_blocked.png" },
        }),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n                     There are no available time slots for the selected\n                     engineers\n                   "
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "location_" }, [
      _c("img", { attrs: { src: "/img/Map-location.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "location_" }, [
      _c("img", { attrs: { src: "/img/Bag.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "confirbBtn" }, [
      _c("div", { staticClass: "btn btn-primary p-3" }, [
        _c("span", [_vm._v("Set-up the Initial conversation")]),
        _c("span", [
          _vm._v(" \n               "),
          _c("img", {
            staticStyle: { width: "16px" },
            attrs: { src: "/img/arrow-right.svg" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }