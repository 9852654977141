var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "fixed-header",
        style: "display:" + (_vm.proceedPreview ? "none" : "flex"),
      },
      [
        _c("v-toolbar-title", [
          _c("span", { style: { "margin-left": _vm.screenSize.logoMargin } }),
          _c(
            "span",
            {
              staticClass: "project-name",
              style: _vm.clientProject.project_name ? "color: #1B1E22;" : null,
            },
            [
              _vm._v(
                _vm._s(
                  _vm.clientProject.project_name
                    ? _vm.clientProject.project_name
                    : "Product Name_"
                )
              ),
            ]
          ),
          _vm._v("\n       \n      "),
          _c(
            "span",
            {
              staticClass: "badge text-bg-secondary rounded-pill",
              staticStyle: { "padding-top": "1px", cursor: "pointer" },
              attrs: {
                "data-bs-toggle": "tooltip",
                "data-bs-placement": "right",
                title: "Project Info",
              },
            },
            [
              _c("img", {
                staticStyle: { width: "10px" },
                attrs: { src: "/img/Shape_2.svg" },
              }),
            ]
          ),
        ]),
        _c(
          "div",
          { staticClass: "username-div" },
          [
            _c("span", { staticClass: "text-lg-right" }, [
              _vm._v(_vm._s(_vm.shortenName(_vm.currentUser.name))),
            ]),
            _c(
              "v-menu",
              {
                attrs: {
                  bottom: "",
                  origin: "center center",
                  transition: "scale-transition",
                },
              },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { slot: "activator", flat: "", icon: "" },
                    slot: "activator",
                  },
                  [_c("v-icon", [_vm._v("keyboard_arrow_down")])],
                  1
                ),
                _c(
                  "v-list",
                  [
                    _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-title",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.handleDashboard },
                          },
                          [_vm._v("Dashboard")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-list-tile",
                      [
                        _c(
                          "v-list-tile-title",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.handleLogout },
                          },
                          [_vm._v("Logout")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }