var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "page-wrap" },
        [
          _c(
            "Topbar",
            _vm._b(
              {},
              "Topbar",
              {
                userSelectedPlan: _vm.userSelectedPlan,
                switchRegion: _vm.switchRegion,
                selected_region: _vm.selected_region,
                handleGoBack: _vm.handleGoBack,
                shortenName: _vm.shortenName,
                proceedPreview: _vm.proceedPreview,
              },
              false
            )
          ),
          _c(
            "div",
            { staticClass: "teams-preview", staticStyle: { display: "none" } },
            [
              _c(
                "TeamPreview",
                _vm._b(
                  {},
                  "TeamPreview",
                  {
                    windowWidth: _vm.windowWidth,
                    yourselectedTeamData: _vm.yourselectedTeamData,
                  },
                  false
                )
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "teams-container",
              staticStyle: { display: "none" },
            },
            [
              _vm.isReloadingContent
                ? _c("section", { staticClass: "container" }, [_c("Loader")], 1)
                : _vm._e(),
              !_vm.isReloadingContent && _vm.availableTeams.length == 0
                ? _c("section", { staticClass: "container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "no_team",
                        staticStyle: {
                          "line-height": "70px",
                          "margin-top": "150px",
                          "font-size": "20px",
                        },
                      },
                      [
                        _vm._v(
                          "\n           No team available for this plan, kindly choose another plan.\n           "
                        ),
                        _c("br"),
                        _vm.selected_region == "My Region"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#0566c2",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.switchRegion("any")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n             Select team from other regions\n           "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _c("br"),
                        _vm._v("\n           Or\n         "),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "grupa-blue-btn",
                        on: {
                          click: function ($event) {
                            _vm.showCalendly = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n           Schedule meeting with grupa\n         "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              !_vm.isReloadingContent && _vm.availableTeams.length > 0
                ? _c("div", { staticClass: "bestEngineer" }, [
                    _c(
                      "div",
                      {
                        staticClass: "container-fluid",
                        staticStyle: { border: "0px solid red" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row align-items-start",
                            staticStyle: { height: "80%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-md-8 scrollHide",
                                style:
                                  "text-align: start;border:0px solid red;max-height:" +
                                  (_vm.windowHeight -
                                    (20 / 100) * _vm.windowHeight) +
                                  "px;overflow-y: auto;  ",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "go-back-div",
                                    on: { click: _vm.handleDashboard },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/img/onboarding/back-icon.svg",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticStyle: { "font-weight": "800" } },
                                      [_vm._v("Go Back")]
                                    ),
                                  ]
                                ),
                                _c("nylasPop"),
                                _vm._m(0),
                                _c(
                                  "div",
                                  {
                                    staticClass: "accordion_",
                                    staticStyle: { "margin-top": "30px" },
                                  },
                                  _vm._l(
                                    _vm.allTeams,
                                    function (bestFitTeam, index) {
                                      return _c(
                                        "BestFitEngineer",
                                        _vm._b(
                                          {
                                            key: index,
                                            attrs: { topTeam: _vm.allTeams },
                                          },
                                          "BestFitEngineer",
                                          {
                                            bestFitTeam: bestFitTeam,
                                            checkUnderscore:
                                              _vm.checkUnderscore,
                                            selected_region:
                                              _vm.selected_region,
                                            teamStack: _vm.teamStack,
                                            abbreviateFullName:
                                              _vm.abbreviateFullName,
                                            imgPath: _vm.imgPath,
                                            viewTeam: _vm.viewTeam,
                                            industries: _vm.industries,
                                            getTimeZone: _vm.getTimeZone,
                                            teamFrameWork: _vm.teamFrameWork,
                                            imageExists: _vm.imageExists,
                                            compareTeamClientTimeZone:
                                              _vm.compareTeamClientTimeZone,
                                          },
                                          false
                                        )
                                      )
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "col-md-4 scrollHide",
                                style:
                                  "text-align: center;background: #F7F7F8;max-height:" +
                                  (_vm.windowHeight -
                                    (20 / 100) * _vm.windowHeight) +
                                  "px;overflow-y: auto; display:" +
                                  (_vm.windowWidth <= 767 ? "none" : "block") +
                                  " ",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "profileData",
                                    staticStyle: { display: "none" },
                                  },
                                  [_c("ProfileData")],
                                  1
                                ),
                                _c("img", {
                                  staticClass: "mt-30 profileLoader",
                                  staticStyle: { width: "100%" },
                                  attrs: { src: "/img/content.svg" },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "sticky-bottom container-fluid",
                  style:
                    "display:" + (_vm.isReloadingContent ? "none" : "block"),
                },
                [
                  _vm.availableTeams.length > 0
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-8 col-sm-12 bottomSelected p-4 pl-5",
                            staticStyle: { "text-align": "left" },
                          },
                          [
                            _c("span", { staticStyle: { color: "#000" } }, [
                              _vm._v("Selected:"),
                            ]),
                            _c("span", { staticClass: "totalSelected" }, [
                              _vm._v("  No selections yet "),
                            ]),
                            _c("nylasPopButton"),
                            _vm.devNylasRecord.user !== null
                              ? _c(
                                  "div",
                                  { staticStyle: { "margin-top": "-20px" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "proceedBtn",
                                        attrs: { id: "pButton" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.proceedBtn()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                 Proceed\n               "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "btn btn-light proceedBtnCancel",
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetForm()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                 Cancel\n               "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("div", {
                          staticClass: "col-md-4 col-sm-12 bottomSelected p-4",
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.showCalendly
        ? _c(
            "CalendlyModal",
            _vm._b(
              {
                attrs: { client: true },
                on: { closeModal: _vm.closeCalendly },
              },
              "CalendlyModal",
              {
                loadCalendly: _vm.loadCalendly,
                user: _vm.currentUser,
                meetingName: "Get Custom Team",
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row align-items-start" }, [
      _c(
        "div",
        { staticClass: "col-sm-6", staticStyle: { "text-align": "start" } },
        [
          _c("div", { staticClass: "page-title-main mt-60" }, [
            _vm._v("Build your team"),
          ]),
          _c("span", { staticClass: "page-sub-title" }, [
            _vm._v(
              "\n                     We have curated a list of Experts that best fit your\n                     needs.\n                     "
            ),
            _c("br"),
            _c("br"),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "col-sm-6 mt-60 filter",
          staticStyle: {
            "text-align": "end",
            "margin-bottom": "30px",
            "padding-right": "40px",
            display: "none",
          },
        },
        [
          _c("img", {
            staticStyle: { "padding-right": "10px" },
            attrs: { src: "/img/Shape.svg" },
          }),
          _vm._v("\n                   Modify\n                 "),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }