<template>
  <div>
    <div class="fixed-header" :style="`display:${proceedPreview?'none':'flex'}`">
     
      
      <!-- <div class="go-back-div" @click="handleDashboard">
        <img src="/img/onboarding/back-icon.svg" />
        <span>Go Back</span>
      </div> -->
      <v-toolbar-title>
        <span v-bind:style="{ 'margin-left': screenSize.logoMargin }"></span>
        <span
          class="project-name"
          :style="clientProject.project_name ? 'color: #1B1E22;' : null"
          >{{
            clientProject.project_name
              ? clientProject.project_name
              : "Product Name_"
          }}</span
        >
        &nbsp;
        <span
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          title="Project Info"
          class="badge text-bg-secondary rounded-pill"
          style="padding-top: 1px; cursor: pointer"
          ><img src="/img/Shape_2.svg" style="width: 10px"
        /></span>
      </v-toolbar-title>
      <div class="username-div">
        <span class="text-lg-right">{{ shortenName(currentUser.name) }}</span>
        <v-menu bottom origin="center center" transition="scale-transition">
          <v-btn flat slot="activator" icon>
            <v-icon>keyboard_arrow_down</v-icon>
          </v-btn>

          <v-list>
            <v-list-tile>
              <v-list-tile-title
                style="cursor: pointer"
                @click="handleDashboard"
                >Dashboard</v-list-tile-title
              >
            </v-list-tile>
            <v-list-tile>
              <v-list-tile-title style="cursor: pointer" @click="handleLogout"
                >Logout</v-list-tile-title
              >
            </v-list-tile>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { logoutUser } from "@/utils/logoutUser";
export default {
  props: {
    proceedPreview: {
      type: Boolean,
    },
    userSelectedPlan: {
      type: Object,
    },
    switchRegion: {
      type: Function,
    },
    selected_region: {
      type: String,
    },
    shortenName: {
      type: Function,
    },
  },
  components: {
    //proceedPreview
  },

  data: () => ({}),

  created() {},
  mounted() {
   // console.log(this.proceedPreview)
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
  methods: {
    handleLogout() {
      logoutUser();
    },
    handleDashboard() {
      let projectId = this.$route.params.id;
      if (projectId) {
        this.$router.push({
          name: "project_workspace",
          params: { projectId },
        });
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", ["clientProject"]),
    screenSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return { logoMargin: "0px", margin_right: "0px" };
        case "sm":
          return { logoMargin: "0px", margin_right: "0px" };
        case "md":
          return { logoMargin: "50px", margin_right: "50px" };
        case "lg":
          return { logoMargin: "100px", margin_right: "100px" };
        case "xl":
          return { logoMargin: "100px", margin_right: "100px" };
      }
    },
  },
};
</script>
<style scoped>
/* * {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
} */
.fixed-header,
.go-back-div,
.region-container,
.region-button {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.region-button {
  cursor: pointer;
}
.fixed-header {
  top: 0;
  position: fixed;
  background: #fff;
  filter: alpha(opacity=40);
  z-index: 1000;
  padding: 17px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  box-shadow: 0px 1px 2px rgba(30, 27, 34, 0.1);
}
.go-back-div {
  margin-top: 7px;
}
.region-container {
  background: #e4e5e7;
  border: 1px solid #e4e5e7;
  border-radius: 5px;
  display: flex;
  /* width: 252px; */
}
.region-button {
  padding: 10px 23px;
  background: #e4e5e7;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  box-shadow: 2px 1px 5px rgba(27, 30, 34, 0.1);
  border-radius: 5px;
}
.text-lg-right {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.username-div {
  /* margin-top: 7px; */
}

@media (max-width: 1280px) {
  .fixed-header {
    /* width: calc(100% - 230px); */
  }
}
</style>
