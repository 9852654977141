var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", {}, [
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "showNylasBar",
            tabindex: "-1",
            "aria-labelledby": "showNylasBar",
            "data-bs-keyboard": "false",
            "data-bs-backdrop": "static",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "modal-body",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _vm._m(1),
                  _vm._m(2),
                  _c("div", { staticClass: "step1" }, [
                    _c("img", {
                      staticStyle: { "margin-top": "35px" },
                      attrs: { src: "/img/Calendar.svg" },
                    }),
                    _c("div", { staticClass: "nylasBarText" }, [
                      _vm._v(
                        "\n                Connect your calendar to help us schedule meetings\n                quickly with\n                "
                      ),
                      _c("b", [
                        _vm._v(
                          _vm._s(
                            _vm.currentUser.user_type == 1
                              ? "Founder"
                              : "Expert"
                          )
                        ),
                      ]),
                      _vm._v(
                        " who\n                want to meet you.\n              "
                      ),
                    ]),
                    _c("hr"),
                    _vm._m(3),
                    _c("br"),
                    _vm._m(4),
                    _vm._m(5),
                    _c(
                      "div",
                      {
                        staticClass: "nylasBarButton",
                        on: { click: _vm.handleGetNylasUniqueURL },
                      },
                      [
                        _vm._m(6),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "align-items": "center",
                              "text-align": "center",
                              width: "90%",
                              color: "#53585f",
                            },
                          },
                          [
                            _vm._v(
                              "\n                  Connect with Google\n                "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm.showNylasBar() && _vm.isHideBar == false
        ? _c(
            "div",
            {
              staticClass: "nylas-warning-container",
              staticStyle: { display: "none" },
            },
            [_vm._m(7)]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [_vm._v("\n              Connect your work calendar\n            ")]
      ),
      _c("button", {
        staticClass: "btn-close",
        attrs: {
          type: "button",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "step3", staticStyle: { "margin-top": "30px" } },
      [
        _c("img", {
          staticStyle: { width: "120px" },
          attrs: { src: "/img/calendar.png" },
        }),
        _c(
          "div",
          {
            staticStyle: { "margin-top": "30px" },
            attrs: { id: "nylasLoader2" },
          },
          [_vm._v("\n                Calendar sync completed\n              ")]
        ),
        _c(
          "div",
          {
            staticStyle: {
              "margin-top": "10px",
              color: "#aaa",
              "font-size": "11px",
            },
          },
          [_vm._v("\n                reloading your profile\n              ")]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step2" }, [
      _c("div", { staticClass: "nylasLoader2" }, [
        _c("span", { staticStyle: { color: "rgb(52 54 57)" } }, [
          _vm._v(" Syncing Calendar"),
        ]),
        _c("img", {
          staticStyle: { height: "120px" },
          attrs: { src: "/img/grupa-loader.gif" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nylasBarTextSub" }, [
      _c("code"),
      _c("div", { staticClass: "alert alert-dark", attrs: { role: "alert" } }, [
        _vm._v(
          "\n                  Grupa will only use the requested calendar access to view when you're \n                  available, and set up conversations with Experts on your behalf. \n                  "
        ),
        _c("b", [
          _vm._v(
            "Grupa will not access your calendar or event data for any other purpose."
          ),
        ]),
      ]),
      _c("div", { staticClass: "alert alert-info", attrs: { role: "alert" } }, [
        _vm._v(
          "\n                  Browser pop-up access needed to complete the sync process. Kindly allow when prompted!\n                 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nylasLoader" }, [
      _c("img", {
        staticStyle: { height: "120px" },
        attrs: { src: "/img/grupa-loader.gif" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "alert alert-danger alert-dismissible nylasAlert",
        staticStyle: { margin: "30px" },
        attrs: { role: "alert" },
      },
      [
        _c("div", [_vm._v("An unexpected error occured")]),
        _c("code", [_vm._v("If issue persist, kindly relogin and try again")]),
        _c("button", {
          staticClass: "btn-close",
          staticStyle: { "font-size": "10px" },
          attrs: {
            type: "button",
            "data-bs-dismiss": "alert",
            "aria-label": "Close",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { width: "10%" } }, [
      _c("img", { attrs: { src: "/img/google_g_icon.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row", staticStyle: { width: "100%" } }, [
      _c(
        "div",
        {
          staticClass: "col-md-12 col-sm-12 text-right create-team-msg",
          staticStyle: { float: "right", "margin-top": "-40px" },
        },
        [
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn isSetupProfileClicked",
              attrs: {
                "data-bs-toggle": "modal",
                "data-bs-target": "#showNylasBar",
                "aria-labelledby": "staticBackdropLabel",
              },
            },
            [_vm._v("\n            Connect your calendar\n          ")]
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }