<template>
  <div>
    <div class="page-wrap">
      <Topbar
        v-bind="{
          userSelectedPlan,
          switchRegion,
          selected_region,
          handleGoBack,
          shortenName,
          proceedPreview,
        }"
      />

      <div class="teams-preview" style="display: none">
        <TeamPreview
          v-bind="{
            windowWidth,
            yourselectedTeamData,
          }"
        />
      </div>

      <div class="teams-container" style="display: none">
        <section class="container" v-if="isReloadingContent">
          <Loader />
        </section>

        <section
          class="container"
          v-if="!isReloadingContent && availableTeams.length == 0"
        >
          <div
            class="no_team"
            style="line-height: 70px; margin-top: 150px; font-size: 20px"
          >
            No team available for this plan, kindly choose another plan.
            <br />

            <div
              v-if="selected_region == 'My Region'"
              style="color: #0566c2; cursor: pointer"
              @click="switchRegion('any')"
            >
              Select team from other regions
            </div>

            <br />
            Or
          </div>

          <button @click="showCalendly = true" class="grupa-blue-btn">
            Schedule meeting with grupa
          </button>
        </section>

        <div
          class="bestEngineer"
          v-if="!isReloadingContent && availableTeams.length > 0"
        >
          <!--           <div class="page-information">

            <div class="go-back-div" @click="handleDashboard">

              <img src="/img/onboarding/back-icon.svg" />

              <span>Go Back</span>

            </div>

            <b />
            <br />
             []
            <div class="page-title">Choose A Team</div>

            <div class="select-expect">
               Select from a list of the top 3 expert teams that suit your needs.
              Click on a team to view more details
            </div>

          </div> -->

          <div class="container-fluid" style="border: 0px solid red">
            <div class="row align-items-start" style="height: 80%">
              <div
                class="col-md-8 scrollHide"
                :style="`text-align: start;border:0px solid red;max-height:${
                  windowHeight - (20 / 100) * windowHeight
                }px;overflow-y: auto;  `"
              >
                <div class="go-back-div" @click="handleDashboard">
                  <img src="/img/onboarding/back-icon.svg" />

                  <span style="font-weight: 800">Go Back</span>
                </div>

                <nylasPop />

                <div class="row align-items-start">
                  <div class="col-sm-6" style="text-align: start">
                    <div class="page-title-main mt-60">Build your team</div>

                    <span class="page-sub-title">
                      We have curated a list of Experts that best fit your
                      needs.
                      <br />

                      <br />
                    </span>
                  </div>

                  <div
                    class="col-sm-6 mt-60 filter"
                    style="
                      text-align: end;
                      margin-bottom: 30px;
                      padding-right: 40px;
                      display: none;
                    "
                  >
                    <img src="/img/Shape.svg" style="padding-right: 10px" />
                    Modify
                  </div>
                </div>

                <div class="accordion_" style="margin-top: 30px">
                  <BestFitEngineer
                    v-for="(bestFitTeam, index) in allTeams"
                    :key="index"
                    v-bind="{
                      bestFitTeam,
                      checkUnderscore,
                      selected_region,
                      teamStack,
                      abbreviateFullName,
                      imgPath,
                      viewTeam,
                      industries,
                      getTimeZone,
                      teamFrameWork,
                      imageExists,
                      compareTeamClientTimeZone,
                    }"
                    v-bind:topTeam="allTeams"
                  />
                </div>
              </div>

              <div
                class="col-md-4 scrollHide"
                :style="`text-align: center;background: #F7F7F8;max-height:${
                  windowHeight - (20 / 100) * windowHeight
                }px;overflow-y: auto; display:${
                  windowWidth <= 767 ? 'none' : 'block'
                } `"
              >
                <div class="profileData" style="display: none">
                  <ProfileData />
                </div>

                <img
                  src="/img/content.svg"
                  class="mt-30 profileLoader"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>

          <!--           <div class="call-container">
            Got questions?
            <span @click="showCalendly = true">
              Join instant call with Grupa
            </span>
          </div> -->
        </div>

        <!--  {{ JSON.stringify(devNylasRecord.user) }}
 -->
        <div
          class="sticky-bottom container-fluid"
          :style="`display:${isReloadingContent ? 'none' : 'block'}`"
        >
          <div class="row" v-if="availableTeams.length > 0">
            <div
              class="col-md-8 col-sm-12 bottomSelected p-4 pl-5"
              style="text-align: left"
            >
              <span style="color: #000">Selected:</span>
              <span class="totalSelected">&nbsp; No selections yet </span>

              <nylasPopButton />

              <div
                v-if="devNylasRecord.user !== null"
                style="margin-top: -20px"
              >
                <div class="proceedBtn" @click="proceedBtn()" id="pButton">
                  Proceed
                </div>

                <div
                  class="btn btn-light proceedBtnCancel"
                  @click="resetForm()"
                >
                  Cancel
                </div>
              </div>
            </div>

            <div class="col-md-4 col-sm-12 bottomSelected p-4">
              <!-- <div class="addToTeam" v-if="devNylasRecord.user !== null">
                Add to your team
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <CalendlyModal
      v-if="showCalendly"
      v-bind:client="true"
      v-on:closeModal="closeCalendly"
      v-bind="{
        loadCalendly,
        user: currentUser,
        meetingName: 'Get Custom Team',
      }"
    />
  </div>
</template>

<script>
import countryFinder from "country-finder";
import countryTimeZones from "countries-and-timezones";
import { mapState, mapActions } from "vuex";
import Topbar from "./topbar";
import TeamPreview from "./TeamPreview";
import BestFitEngineer from "./BestFitEngineer";
import ProfileData from "./ProfileData";

import Loader from "@/components/general/loader2";
import CalendlyModal from "@/components/calendlyModal";
import { checkRegion } from "@/utils/checkRegion.js";
import Axios from "axios";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";
import { grupaPrices, getTeamsforProject, getEnginnerforProject } from "@/api";
import { getGrupaNylasRecord } from "@/api/nylasApi";

import nylasPop from "./../../../ClientDashboard/workspace/newProject/dashboard/nylasPop.vue";
import nylasPopButton from "./../../../ClientDashboard/workspace/newProject/dashboard/nylasPopButton.vue";

//import JQuery from 'jquery'
import $ from "jquery";

export default {
  name: "selectEngineerAI",
  props: {},
  components: {
    Topbar,
    nylasPop,
    nylasPopButton,
    BestFitEngineer,
    ProfileData,
    TeamPreview,
    Loader,
    CalendlyModal,
  },
  mixins: [shortName, appMixin],
  data: () => ({
    isGetNylasData: false,

    industries: ["Finance", "Payments", "Security"],
    imgPath: "/img/onboarding/",
    isReloadingContent: true,
    userSelectedPlan: {
      project_work_mode: 1,
      service_plan_id: 1,
      is_remove_my_region: true,
    },
    userRegion: {},
    teamStack: [],
    allTeams: [],
    allEngineer: [],
    devNylasRecord: { user: [] },
    isReloadingContent: false,
    yourselectedTeamData: [],
    selected_region: "My Region",
    loadCalendly: true,
    showCalendly: false,
    proceedPreview: false,
    clientRegion: null,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    isClickEnginner: false,
    nylasLoader: false,
    // txt: ''
  }),

  ready: function () {},

  watch: {
    //alert('kff')
    windowHeight(newHeight, oldHeight) {
      //this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },

    windowWidth(newWidth, oldWidth) {
      //this.txt = `it changed to ${newHeight} from ${oldHeight}`;
      //console.log(`it changed to ${newWidth} from ${oldWidth}`)
    },

    allTeams(newHeight, oldHeight) {
      if (newHeight[0].hasOwnProperty("matchUser")) {
        //console.log(JSON.stringify(newHeight[0].matchUser));
        //  if (this.isClickEnginner === false)
        newHeight[0].matchUser.map((data, i) => {
          // console.log(JSON.stringify(data) !== "[]" ? "true" : "false");

          if (JSON.stringify(data) !== "[]") {
            let timer = setTimeout(function () {
              if (!this.isClickEnginner) {
                // console.log(`#role_data_${i} div.contentBlock1`);
                //console.log(data);

                $(`#role_data_${i} div.contentBlock${0}`).click();
                this.isClickEnginner = true;
              }

              clearTimeout(timer);
            }, 1000);
          }
        });
      }
      //this.txt = `it changed to ${newHeight} from ${oldHeight}`;
      //console.log(`it changed to ${newHeight} from ${oldHeight}`)
    },

    //
  },

  async created() {
    window.addEventListener("scroll", (e) => this.handleScroll(e), true);

    this.isReloadingContent = true;
    if (this.userLocation != null) {
      this.userRegion.country_name = this.userLocation.country_name;
      this.userRegion.continent_name = this.userLocation.continent_name;
      this.userRegion.ip = this.userLocation.ip;
      let region = checkRegion(
        this.userRegion.continent_name,
        this.userRegion.country_name
      );
      this.userSelectedPlan.continent = region;
      this.clientRegion = region;
      this.fetchGrupaPrices(region);
    } else {
      await this.getClientRegion();
    }
    this.getClientTimeZone();
  },
  async mounted() {
    // console.log(this.currentUser)
    this.$nextTick(async () => {
      $(".profileLoader")
        .fadeOut()
        .delay(1500)
        .fadeIn()
        .delay(1500)
        .fadeOut()
        .fadeIn()
        .delay(1500);
      // $('#one').fadeIn(100).delay(5000).fadeOut
      if (localStorage.getItem("engineerRole") !== null) {
        let data = await JSON.parse(localStorage.getItem("engineerRole"));
        if (data.preview) {
          $(".fixed-header").fadeOut();
          $(".teams-container").fadeOut(() => {
            $(".teams-preview").fadeIn();
          });
        } else {
          $(".teams-preview").fadeOut(() => {
            $(".teams-container").fadeIn();
          });
        }
      } else {
        $(".teams-preview").fadeOut(() => {
          $(".teams-container").fadeIn();
        });
      }
      // $(".teams-preview").fadeOut();
      let timer = setTimeout(function () {
        // alert('timeOut')
        if (parseInt($(".totalSelected").html()) > 0) {
          $("#pButton").removeClass("proceedBtn").addClass("proceedBtnActive");
        } else {
          $("#pButton").removeClass("proceedBtnActive").addClass("proceedBtn");
        }
        // console.log("setTimeout");
        clearTimeout(timer);
      }, 10000);

      //

      window.addEventListener("resize", this.onResize);
    });

    this.checkNylas();

    this.resetTeamForProject();
    let payload = this.userSelectedPlan;

    let payload2 = {
      skill_check: true,
      platforms_check: true,
      industry_check: true,
    };

    // console.log("1111", payload2);

    //await this.fetchTeamsProject(payload);
    await this.fetchEngineerProject(payload2);

    if (this.availableTeamsLoading == 1) {
      let sortedTeams = this.sortTeamByPercentage();
      if (sortedTeams.length > 0) {
        this.allTeams = sortedTeams;
      }

      this.isReloadingContent = false;
      this.findSelectedPlan(this.userSelectedPlan);
    } else if (this.availableTeamsLoading == 2) {
      this.isReloadingContent = false;
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    async checkNylas() {
      let payload_p = {
        email: this.currentUser.email, //"okechukwu0127@gmail.com", //
      };
      this.nylasLoader = true;

      //////GET NYLAS RECORD

      //this.devNylasRecord = { user: this.currentUser.nylas };

      await getGrupaNylasRecord(payload_p)
        .then((res) => {
          this.devNylasRecord = res.data.data;
          //  console.log(JSON.stringify(res.data.data));
          //this.isGetNylasData = true;
          this.nylasLoader = false;
        })
        .catch((error) => {
          this.nylasLoader = true;
          // console.log("ERROR getGrupaNylasRecord: ", error);
        });
    },
    changeTimeZoneTimestamp(date, timeZone) {
      try {
        // return date.getTime()
        return (
          new Date(
            date.toLocaleString("en-US", {
              timeZone,
            })
          ).getTime() /
            1000 +
          "--"
        );
      } catch (e) {
        console.log(e);
        return new Date(date.toLocaleString("en-US"));
      }
    },

    proceedBtn() {
      if (parseInt($(".totalSelected").html()) > 0) {
        this.proceedPreview = true;
        $(".fixed-header").fadeOut();
        $(".teams-container").fadeOut(0);
        let data = JSON.parse(localStorage.getItem("engineerRole"));
        data.preview = true;
        localStorage.setItem("engineerRole", JSON.stringify(data));
        $(".teams-preview").fadeIn();
        this.yourselectedTeamData = data;
        document.location.reload();
      } else {
        alert(
          "\nNO ENGINEER SLEECTED\nKindly select at list one engineer to proceed"
        );
        // let unSelectedModal = document.getElementById("unSelected");

        //unSelectedModal.style = "display:block";

        /*  if (unSelectedModal.hasAttribute("style")) {
          unSelectedModal.style.display = "block";
        } else {
        }
 */
        // $("#unSelected").modal();
      }
    },

    testAlert(data) {
      console.log(data);
      //alert("hahaha");
    },

    resetForm() {
      localStorage.removeItem("engineerRole");
      document.location.reload();
    },
    getClick() {
      // $("body").addClass("eze");
    },

    handleScroll(e) {
      if (e.target.hasOwnProperty("classList")) {

        if (e.target.classList.contains("scrollHide") === false) {
          e.target.classList.add("scrollHide");
        } else {
          setTimeout(function () {
            e.target.classList.remove("scrollHide");
          }, 1000);
        }
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    ...mapActions("selectTeamForProject", [
      "selectTeamForProject",
      "resetTeamForProject",
      "updateSelectedPlan",
      "setGrupaPrices",
    ]),
    ...mapActions("userManagement", ["setUserLocation"]),

    // Get client region if not available
    async getClientRegion() {
      const res = await Axios.get("https://httpbin.org/ip");
      const origin = res.data.origin.split(",");
      let ip = origin[0];

      await Axios.get(`https://ipinfo.io/${ip}?token=d8e19ce98ae1da`).then(
        (res) => {
          let location = res.data;
          let countryDetails = countryFinder.byCountryCode(location.country);

          location.continent_name = countryDetails.continent;
          this.userRegion.country_name = countryDetails.name;
          this.userRegion.continent_name = location.continent_name;
          location.country_code = location.country;
          this.userRegion.ip = location.ip;
          let region = checkRegion(
            this.userRegion.continent_name,
            this.userRegion.country_name
          );
          this.userSelectedPlan.continent = region;
          this.fetchGrupaPrices(region);
          // this.regionCode = this.handleRegionCode(region);
          location.loginDate = new Date();
          this.setUserLocation(location);
        }
      );
    },
    // find user selected plan
    findSelectedPlan(payload) {
      /* let { service_plan_id } = payload;
      let plan = this.servicePlanName(service_plan_id);
      let prices = this.priceList;
      console.log(prices)
      let planSelected = prices.find(
        (price) => price.experience == plan && price.work_mode == "distributed"
      );
      this.updateSelectedPlan(planSelected); */
    },
    // filter returned teams with project industry

    async changePlan(plan) {
      if (plan) {
        this.userSelectedPlan.service_plan_id = this.serivePlanId(plan.name);
      }

      let params = this.userSelectedPlan;
      this.isReloadingContent = true;
      this.resetTeamForProject();
      this.bestTeamFit = {};
      //await this.fetchTeamsProject(params);
      await this.fetchEngineerProject(params);

      //fetchEngineerProject
      if (this.availableTeamsLoading == 1) {
        let sortedTeams = this.sortTeamByPercentage();
        if (sortedTeams.length > 0) {
          this.allTeams = sortedTeams;
        }
        this.isReloadingContent = false;
        this.findSelectedPlan(this.userSelectedPlan);
      } else if (this.availableTeamsLoading == 2) {
        this.isReloadingContent = false;
      }
    },
    async switchRegion(payload) {
      this.isReloadingContent = true;
      this.resetTeamForProject();
      let params;
      if (payload == "self") {
        this.userSelectedPlan.continent = this.clientRegion;
        this.selected_region = "My Region";
        this.userSelectedPlan.is_remove_my_region = false;
        params = this.userSelectedPlan;
      } else {
        this.selected_region = "Other Regions";
        this.userSelectedPlan.is_remove_my_region = true;
        params = this.userSelectedPlan;
      }
      // await this.fetchTeamsProject(params);
      await this.fetchEngineerProject(params);

      if (this.availableTeamsLoading == 1) {
        let sortedTeams = this.sortTeamByPercentage();
        if (sortedTeams.length > 0) {
          this.allTeams = sortedTeams;
        }
        this.isReloadingContent = false;
      } else if (this.availableTeamsLoading == 2) {
        this.isReloadingContent = false;
      }
    },
    abbreviateFullName(name) {
      if (name) return name.length > 7 ? `Mr ${name.charAt(0)}` : name;
    },
    viewTeam(teamDetails) {
      const team = teamDetails.user_name;
      teamDetails.price ? this.updateSelectedPlan(teamDetails.price) : null;
      // Clear selected work mode while leaving the page
      this.$store.dispatch("selectTeamForProject/updatechosenName", team);
      this.$router.push({
        name: "view_team",
        params: { teamId: teamDetails.id, id: this.$route.params.id },
      });
    },
    handleGoBack() {
      this.$router.push({
        name: "work_mode",
        params: { id: this.$route.params.id },
      });
    },
    getTimeZone(timeZone) {
      const timezone = countryTimeZones.getTimezone(`${timeZone}`);
      return timezone.dstOffsetStr.split(":")[0];
    },
    compareTeamClientTimeZone(TimeZone) {
      const clientTimeZone = countryTimeZones.getTimezone(
        `${this.userRegion.timeZone}`
      );
      let clientGMT = clientTimeZone.utcOffsetStr.split(":")[0];
      clientGMT = parseInt(clientGMT);
      const teamTimeZone = countryTimeZones.getTimezone(`${TimeZone}`);
      let teamGMT = teamTimeZone.utcOffsetStr.split(":")[0];
      teamGMT = parseInt(teamGMT);
      if (clientGMT == teamGMT) {
        return "Same time zone";
      } else {
        let timeDifferences = Math.abs(clientGMT - teamGMT);
        if (clientGMT > teamGMT) {
          return `${timeDifferences}hr${
            timeDifferences > 1 ? "s" : ""
          } behind your location`;
        }
        if (clientGMT < teamGMT) {
          return `${timeDifferences}hr${
            timeDifferences > 1 ? "s" : ""
          } ahead your of location`;
        }
      }
    },
    closeCalendly() {
      this.showCalendly = false;
    },
    serivePlanId(plan) {
      switch (plan) {
        case "shared":
          return 1;
        case "exclusive":
          return 2;
        case "dedicated":
          return 3;
      }
    },
    servicePlanName(id) {
      switch (id) {
        case 1:
          return "shared";
        case 2:
          return "exclusive";
        case 3:
          return "dedicated";
      }
    },
    fetchGrupaPrices(region) {
      let payload = { continent: region };
      grupaPrices(payload)
        .then((response) => {
          this.setGrupaPrices(response.data.priceList);
        })
        .catch((error) => {
          this.$developConsole(error, "Api Error");
        });
    },
    async fetchTeamsProject(params_Data) {
      let projectId = this.$route.params.id;
      //console.log(params_Data)
      await getTeamsforProject(projectId, params_Data)
        .then((response) => {
          const { best_fit_team } = response.data.data;
          let responseData = {
            data: best_fit_team,
            message: "Load team successfully",
            status: 1,
          };
          this.selectTeamForProject(responseData);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status == 500
          ) {
            let responseData = {
              data: [],
              message: error.response.data.message,
              status: 2,
            };
            this.selectTeamForProject(responseData);
          }
        });
    },

    async fetchEngineerProject(params_Data) {
      let projectId = this.$route.params.id;
      //console.log(params_Data)

      await getEnginnerforProject(projectId, params_Data)
        .then((response) => {
          //console.log(response.data)
          let max = response.data.engineer[0].matchRole.length;

          this.$store.commit("selectTeamForProject/updateProjectRoles", {
            data: response.data.engineer[0].matchRole,
          });

          let ar = [];

          for (let step = 0; step < max; step++) {
            ar[step] = new Array();
          }

          /* this.$store.commit("selectTeamForProject/updateProjectRoles", {
            engineers: ar,
          }); */

          let d = {
            engineers: [],
            engineersData: [],
            role: [],
            preview: false,
          };

          if (localStorage.getItem("engineerRole") !== null) {
            if (
              JSON.parse(localStorage.getItem("engineerRole")).hasOwnProperty(
                "role"
              )
            ) {
              if (
                JSON.parse(localStorage.getItem("engineerRole")).role.length <=
                0
              ) {
                d.engineers = ar;
                d.engineersData = ar;

                d.role = response.data.engineer[0].matchRole;

                localStorage.setItem("engineerRole", JSON.stringify(d));
              } else {
                // console.log("all is set already");
              }
            } else {
              d.engineers = ar;
              d.engineersData = ar;

              d.role = response.data.engineer[0].matchRole;

              localStorage.setItem("engineerRole", JSON.stringify(d));
            }
          } else {
            d.engineers = ar;
            d.engineersData = ar;

            d.role = response.data.engineer[0].matchRole;

            localStorage.setItem("engineerRole", JSON.stringify(d));
          }

          // console.log(localStorage.getItem("engineerRole"));

          //console.log(JSON.parse(localStorage.getItem("engineerRole")).role);

          /*  console.log(this.$store.state.selectTeamForProject.engineerRole.role);
          console.log(
            this.$store.state.selectTeamForProject.engineerRole.engineers
          ); */

          let responseData = {
            data: response.data.engineer,
            message: "Load Engineer successfully",
            status: 1,
          };
          this.selectTeamForProject(responseData);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status &&
            error.response.status == 500
          ) {
            let responseData = {
              data: [],
              message: error.response.data.message,
              status: 2,
            };
            this.selectTeamForProject(responseData);
          }
        });
    },
    sortTeamByPercentage() {
      if (this.availableTeams.length > 0) {
        //console.log(this.availableTeams);
        let teamArray = JSON.parse(JSON.stringify(this.availableTeams));
        // console.log(this.availableTeams)
        return teamArray.sort((a, b) => b.percentage - a.percentage);
      }

      return this.availableTeams;
    },
    async getClientTimeZone() {
      let ipAddress = this.userLocation.ip;
      const response = await Axios.get(
        `https://ipapi.co/${ipAddress}/timezone`
      );
      this.userRegion.timeZone = response.data;
    },
    handleDashboard() {
      let projectId = this.$route.params.id;
      if (projectId) {
        this.$router.push({
          name: "project_workspace",
          params: { projectId },
        });
      }
    },
  },
  computed: {
    ...mapState("userManagement", ["userLocation", "currentUser"]),
    ...mapState("selectTeamForProject", [
      "priceList",
      "availableTeams",
      "availableTeamsLoading",
      "selectTeamProject",
      "availableTeamsMessage",
    ]),
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.addToTeam {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 145%;
  /* or 20px */
  cursor: pointer;
  text-align: center;
  display: none;

  /* accent/500 */

  color: #0781f2;
}

.proceedBtnCancel {
  float: right;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -6px;

  /* identical to box height, or 14px */

  align-items: center;
  text-align: center;

  width: 104.1px;
  height: 45px;
  padding-top: 10px;

  border-radius: 3px;
  background-color: #ebeaea;
}

.proceedBtn {
  float: right;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -6px;
  cursor: pointer;

  /* identical to box height, or 14px */

  align-items: center;
  text-align: center;

  color: #a4d0f9;

  width: 104.1px;
  height: 45px;
  padding-top: 10px;

  background: rgba(164, 208, 249, 0.3);
  border-radius: 3px;
}

.proceedBtnActive {
  float: right;
  margin-right: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: -6px;

  /* identical to box height, or 14px */

  align-items: center;
  text-align: center;

  color: #fff;

  width: 104.1px;
  height: 45px;
  padding-top: 10px;

  background: #0781f2;
  border-radius: 3px;
  cursor: pointer;
}

.bottomSelected {
  width: 100%;
  height: 77px;
  background: #f7f7f8;
  border-top: 2px solid #e4e5e7;

  border-width: 2px 0px 0px 2px;
  border-style: solid;
  border-color: #e4e5e7;
}

.totalSelected {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/300 */

  color: #979da5;
  color: #000;
}

#loadMore {
  width: 200px;
  color: #fff;
  display: block;
  text-align: center;
  margin: 20px auto;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  background-color: blue;
  transition: 0.3s;
}
#loadMore:hover {
  color: blue;
  background-color: #fff;
  border: 1px solid blue;
  text-decoration: none;
}
.borderLine {
  border: 2px solid red;
}

.filter {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  /* accent/500 */

  color: #0781f2;
}
.bestEngineer {
  /*   padding: 20px;
 */
  margin-top: -20px;
  width: 100%;
  border: 0px solid red;
  padding-left: 40px;
  padding-right: 40px;
}
.page-information {
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  width: 100%;
}
.go-back-div {
  /* position: absolute; */
  left: 0px;
  top: 10px;
  padding: 10px;
  font-weight: 800;
}
.page-title-main {
  font-weight: 700;
  font-size: 23px;
  line-height: 28px;
}
.page-sub-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 145%;
  /* or 23px */

  /* primary/300 */

  color: #979da5;
}
.select-expect {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
  width: 410px;
  margin-top: 16px;
}
.teams-preview {
  align-items: flex-start;
  text-align: left;
}
.teams-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 48px;
  margin-top: 72px;
}
.call-container {
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
  margin: 72px 0px 100px 0px;
}
.call-container span {
  color: #0781f2;
  cursor: pointer;
}

.scrollHide::-webkit-scrollbar {
  width: 0 !important;
} /* 
 ::-webkit-scrollbar-thumb {
  background-color: #acacac;
}

::-webkit-scrollbar {
  width: 15 !important;
} */
/* 
.scrollHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: -moz-scrollbars-none;
  } */
</style>
